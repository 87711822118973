import React, { useState } from 'react';
import { makeRequest } from '../../Utils';
import styles from './email.module.css';
import { TextControl } from '../InputControls';

export default function EmailSignup() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState('');

  const submit = async () => {
    setSubmitted(true);
    let data = await makeRequest(`newsletter?email=${email}`);
    if (!data.valid) {
      setMessage(data.message);
    }
  }

  return (
    <div className={styles.emailSignup}>

      <h3 className={styles.emailHeadline}>Sign Up for Our Newsletter and Take 10% Off on Us</h3>
      <div className={styles.emailDescription}>Get all the latest news, weekly curated picks, and more, straight to your inbox! Plus, receive a 10% off coupon for your next order.</div>
      {message && <div className={styles.emailError}>{message}</div>}
      {!submitted && <div className={styles.emailControls}>
        <TextControl
          value={email}
          change={setEmail}
          classlist='newsletterSignupNew'
          placeholder={'Your email'}
        />
        <div>
          <button onClick={submit} className={styles.emailButton}>I'm in!</button>
        </div>
      </div>}
      {submitted &&
        <div className={styles.emailConfirmed}>Thanks! You're swell.</div>
      }
      <div className={styles.emailFinePrint}>By signing up, you agree to get regular email communications from Asterism. We will never sell your data, and you can opt-out at any time.</div>
    </div>
  )
}
