import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { sessionState, pageviewState } from '../../atoms';
import { asaEvent, getAverageRgb } from '../../Utils';
import { useRef } from 'react';
import { useState } from 'react';

export function CategoriesBlock({ index }) {


  const cats = [
    {
      name: 'Poetry',
      slug: 'poetry',
      link: '/all-books?categories=poetry%2Cpoetics',
      image: 'https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/catpoetry.png'
    },
    {
      name: 'Fiction',
      slug: 'fiction',
      link: '/all-books?categories=fiction',
      image: 'https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/catsfiction.png'
    },
    {
      name: 'Translations',
      slug: 'translation',
      link: '/all-books?categories=translation',
      image: 'https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/catstranslations.png'
    },
    {
      name: 'Memoirs',
      slug: 'memoir',
      link: '/all-books?categories=translation',
      image: 'https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/catsmemoir.png'
    },
    {
      name: 'Horror',
      slug: 'horror',
      link: '/all-books?categories=horror',
      image: 'https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/catshorror.png'
    },
    {
      name: 'Queer Stories',
      slug: 'queery',
      link: '/all-books?categories=queer-lit%2Cqueer-studies',
      image: 'https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/catsqueer.png'
    },
    {
      name: 'Art & Visual',
      slug: 'art',
      link: '/all-books?categories=art-visual%2Cartist-book',
      image: 'https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/catsart.png'
    },
    {
      name: 'Essays & Criticism',
      slug: 'essay',
      link: '/all-books?categories=essays%2Ccriticism',
      image: 'https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/catsessay2.png'
    },
    {
      name: 'Magazines & Journals',
      slug: 'magazines',
      link: '/all-books?categories=magazines',
      image: 'https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/cat_mags.png'
    },
    {
      name: 'Short Stories',
      slug: 'stories',
      link: '/all-books?categories=short-stories',
      image: 'https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/catsstories.png'
    },
  ]

  return (
    <div className='roomBelowLarge'>
      <div className='newHead'><h2>Shop by Category</h2></div>
      <div className='categoryGrid'>
        {cats.map((cat, index) => {
          return (<CatBlock key={cat.name} index={index} name={cat.name} slug={cat.slug} link={cat.link} image={cat.image} />)
        })
        }
      </div>
    </div>
  )
}

function CatBlock({ name, slug, link, image, index }) {
  const session = useRecoilValue(sessionState);
  const pageview = useRecoilValue(pageviewState);
  const [background, setBackground] = useState('none');

  const img = useRef();

  useEffect(() => {
    if (index === 0 || index === 4 || index === 8) {
      setBackground('rgba(229, 59, 60, 1)'); // 0.6
    } else if (index === 1 || index === 5 || index === 9) {
      setBackground('#b9cdcc');
    } else if (index === 2 || index === 6) {
      setBackground('rgba(171,149,102, 1)'); // 0.4
    } else {
      setBackground('rgba(0, 55, 75, 1)'); // 0.4
    }
  }, []);



  return (<Link to={link} onClick={() => {
    asaEvent({
      session: session.id,
      category: 'recirc',
      action: 'click',
      label: `categoryBlock-${slug}`,
      locationOne: index,
      locationTwo: '',
      locationThree: '',
      testgroup: session.group,
      pagetype: pageview.pagetype,
      pageview: pageview.id,
      campaign: session.campaign,
      value: 1
    });
  }}>
    <div className='catGrid_image' style={{ background: background }}>
      <img ref={img} src={image} alt='stack of books' />
    </div>
    <div className='catGrid_name'>{name}</div>
  </Link>)
}