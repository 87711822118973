import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from './blog.module.css';
import Blobbiest from '../../icons/blobbiest';
import { makeRequest } from '../../Utils';
import moment from 'moment';


export default function BlogNews() {
  const [headlines, setHeadlines] = useState([])

  const { page } = useParams();
  const navigate = useNavigate();
  const per = 20;


  const getHeadlines = async (page) => {
    let data = await makeRequest(`headlines`);
    setHeadlines(data);
  }

  document.title = `Headlines | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', `News from around the Asterism community of publishers`);

  useEffect(() => {
    getHeadlines();
  }, [page]);

  return (<>
    <h1>News from Asterism</h1>
    <div className={styles.headlineList}>
      {headlines.map((post) => {
        return (
          <div className={styles.headline}>
            <div className={styles.headlineMeta}>{moment(post.publishDate).format('LL')}</div>
            <Link to={`/blog/post/${post.slug}`}><h2>{post.title}</h2></Link>
            <div className={styles.headlineSummary}>{post.summary}</div>
            <Link className='buttonSecondary' to={`/blog/post/${post.slug}`}>Read More</Link>
          </div>
        )
      })}
    </div >
  </>)
}