import React from 'react';
import { useRecoilValue } from 'recoil';
import { cartCountState } from '../../atoms';
import styles from './counter.module.css';

export default function CartCounter({ toggleCart }) {
  const cartCount = useRecoilValue(cartCountState);

  return (
    <div className={styles.counterContainer}>
      <div className={styles.counter} onClick={toggleCart} role='button' tabIndex={0} aria-label='View Cart'>
        <span className={styles.counterIcon}><i className='fa-solid fa-bag-shopping'></i></span>
        <span className={`${styles.counterTag} ${cartCount > 0 && styles.counterTagActive}`}>
          <span className={styles.counterTagNo}>{cartCount}</span>
        </span>
      </div>
    </div>
  )
}