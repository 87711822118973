import React, { useState, useEffect } from 'react';
import { capitalize } from '../../Utils';
import './style.css';

export default function BarStyleGraph({ label, style, data, showTotal = true, autoCap = true }) {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotal(Object.values(data).reduce((partialSum, a) => partialSum + a, 0))
  }, [data]);

  const colors =
    ['#00374b',
      '#f13b3b',
      '#b9cdcc',
      '#97a583',
      '#af945f',
      '#1c1c1c',
      '#1f93b7',
      '#ddc128',
      '#723bb5',
      '#db7b35',
      '#4e9b47',
      '#484b4d',
      '#d55dac',
      '#959065',
      '#5e4e39',
      '#3e5e39',
      '#6287ae',
      '#977575',
      '#b03838',
      '#52b038',
      '#b09838',
      '#7438b0',
      '#b03887',
      '#a1bbd2',
      '#d2b8a1',
      '#ced2a1',
      '#a5d2a1',
      '#d2a1a1',
      '#a1a2d2',
      '#977575',
      '#977575',
      '#977575'];

  const formatData = (no) => {
    if (!no) {
      return 0;
    }
    switch (style) {
      case 'currency':
        return `$${no.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      default:
        return no;
    }
  }

  return (
    <div className='graph'>
      {label && <div className='pseudoLabel'>{label}</div>}
      {showTotal && <div className='bigStat__no'>{formatData(total).toLocaleString()}</div>}
      <div className='barGraph'>
        {Object.keys(data).map((key, index) =>
          <div className='barGraph__data' style={{ background: colors[index], width: `${data[key] / total * 100}%` }}></div>
        )}
      </div>
      <div>
        {Object.keys(data).map((key, index) =>
          <div key={key} className='breakdownLine'>
            <div className='breakdownKey' style={{ background: colors[index] }}></div>
            <div className='breakdownLabel'>{autoCap ? capitalize(key) : key}</div>
            <div className='breakdownTotal'>{formatData(data[key]).toLocaleString()}</div>
          </div>
        )}
      </div>
    </div>
  )
}