
export default function Blobbiest() {

  return (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 516.38 528.49">

      <g id="Layer_1-2" data-name="Layer_1">
        <path className="blob" d="M51.6,440.42c-9.23-40.47,22.69-59.64,8.39-98.89-11.98-32.9-37.61-28.25-52.4-60.61-20.41-44.68,2.65-109.75,41.25-141.63,20.93-17.28,28.62-10.56,60.35-32.21,40.92-27.92,44.98-50.6,70.28-72.3,50.39-43.23,146.39-46.74,187.21-7.26,23.03,22.28,15.73,45.98,50.78,78.11,23.59,21.62,31.62,15.22,52.16,33.66,36,32.33,57.53,93.54,41.25,141.63-10.93,32.27-29.1,29.72-41.3,66.4-14.16,42.56,6.7,56.81-4.07,93.1-14.55,49.03-72.48,89.94-122.38,88-34.1-1.32-37.87-21.63-77.61-23.36-44.08-1.92-52.74,22.48-91.52,23.36-51.57,1.16-111.49-40.27-122.38-88Z" />
      </g>
    </svg>
  )
}
