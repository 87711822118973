export const codes = [
  {
    "Code": "",
    "Literal": ""
  },
  {
    "Code": "ART000000",
    "Literal": "ART / General"
  },
  {
    "Code": "ART015010",
    "Literal": "ART / African"
  },
  {
    "Code": "ART015020",
    "Literal": "ART / American / General"
  },
  {
    "Code": "ART038000",
    "Literal": "ART / American / African American & Black"
  },
  {
    "Code": "ART039000",
    "Literal": "ART / American / Asian American & Pacific Islander"
  },
  {
    "Code": "ART040000",
    "Literal": "ART / American / Hispanic & Latino"
  },
  {
    "Code": "ART069000",
    "Literal": "ART / American / Native American"
  },
  {
    "Code": "ART054000",
    "Literal": "ART / Annuals"
  },
  {
    "Code": "ART037000",
    "Literal": "ART / Art & Politics"
  },
  {
    "Code": "ART019000",
    "Literal": "ART / Asian / General"
  },
  {
    "Code": "ART019040",
    "Literal": "ART / Asian / Central Asian"
  },
  {
    "Code": "ART019010",
    "Literal": "ART / Asian / Chinese"
  },
  {
    "Code": "ART019020",
    "Literal": "ART / Asian / Indian & South Asian"
  },
  {
    "Code": "ART019030",
    "Literal": "ART / Asian / Japanese"
  },
  {
    "Code": "ART019050",
    "Literal": "ART / Asian / Korean"
  },
  {
    "Code": "ART019060",
    "Literal": "ART / Asian / Southeast Asian"
  },
  {
    "Code": "ART042000",
    "Literal": "ART / Australian & Oceanian"
  },
  {
    "Code": "ART055000",
    "Literal": "ART / Body Art & Tattooing"
  },
  {
    "Code": "ART043000",
    "Literal": "ART / Business Aspects"
  },
  {
    "Code": "ART015040",
    "Literal": "ART / Canadian"
  },
  {
    "Code": "ART044000",
    "Literal": "ART / Caribbean & Latin American"
  },
  {
    "Code": "ART068000",
    "Literal": "ART / Celtic Art"
  },
  {
    "Code": "ART045000",
    "Literal": "ART / Ceramics"
  },
  {
    "Code": "ART006000",
    "Literal": "ART / Collections, Catalogs, Exhibitions / General"
  },
  {
    "Code": "ART006010",
    "Literal": "ART / Collections, Catalogs, Exhibitions / Group Shows"
  },
  {
    "Code": "ART006020",
    "Literal": "ART / Collections, Catalogs, Exhibitions / Permanent Collections"
  },
  {
    "Code": "ART007000",
    "Literal": "ART / Color Theory"
  },
  {
    "Code": "ART008000",
    "Literal": "ART / Conceptual"
  },
  {
    "Code": "ART056000",
    "Literal": "ART / Conservation & Preservation"
  },
  {
    "Code": "ART009000",
    "Literal": "ART / Criticism & Theory"
  },
  {
    "Code": "ART046000",
    "Literal": "ART / Digital"
  },
  {
    "Code": "ART063000",
    "Literal": "ART / Environmental & Land Art"
  },
  {
    "Code": "ART015030",
    "Literal": "ART / European"
  },
  {
    "Code": "ART057000",
    "Literal": "ART / Film & Video"
  },
  {
    "Code": "ART013000",
    "Literal": "ART / Folk & Outsider Art"
  },
  {
    "Code": "ART067000",
    "Literal": "ART / Forgeries"
  },
  {
    "Code": "ART061000",
    "Literal": "ART / Glass"
  },
  {
    "Code": "ART058000",
    "Literal": "ART / Graffiti & Street Art"
  },
  {
    "Code": "ART015000",
    "Literal": "ART / History / General"
  },
  {
    "Code": "ART015050",
    "Literal": "ART / History / Prehistoric"
  },
  {
    "Code": "ART015060",
    "Literal": "ART / History / Ancient & Classical"
  },
  {
    "Code": "ART015130",
    "Literal": "ART / History / European / General"
  },
  {
    "Code": "ART015070",
    "Literal": "ART / History / European / Medieval"
  },
  {
    "Code": "ART015080",
    "Literal": "ART / History / European / Renaissance"
  },
  {
    "Code": "ART015090",
    "Literal": "ART / History / European / Baroque & Rococo"
  },
  {
    "Code": "ART015120",
    "Literal": "ART / History / European / Romanticism"
  },
  {
    "Code": "ART015140",
    "Literal": "ART / History / Indigenous"
  },
  {
    "Code": "ART015100",
    "Literal": "ART / History / Modern (late 19th Century to 1945)"
  },
  {
    "Code": "ART015110",
    "Literal": "ART / History / Contemporary (1945-)"
  },
  {
    "Code": "ART041000",
    "Literal": "ART / Indigenous"
  },
  {
    "Code": "ART070000",
    "Literal": "ART / Indigenous Art of Turtle Island"
  },
  {
    "Code": "ART016000",
    "Literal": "ART / Individual Artists / General"
  },
  {
    "Code": "ART016010",
    "Literal": "ART / Individual Artists / Artists' Books"
  },
  {
    "Code": "ART016020",
    "Literal": "ART / Individual Artists / Essays"
  },
  {
    "Code": "ART016030",
    "Literal": "ART / Individual Artists / Monographs"
  },
  {
    "Code": "ART066000",
    "Literal": "ART / LGBTQ+ Artists"
  },
  {
    "Code": "ART047000",
    "Literal": "ART / Middle Eastern"
  },
  {
    "Code": "ART017000",
    "Literal": "ART / Mixed Media"
  },
  {
    "Code": "ART059000",
    "Literal": "ART / Museum Studies"
  },
  {
    "Code": "ART060000",
    "Literal": "ART / Performance"
  },
  {
    "Code": "ART023000",
    "Literal": "ART / Popular Culture"
  },
  {
    "Code": "ART048000",
    "Literal": "ART / Prints"
  },
  {
    "Code": "ART062000",
    "Literal": "ART / Public Art"
  },
  {
    "Code": "ART025000",
    "Literal": "ART / Reference"
  },
  {
    "Code": "ART049000",
    "Literal": "ART / Russian & Soviet"
  },
  {
    "Code": "ART026000",
    "Literal": "ART / Sculpture & Installation"
  },
  {
    "Code": "ART027000",
    "Literal": "ART / Study & Teaching"
  },
  {
    "Code": "ART050000",
    "Literal": "ART / Subjects & Themes / General"
  },
  {
    "Code": "ART050080",
    "Literal": "ART / Subjects & Themes / Buildings & Cityscapes"
  },
  {
    "Code": "ART050050",
    "Literal": "ART / Subjects & Themes / Erotica"
  },
  {
    "Code": "ART050010",
    "Literal": "ART / Subjects & Themes / Human Figure"
  },
  {
    "Code": "ART050020",
    "Literal": "ART / Subjects & Themes / Landscapes & Seascapes"
  },
  {
    "Code": "ART050030",
    "Literal": "ART / Subjects & Themes / Plants & Animals"
  },
  {
    "Code": "ART050040",
    "Literal": "ART / Subjects & Themes / Portraits"
  },
  {
    "Code": "ART035000",
    "Literal": "ART / Subjects & Themes / Religious"
  },
  {
    "Code": "ART050060",
    "Literal": "ART / Subjects & Themes / Science Fiction & Fantasy"
  },
  {
    "Code": "ART050070",
    "Literal": "ART / Subjects & Themes / Still Life"
  },
  {
    "Code": "ART028000",
    "Literal": "ART / Techniques / General"
  },
  {
    "Code": "ART031000",
    "Literal": "ART / Techniques / Acrylic Painting"
  },
  {
    "Code": "ART002000",
    "Literal": "ART / Techniques / Airbrush"
  },
  {
    "Code": "ART071000",
    "Literal": "ART / Techniques / Basketry"
  },
  {
    "Code": "ART072000",
    "Literal": "ART / Techniques / Beadwork"
  },
  {
    "Code": "ART003000",
    "Literal": "ART / Techniques / Calligraphy"
  },
  {
    "Code": "ART004000",
    "Literal": "ART / Techniques / Cartooning"
  },
  {
    "Code": "ART051000",
    "Literal": "ART / Techniques / Color"
  },
  {
    "Code": "ART073000",
    "Literal": "ART / Techniques / Composition"
  },
  {
    "Code": "ART010000",
    "Literal": "ART / Techniques / Drawing"
  },
  {
    "Code": "ART074000",
    "Literal": "ART / Techniques / Leatherwork & Hidework"
  },
  {
    "Code": "ART052000",
    "Literal": "ART / Techniques / Life Drawing"
  },
  {
    "Code": "ART018000",
    "Literal": "ART / Techniques / Oil Painting"
  },
  {
    "Code": "ART020000",
    "Literal": "ART / Techniques / Painting"
  },
  {
    "Code": "ART021000",
    "Literal": "ART / Techniques / Pastel Drawing"
  },
  {
    "Code": "ART033000",
    "Literal": "ART / Techniques / Pen & Ink Drawing"
  },
  {
    "Code": "ART034000",
    "Literal": "ART / Techniques / Pencil Drawing"
  },
  {
    "Code": "ART024000",
    "Literal": "ART / Techniques / Printmaking"
  },
  {
    "Code": "ART075000",
    "Literal": "ART / Techniques / Quillwork"
  },
  {
    "Code": "ART053000",
    "Literal": "ART / Techniques / Sculpting"
  },
  {
    "Code": "ART076000",
    "Literal": "ART / Techniques / Textiles & Weaving"
  },
  {
    "Code": "ART029000",
    "Literal": "ART / Techniques / Watercolor Painting"
  },
  {
    "Code": "ART064000",
    "Literal": "ART / Video Game Art"
  },
  {
    "Code": "ART065000",
    "Literal": "ART / Women Artists"
  },
  {
    "Code": "BIO000000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / General"
  },
  {
    "Code": "BIO023000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Adventurers & Explorers"
  },
  {
    "Code": "BIO002010",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / African American & Black"
  },
  {
    "Code": "BIO002040",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Arab & Middle Eastern"
  },
  {
    "Code": "BIO001000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Artists, Architects, Photographers"
  },
  {
    "Code": "BIO002020",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Asian & Asian American"
  },
  {
    "Code": "BIO034000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Aviation & Nautical"
  },
  {
    "Code": "BIO003000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Business"
  },
  {
    "Code": "BIO024000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Criminals & Outlaws"
  },
  {
    "Code": "BIO029000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Culinary"
  },
  {
    "Code": "BIO002000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Cultural & Regional"
  },
  {
    "Code": "BIO025000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Editors, Journalists, Publishers"
  },
  {
    "Code": "BIO019000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Educators"
  },
  {
    "Code": "BIO005000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Entertainment & Performing Arts"
  },
  {
    "Code": "BIO030000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Environmentalists & Naturalists"
  },
  {
    "Code": "BIO035000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Fashion"
  },
  {
    "Code": "BIO036000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Fire & Emergency Services"
  },
  {
    "Code": "BIO002030",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Hispanic & Latino"
  },
  {
    "Code": "BIO006000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Historical"
  },
  {
    "Code": "BIO028000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Indigenous"
  },
  {
    "Code": "BIO039000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Indigenous Peoples of Turtle Island"
  },
  {
    "Code": "BIO037000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Jewish"
  },
  {
    "Code": "BIO027000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Law Enforcement"
  },
  {
    "Code": "BIO020000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Lawyers & Judges"
  },
  {
    "Code": "BIO031000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / LGBTQ+"
  },
  {
    "Code": "BIO007000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Literary Figures"
  },
  {
    "Code": "BIO017000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Medical (incl. Patients)"
  },
  {
    "Code": "BIO026000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Memoirs"
  },
  {
    "Code": "BIO008000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Military"
  },
  {
    "Code": "BIO004000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Music"
  },
  {
    "Code": "BIO040000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Native American"
  },
  {
    "Code": "BIO033000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / People with Disabilities"
  },
  {
    "Code": "BIO009000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Philosophers"
  },
  {
    "Code": "BIO010000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Political"
  },
  {
    "Code": "BIO011000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Presidents & Heads of State"
  },
  {
    "Code": "BIO012000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Reference"
  },
  {
    "Code": "BIO018000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Religious"
  },
  {
    "Code": "BIO013000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Rich & Famous"
  },
  {
    "Code": "BIO014000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Royalty"
  },
  {
    "Code": "BIO015000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Science & Technology"
  },
  {
    "Code": "BIO032000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Social Activists"
  },
  {
    "Code": "BIO021000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Social Scientists & Psychologists"
  },
  {
    "Code": "BIO016000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Sports"
  },
  {
    "Code": "BIO038000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Survival"
  },
  {
    "Code": "BIO022000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Women"
  },
  {
    "Code": "OCC000000",
    "Literal": "BODY, MIND & SPIRIT / General"
  },
  {
    "Code": "CGN000000",
    "Literal": "COMICS & GRAPHIC NOVELS / General"
  },
  {
    "Code": "CGN018000",
    "Literal": "COMICS & GRAPHIC NOVELS / Action & Adventure"
  },
  {
    "Code": "CGN012000",
    "Literal": "COMICS & GRAPHIC NOVELS / Adaptations"
  },
  {
    "Code": "CGN022000",
    "Literal": "COMICS & GRAPHIC NOVELS / African American & Black"
  },
  {
    "Code": "CGN001000",
    "Literal": "COMICS & GRAPHIC NOVELS / Anthologies"
  },
  {
    "Code": "CGN023000",
    "Literal": "COMICS & GRAPHIC NOVELS / Asian American & Pacific Islander"
  },
  {
    "Code": "CGN024000",
    "Literal": "COMICS & GRAPHIC NOVELS / Coming of Age"
  },
  {
    "Code": "CGN008000",
    "Literal": "COMICS & GRAPHIC NOVELS / Contemporary Women"
  },
  {
    "Code": "CGN004010",
    "Literal": "COMICS & GRAPHIC NOVELS / Crime & Mystery"
  },
  {
    "Code": "CGN025000",
    "Literal": "COMICS & GRAPHIC NOVELS / Diversity & Multicultural"
  },
  {
    "Code": "CGN013000",
    "Literal": "COMICS & GRAPHIC NOVELS / Dystopian"
  },
  {
    "Code": "CGN004340",
    "Literal": "COMICS & GRAPHIC NOVELS / East Asian Style / General"
  },
  {
    "Code": "CGN004050",
    "Literal": "COMICS & GRAPHIC NOVELS / East Asian Style / Manga / General"
  },
  {
    "Code": "CGN004110",
    "Literal": "COMICS & GRAPHIC NOVELS / East Asian Style / Manga / Erotica & Hentai"
  },
  {
    "Code": "CGN004300",
    "Literal": "COMICS & GRAPHIC NOVELS / East Asian Style / Manga / Isekai"
  },
  {
    "Code": "CGN004260",
    "Literal": "COMICS & GRAPHIC NOVELS / East Asian Style / Manga / Magical Girl"
  },
  {
    "Code": "CGN004270",
    "Literal": "COMICS & GRAPHIC NOVELS / East Asian Style / Manga / Mecha"
  },
  {
    "Code": "CGN004210",
    "Literal": "COMICS & GRAPHIC NOVELS / East Asian Style / Manga / Yaoi (Boys' Love)"
  },
  {
    "Code": "CGN004310",
    "Literal": "COMICS & GRAPHIC NOVELS / East Asian Style / Manga / Yuri (Girls' Love)"
  },
  {
    "Code": "CGN004350",
    "Literal": "COMICS & GRAPHIC NOVELS / East Asian Style / Manhua"
  },
  {
    "Code": "CGN021000",
    "Literal": "COMICS & GRAPHIC NOVELS / East Asian Style / Manhwa"
  },
  {
    "Code": "CGN020000",
    "Literal": "COMICS & GRAPHIC NOVELS / European Style (incl. Bandes Dessinées)"
  },
  {
    "Code": "CGN004020",
    "Literal": "COMICS & GRAPHIC NOVELS / Erotica"
  },
  {
    "Code": "CGN019000",
    "Literal": "COMICS & GRAPHIC NOVELS / Fairy Tales, Folk Tales, Legends & Mythology"
  },
  {
    "Code": "CGN004030",
    "Literal": "COMICS & GRAPHIC NOVELS / Fantasy / General"
  },
  {
    "Code": "CGN004360",
    "Literal": "COMICS & GRAPHIC NOVELS / Fantasy / Dark Fantasy"
  },
  {
    "Code": "CGN004370",
    "Literal": "COMICS & GRAPHIC NOVELS / Fantasy / Dragons & Mythical Creatures"
  },
  {
    "Code": "CGN004380",
    "Literal": "COMICS & GRAPHIC NOVELS / Fantasy / Epic"
  },
  {
    "Code": "CGN026000",
    "Literal": "COMICS & GRAPHIC NOVELS / Feminist"
  },
  {
    "Code": "CGN004330",
    "Literal": "COMICS & GRAPHIC NOVELS / Food"
  },
  {
    "Code": "CGN027000",
    "Literal": "COMICS & GRAPHIC NOVELS / Hispanic & Latino"
  },
  {
    "Code": "CGN010000",
    "Literal": "COMICS & GRAPHIC NOVELS / Historical Fiction / General"
  },
  {
    "Code": "CGN010010",
    "Literal": "COMICS & GRAPHIC NOVELS / Historical Fiction / Ancient"
  },
  {
    "Code": "CGN010020",
    "Literal": "COMICS & GRAPHIC NOVELS / Historical Fiction / Medieval"
  },
  {
    "Code": "CGN010030",
    "Literal": "COMICS & GRAPHIC NOVELS / Historical Fiction / World War II & Holocaust"
  },
  {
    "Code": "CGN004040",
    "Literal": "COMICS & GRAPHIC NOVELS / Horror"
  },
  {
    "Code": "CGN014000",
    "Literal": "COMICS & GRAPHIC NOVELS / Humorous"
  },
  {
    "Code": "CGN028000",
    "Literal": "COMICS & GRAPHIC NOVELS / Indigenous"
  },
  {
    "Code": "CGN029000",
    "Literal": "COMICS & GRAPHIC NOVELS / Indigenous Peoples of Turtle Island"
  },
  {
    "Code": "CGN030000",
    "Literal": "COMICS & GRAPHIC NOVELS / Jewish"
  },
  {
    "Code": "CGN009000",
    "Literal": "COMICS & GRAPHIC NOVELS / LGBTQ+ / General"
  },
  {
    "Code": "CGN009010",
    "Literal": "COMICS & GRAPHIC NOVELS / LGBTQ+ / Bisexual"
  },
  {
    "Code": "CGN009020",
    "Literal": "COMICS & GRAPHIC NOVELS / LGBTQ+ / Gay"
  },
  {
    "Code": "CGN009030",
    "Literal": "COMICS & GRAPHIC NOVELS / LGBTQ+ / Lesbian"
  },
  {
    "Code": "CGN009040",
    "Literal": "COMICS & GRAPHIC NOVELS / LGBTQ+ / Transgender"
  },
  {
    "Code": "CGN009050",
    "Literal": "COMICS & GRAPHIC NOVELS / LGBTQ+ / Two-Spirited & Indigiqueer"
  },
  {
    "Code": "CGN004320",
    "Literal": "COMICS & GRAPHIC NOVELS / Light Novel"
  },
  {
    "Code": "CGN006000",
    "Literal": "COMICS & GRAPHIC NOVELS / Literary"
  },
  {
    "Code": "CGN031000",
    "Literal": "COMICS & GRAPHIC NOVELS / Magical Realism"
  },
  {
    "Code": "CGN004060",
    "Literal": "COMICS & GRAPHIC NOVELS / Media Tie-In"
  },
  {
    "Code": "CGN032000",
    "Literal": "COMICS & GRAPHIC NOVELS / Native American"
  },
  {
    "Code": "CGN007000",
    "Literal": "COMICS & GRAPHIC NOVELS / Nonfiction / General"
  },
  {
    "Code": "CGN007010",
    "Literal": "COMICS & GRAPHIC NOVELS / Nonfiction / Biography & Memoir"
  },
  {
    "Code": "CGN007020",
    "Literal": "COMICS & GRAPHIC NOVELS / Nonfiction / History"
  },
  {
    "Code": "CGN007030",
    "Literal": "COMICS & GRAPHIC NOVELS / Nonfiction / Journalism"
  },
  {
    "Code": "CGN007050",
    "Literal": "COMICS & GRAPHIC NOVELS / Nonfiction / Medicine"
  },
  {
    "Code": "CGN007060",
    "Literal": "COMICS & GRAPHIC NOVELS / Nonfiction / Politics & Philosophy"
  },
  {
    "Code": "CGN007040",
    "Literal": "COMICS & GRAPHIC NOVELS / Nonfiction / Science & Nature"
  },
  {
    "Code": "CGN007070",
    "Literal": "COMICS & GRAPHIC NOVELS / Nonfiction / Self-Help"
  },
  {
    "Code": "CGN016000",
    "Literal": "COMICS & GRAPHIC NOVELS / Occult & Supernatural"
  },
  {
    "Code": "CGN015000",
    "Literal": "COMICS & GRAPHIC NOVELS / Reference, Guides & Companions"
  },
  {
    "Code": "CGN011000",
    "Literal": "COMICS & GRAPHIC NOVELS / Religious"
  },
  {
    "Code": "CGN004090",
    "Literal": "COMICS & GRAPHIC NOVELS / Romance"
  },
  {
    "Code": "CGN033000",
    "Literal": "COMICS & GRAPHIC NOVELS / Satire"
  },
  {
    "Code": "CGN004280",
    "Literal": "COMICS & GRAPHIC NOVELS / School Life"
  },
  {
    "Code": "CGN004070",
    "Literal": "COMICS & GRAPHIC NOVELS / Science Fiction / General"
  },
  {
    "Code": "CGN004390",
    "Literal": "COMICS & GRAPHIC NOVELS / Science Fiction / Alien Contact"
  },
  {
    "Code": "CGN004400",
    "Literal": "COMICS & GRAPHIC NOVELS / Science Fiction / Apocalyptic & Post-Apocalyptic"
  },
  {
    "Code": "CGN004410",
    "Literal": "COMICS & GRAPHIC NOVELS / Science Fiction / Cyberpunk"
  },
  {
    "Code": "CGN004420",
    "Literal": "COMICS & GRAPHIC NOVELS / Science Fiction / Steampunk"
  },
  {
    "Code": "CGN034000",
    "Literal": "COMICS & GRAPHIC NOVELS / Slice of Life"
  },
  {
    "Code": "CGN035000",
    "Literal": "COMICS & GRAPHIC NOVELS / Spies & Espionage"
  },
  {
    "Code": "CGN004200",
    "Literal": "COMICS & GRAPHIC NOVELS / Sports"
  },
  {
    "Code": "CGN004080",
    "Literal": "COMICS & GRAPHIC NOVELS / Superheroes (see also FICTION / Superheroes)"
  },
  {
    "Code": "CGN017000",
    "Literal": "COMICS & GRAPHIC NOVELS / Supervillains"
  },
  {
    "Code": "CRA046000",
    "Literal": "CRAFTS & HOBBIES / Book Printing & Binding"
  },
  {
    "Code": "DES000000",
    "Literal": "DESIGN / General"
  },
  {
    "Code": "DES001000",
    "Literal": "DESIGN / Book"
  },
  {
    "Code": "DES002000",
    "Literal": "DESIGN / Clip Art"
  },
  {
    "Code": "DES003000",
    "Literal": "DESIGN / Decorative Arts"
  },
  {
    "Code": "DES004000",
    "Literal": "DESIGN / Essays"
  },
  {
    "Code": "DES005000",
    "Literal": "DESIGN / Fashion & Accessories"
  },
  {
    "Code": "DES006000",
    "Literal": "DESIGN / Furniture"
  },
  {
    "Code": "DES007000",
    "Literal": "DESIGN / Graphic Arts / General"
  },
  {
    "Code": "DES007010",
    "Literal": "DESIGN / Graphic Arts / Advertising"
  },
  {
    "Code": "DES007020",
    "Literal": "DESIGN / Graphic Arts / Branding & Logo Design"
  },
  {
    "Code": "DES007030",
    "Literal": "DESIGN / Graphic Arts / Commercial & Corporate"
  },
  {
    "Code": "DES007040",
    "Literal": "DESIGN / Graphic Arts / Illustration"
  },
  {
    "Code": "DES007050",
    "Literal": "DESIGN / Graphic Arts / Typography"
  },
  {
    "Code": "DES008000",
    "Literal": "DESIGN / History & Criticism"
  },
  {
    "Code": "DES015000",
    "Literal": "DESIGN / Individual Designers"
  },
  {
    "Code": "DES009000",
    "Literal": "DESIGN / Industrial"
  },
  {
    "Code": "DES014000",
    "Literal": "DESIGN / Jewelry"
  },
  {
    "Code": "DES011000",
    "Literal": "DESIGN / Product"
  },
  {
    "Code": "DES012000",
    "Literal": "DESIGN / Reference"
  },
  {
    "Code": "DES013000",
    "Literal": "DESIGN / Textile & Costume"
  },
  {
    "Code": "DRA000000",
    "Literal": "DRAMA / General"
  },
  {
    "Code": "DRA011000",
    "Literal": "DRAMA / African"
  },
  {
    "Code": "DRA001000",
    "Literal": "DRAMA / American / General"
  },
  {
    "Code": "DRA001010",
    "Literal": "DRAMA / American / African American & Black"
  },
  {
    "Code": "DRA001020",
    "Literal": "DRAMA / American / Asian & Pacific Islander"
  },
  {
    "Code": "DRA001030",
    "Literal": "DRAMA / American / Hispanic & Latino"
  },
  {
    "Code": "DRA006000",
    "Literal": "DRAMA / Ancient & Classical"
  },
  {
    "Code": "DRA002000",
    "Literal": "DRAMA / Anthologies (multiple authors)"
  },
  {
    "Code": "DRA005000",
    "Literal": "DRAMA / Asian / General"
  },
  {
    "Code": "DRA005010",
    "Literal": "DRAMA / Asian / Japanese"
  },
  {
    "Code": "DRA012000",
    "Literal": "DRAMA / Australian & Oceanian"
  },
  {
    "Code": "DRA013000",
    "Literal": "DRAMA / Canadian"
  },
  {
    "Code": "DRA014000",
    "Literal": "DRAMA / Caribbean & Latin American"
  },
  {
    "Code": "DRA021000",
    "Literal": "DRAMA / Contemporary"
  },
  {
    "Code": "DRA004000",
    "Literal": "DRAMA / European / General"
  },
  {
    "Code": "DRA004050",
    "Literal": "DRAMA / European / Eastern"
  },
  {
    "Code": "DRA003000",
    "Literal": "DRAMA / European / English, Irish, Scottish, Welsh"
  },
  {
    "Code": "DRA004010",
    "Literal": "DRAMA / European / French"
  },
  {
    "Code": "DRA004020",
    "Literal": "DRAMA / European / German"
  },
  {
    "Code": "DRA004030",
    "Literal": "DRAMA / European / Italian"
  },
  {
    "Code": "DRA004040",
    "Literal": "DRAMA / European / Spanish & Portuguese"
  },
  {
    "Code": "DRA020000",
    "Literal": "DRAMA / Indigenous"
  },
  {
    "Code": "DRA022000",
    "Literal": "DRAMA / Indigenous Peoples of Turtle Island"
  },
  {
    "Code": "DRA028000",
    "Literal": "DRAMA / Jewish"
  },
  {
    "Code": "DRA017000",
    "Literal": "DRAMA / LGBTQ+"
  },
  {
    "Code": "DRA018000",
    "Literal": "DRAMA / Medieval"
  },
  {
    "Code": "DRA015000",
    "Literal": "DRAMA / Middle Eastern"
  },
  {
    "Code": "DRA023000",
    "Literal": "DRAMA / Native American"
  },
  {
    "Code": "DRA016000",
    "Literal": "DRAMA / Russian & Soviet"
  },
  {
    "Code": "DRA010000",
    "Literal": "DRAMA / Shakespeare"
  },
  {
    "Code": "DRA024000",
    "Literal": "DRAMA / Type / Comedy"
  },
  {
    "Code": "DRA025000",
    "Literal": "DRAMA / Type / Historical"
  },
  {
    "Code": "DRA008000",
    "Literal": "DRAMA / Type / Religious & Liturgical"
  },
  {
    "Code": "DRA026000",
    "Literal": "DRAMA / Type / Tragedy"
  },
  {
    "Code": "DRA027000",
    "Literal": "DRAMA / Type / Tragicomedy"
  },
  {
    "Code": "DRA019000",
    "Literal": "DRAMA / Women Authors"
  },
  {
    "Code": "EDU000000",
    "Literal": "EDUCATION / General"
  },
  {
    "Code": "EDU001000",
    "Literal": "EDUCATION / Administration / General"
  },
  {
    "Code": "EDU001020",
    "Literal": "EDUCATION / Administration / Elementary & Secondary"
  },
  {
    "Code": "EDU001010",
    "Literal": "EDUCATION / Administration / Facility Management"
  },
  {
    "Code": "EDU001030",
    "Literal": "EDUCATION / Administration / Higher"
  },
  {
    "Code": "EDU001040",
    "Literal": "EDUCATION / Administration / School Superintendents & Principals"
  },
  {
    "Code": "EDU002000",
    "Literal": "EDUCATION / Adult & Continuing Education"
  },
  {
    "Code": "EDU003000",
    "Literal": "EDUCATION / Aims & Objectives"
  },
  {
    "Code": "EDU057000",
    "Literal": "EDUCATION / Arts in Education"
  },
  {
    "Code": "EDU049000",
    "Literal": "EDUCATION / Behavioral Management"
  },
  {
    "Code": "EDU005000",
    "Literal": "EDUCATION / Bilingual Education"
  },
  {
    "Code": "EDU044000",
    "Literal": "EDUCATION / Classroom Management"
  },
  {
    "Code": "EDU050000",
    "Literal": "EDUCATION / Collaborative & Team Teaching"
  },
  {
    "Code": "EDU043000",
    "Literal": "EDUCATION / Comparative"
  },
  {
    "Code": "EDU039000",
    "Literal": "EDUCATION / Computers & Technology"
  },
  {
    "Code": "EDU006000",
    "Literal": "EDUCATION / Counseling / General"
  },
  {
    "Code": "EDU014000",
    "Literal": "EDUCATION / Counseling / Academic Development"
  },
  {
    "Code": "EDU031000",
    "Literal": "EDUCATION / Counseling / Career Development"
  },
  {
    "Code": "EDU045000",
    "Literal": "EDUCATION / Counseling / Crisis Management"
  },
  {
    "Code": "EDU062000",
    "Literal": "EDUCATION / Cultural Pedagogies"
  },
  {
    "Code": "EDU007000",
    "Literal": "EDUCATION / Curricula"
  },
  {
    "Code": "EDU008000",
    "Literal": "EDUCATION / Decision-Making & Problem Solving"
  },
  {
    "Code": "EDU041000",
    "Literal": "EDUCATION / Distance, Open & Online Education"
  },
  {
    "Code": "EDU034000",
    "Literal": "EDUCATION / Educational Policy & Reform / General"
  },
  {
    "Code": "EDU034030",
    "Literal": "EDUCATION / Educational Policy & Reform / Federal Legislation"
  },
  {
    "Code": "EDU034010",
    "Literal": "EDUCATION / Educational Policy & Reform / School Safety"
  },
  {
    "Code": "EDU009000",
    "Literal": "EDUCATION / Educational Psychology"
  },
  {
    "Code": "EDU042000",
    "Literal": "EDUCATION / Essays"
  },
  {
    "Code": "EDU011000",
    "Literal": "EDUCATION / Evaluation & Assessment"
  },
  {
    "Code": "EDU013000",
    "Literal": "EDUCATION / Finance"
  },
  {
    "Code": "EDU016000",
    "Literal": "EDUCATION / History"
  },
  {
    "Code": "EDU017000",
    "Literal": "EDUCATION / Home Schooling"
  },
  {
    "Code": "EDU048000",
    "Literal": "EDUCATION / Inclusive Education"
  },
  {
    "Code": "EDU018000",
    "Literal": "EDUCATION / Language Experience Approach"
  },
  {
    "Code": "EDU032000",
    "Literal": "EDUCATION / Leadership"
  },
  {
    "Code": "EDU051000",
    "Literal": "EDUCATION / Learning Styles"
  },
  {
    "Code": "EDU020000",
    "Literal": "EDUCATION / Multicultural Education"
  },
  {
    "Code": "EDU021000",
    "Literal": "EDUCATION / Non-Formal Education"
  },
  {
    "Code": "EDU036000",
    "Literal": "EDUCATION / Organizations & Institutions"
  },
  {
    "Code": "EDU022000",
    "Literal": "EDUCATION / Parent Participation"
  },
  {
    "Code": "EDU040000",
    "Literal": "EDUCATION / Philosophy, Theory & Social Aspects"
  },
  {
    "Code": "EDU046000",
    "Literal": "EDUCATION / Professional Development"
  },
  {
    "Code": "EDU024000",
    "Literal": "EDUCATION / Reference"
  },
  {
    "Code": "EDU037000",
    "Literal": "EDUCATION / Research"
  },
  {
    "Code": "EDU052000",
    "Literal": "EDUCATION / Rural"
  },
  {
    "Code": "EDU060000",
    "Literal": "EDUCATION / Schools / General"
  },
  {
    "Code": "EDU060010",
    "Literal": "EDUCATION / Schools / Levels / General"
  },
  {
    "Code": "EDU023000",
    "Literal": "EDUCATION / Schools / Levels / Early Childhood (incl. Preschool & Kindergarten)"
  },
  {
    "Code": "EDU010000",
    "Literal": "EDUCATION / Schools / Levels / Elementary"
  },
  {
    "Code": "EDU025000",
    "Literal": "EDUCATION / Schools / Levels / Secondary"
  },
  {
    "Code": "EDU015000",
    "Literal": "EDUCATION / Schools / Levels / Higher"
  },
  {
    "Code": "EDU060020",
    "Literal": "EDUCATION / Schools / Types / General"
  },
  {
    "Code": "EDU034020",
    "Literal": "EDUCATION / Schools / Types / Charter"
  },
  {
    "Code": "EDU060030",
    "Literal": "EDUCATION / Schools / Types / Private"
  },
  {
    "Code": "EDU060040",
    "Literal": "EDUCATION / Schools / Types / Public"
  },
  {
    "Code": "EDU060050",
    "Literal": "EDUCATION / Schools / Types / Religious"
  },
  {
    "Code": "EDU026000",
    "Literal": "EDUCATION / Special Education / General"
  },
  {
    "Code": "EDU026050",
    "Literal": "EDUCATION / Special Education / Behavioral, Emotional & Social Disabilities"
  },
  {
    "Code": "EDU026010",
    "Literal": "EDUCATION / Special Education / Communicative Disorders"
  },
  {
    "Code": "EDU026030",
    "Literal": "EDUCATION / Special Education / Developmental & Intellectual Disabilities"
  },
  {
    "Code": "EDU026060",
    "Literal": "EDUCATION / Special Education / Gifted"
  },
  {
    "Code": "EDU026020",
    "Literal": "EDUCATION / Special Education / Learning Disabilities"
  },
  {
    "Code": "EDU026040",
    "Literal": "EDUCATION / Special Education / Physical Disabilities"
  },
  {
    "Code": "EDU058000",
    "Literal": "EDUCATION / Standards (incl. Common Core)"
  },
  {
    "Code": "EDU027000",
    "Literal": "EDUCATION / Statistics"
  },
  {
    "Code": "EDU038000",
    "Literal": "EDUCATION / Student Life & Student Affairs"
  },
  {
    "Code": "EDU059000",
    "Literal": "EDUCATION / Teacher & Student Mentoring"
  },
  {
    "Code": "EDU053000",
    "Literal": "EDUCATION / Teacher Training & Certification"
  },
  {
    "Code": "EDU029000",
    "Literal": "EDUCATION / Teaching / General"
  },
  {
    "Code": "EDU029090",
    "Literal": "EDUCATION / Teaching / Materials & Devices"
  },
  {
    "Code": "EDU029100",
    "Literal": "EDUCATION / Teaching / Methods & Strategies"
  },
  {
    "Code": "EDU029110",
    "Literal": "EDUCATION / Teaching / Subjects / General"
  },
  {
    "Code": "EDU029050",
    "Literal": "EDUCATION / Teaching / Subjects / Arts & Humanities"
  },
  {
    "Code": "EDU029070",
    "Literal": "EDUCATION / Teaching / Subjects / Health & Sexuality"
  },
  {
    "Code": "EDU029080",
    "Literal": "EDUCATION / Teaching / Subjects / Language Arts"
  },
  {
    "Code": "EDU029060",
    "Literal": "EDUCATION / Teaching / Subjects / Library Skills"
  },
  {
    "Code": "EDU029010",
    "Literal": "EDUCATION / Teaching / Subjects / Mathematics"
  },
  {
    "Code": "EDU033000",
    "Literal": "EDUCATION / Teaching / Subjects / Physical Education"
  },
  {
    "Code": "EDU029020",
    "Literal": "EDUCATION / Teaching / Subjects / Reading & Phonics"
  },
  {
    "Code": "EDU029030",
    "Literal": "EDUCATION / Teaching / Subjects / Science & Technology"
  },
  {
    "Code": "EDU029040",
    "Literal": "EDUCATION / Teaching / Subjects / Social Science"
  },
  {
    "Code": "EDU030000",
    "Literal": "EDUCATION / Testing & Measurement"
  },
  {
    "Code": "EDU054000",
    "Literal": "EDUCATION / Urban"
  },
  {
    "Code": "EDU055000",
    "Literal": "EDUCATION / Violence & Harassment"
  },
  {
    "Code": "EDU061000",
    "Literal": "EDUCATION / Virtual & Hybrid"
  },
  {
    "Code": "EDU056000",
    "Literal": "EDUCATION / Vocational & Technical"
  },
  {
    "Code": "FIC000000",
    "Literal": "FICTION / General"
  },
  {
    "Code": "FIC064000",
    "Literal": "FICTION / Absurdist"
  },
  {
    "Code": "FIC002000",
    "Literal": "FICTION / Action & Adventure"
  },
  {
    "Code": "FIC075000",
    "Literal": "FICTION / Adaptations & Pastiche"
  },
  {
    "Code": "FIC049000",
    "Literal": "FICTION / African American & Black / General"
  },
  {
    "Code": "FIC049010",
    "Literal": "FICTION / African American & Black / Christian"
  },
  {
    "Code": "FIC049030",
    "Literal": "FICTION / African American & Black / Erotica"
  },
  {
    "Code": "FIC049040",
    "Literal": "FICTION / African American & Black / Historical"
  },
  {
    "Code": "FIC049050",
    "Literal": "FICTION / African American & Black / Mystery & Detective"
  },
  {
    "Code": "FIC049070",
    "Literal": "FICTION / African American & Black / Urban & Street Lit"
  },
  {
    "Code": "FIC049020",
    "Literal": "FICTION / African American & Black / Women"
  },
  {
    "Code": "FIC040000",
    "Literal": "FICTION / Alternative History"
  },
  {
    "Code": "FIC053000",
    "Literal": "FICTION / Amish & Mennonite"
  },
  {
    "Code": "FIC067000",
    "Literal": "FICTION / Animals"
  },
  {
    "Code": "FIC003000",
    "Literal": "FICTION / Anthologies (multiple authors)"
  },
  {
    "Code": "FIC054000",
    "Literal": "FICTION / Asian American & Pacific Islander"
  },
  {
    "Code": "FIC041000",
    "Literal": "FICTION / Biographical"
  },
  {
    "Code": "FIC078000",
    "Literal": "FICTION / Buddhist"
  },
  {
    "Code": "FIC042000",
    "Literal": "FICTION / Christian / General"
  },
  {
    "Code": "FIC042090",
    "Literal": "FICTION / Christian / Biblical"
  },
  {
    "Code": "FIC042010",
    "Literal": "FICTION / Christian / Classic & Allegory"
  },
  {
    "Code": "FIC042050",
    "Literal": "FICTION / Christian / Collections & Anthologies"
  },
  {
    "Code": "FIC042100",
    "Literal": "FICTION / Christian / Contemporary"
  },
  {
    "Code": "FIC042080",
    "Literal": "FICTION / Christian / Fantasy"
  },
  {
    "Code": "FIC042020",
    "Literal": "FICTION / Christian / Futuristic"
  },
  {
    "Code": "FIC042030",
    "Literal": "FICTION / Christian / Historical"
  },
  {
    "Code": "FIC042040",
    "Literal": "FICTION / Christian / Romance / General"
  },
  {
    "Code": "FIC042110",
    "Literal": "FICTION / Christian / Romance / Historical"
  },
  {
    "Code": "FIC042120",
    "Literal": "FICTION / Christian / Romance / Suspense"
  },
  {
    "Code": "FIC042060",
    "Literal": "FICTION / Christian / Suspense"
  },
  {
    "Code": "FIC042070",
    "Literal": "FICTION / Christian / Western"
  },
  {
    "Code": "FIC069000",
    "Literal": "FICTION / City Life"
  },
  {
    "Code": "FIC004000",
    "Literal": "FICTION / Classics"
  },
  {
    "Code": "FIC043000",
    "Literal": "FICTION / Coming of Age"
  },
  {
    "Code": "FIC050000",
    "Literal": "FICTION / Crime"
  },
  {
    "Code": "FIC051000",
    "Literal": "FICTION / Cultural Heritage"
  },
  {
    "Code": "FIC079000",
    "Literal": "FICTION / Disabilities"
  },
  {
    "Code": "FIC070000",
    "Literal": "FICTION / Disaster"
  },
  {
    "Code": "FIC130000",
    "Literal": "FICTION / Diversity & Multicultural"
  },
  {
    "Code": "FIC055000",
    "Literal": "FICTION / Dystopian"
  },
  {
    "Code": "FIC065000",
    "Literal": "FICTION / Epistolary"
  },
  {
    "Code": "FIC005000",
    "Literal": "FICTION / Erotica / General"
  },
  {
    "Code": "FIC005010",
    "Literal": "FICTION / Erotica / BDSM"
  },
  {
    "Code": "FIC005020",
    "Literal": "FICTION / Erotica / Collections & Anthologies"
  },
  {
    "Code": "FIC005060",
    "Literal": "FICTION / Erotica / Historical"
  },
  {
    "Code": "FIC005070",
    "Literal": "FICTION / Erotica / LGBTQ+ / General"
  },
  {
    "Code": "FIC005080",
    "Literal": "FICTION / Erotica / LGBTQ+ / Bisexual"
  },
  {
    "Code": "FIC005030",
    "Literal": "FICTION / Erotica / LGBTQ+ / Gay"
  },
  {
    "Code": "FIC005040",
    "Literal": "FICTION / Erotica / LGBTQ+ / Lesbian"
  },
  {
    "Code": "FIC005090",
    "Literal": "FICTION / Erotica / LGBTQ+ / Transgender"
  },
  {
    "Code": "FIC005100",
    "Literal": "FICTION / Erotica / LGBTQ+ / Two-Spirited & Indigiqueer"
  },
  {
    "Code": "FIC005050",
    "Literal": "FICTION / Erotica / Science Fiction, Fantasy & Horror"
  },
  {
    "Code": "FIC010000",
    "Literal": "FICTION / Fairy Tales, Folk Tales, Legends & Mythology"
  },
  {
    "Code": "FIC045000",
    "Literal": "FICTION / Family Life / General"
  },
  {
    "Code": "FIC045010",
    "Literal": "FICTION / Family Life / Marriage & Divorce"
  },
  {
    "Code": "FIC045020",
    "Literal": "FICTION / Family Life / Siblings"
  },
  {
    "Code": "FIC009000",
    "Literal": "FICTION / Fantasy / General"
  },
  {
    "Code": "FIC009100",
    "Literal": "FICTION / Fantasy / Action & Adventure"
  },
  {
    "Code": "FIC009110",
    "Literal": "FICTION / Fantasy / Arthurian"
  },
  {
    "Code": "FIC009040",
    "Literal": "FICTION / Fantasy / Collections & Anthologies"
  },
  {
    "Code": "FIC009010",
    "Literal": "FICTION / Fantasy / Contemporary"
  },
  {
    "Code": "FIC009070",
    "Literal": "FICTION / Fantasy / Dark Fantasy"
  },
  {
    "Code": "FIC009120",
    "Literal": "FICTION / Fantasy / Dragons & Mythical Creatures"
  },
  {
    "Code": "FIC009020",
    "Literal": "FICTION / Fantasy / Epic"
  },
  {
    "Code": "FIC009130",
    "Literal": "FICTION / Fantasy / Gaslamp"
  },
  {
    "Code": "FIC009030",
    "Literal": "FICTION / Fantasy / Historical"
  },
  {
    "Code": "FIC009080",
    "Literal": "FICTION / Fantasy / Humorous"
  },
  {
    "Code": "FIC009150",
    "Literal": "FICTION / Fantasy / Indigenous"
  },
  {
    "Code": "FIC009140",
    "Literal": "FICTION / Fantasy / Military"
  },
  {
    "Code": "FIC009050",
    "Literal": "FICTION / Fantasy / Paranormal"
  },
  {
    "Code": "FIC009090",
    "Literal": "FICTION / Fantasy / Romance"
  },
  {
    "Code": "FIC009060",
    "Literal": "FICTION / Fantasy / Urban"
  },
  {
    "Code": "FIC076000",
    "Literal": "FICTION / Feminist"
  },
  {
    "Code": "FIC071000",
    "Literal": "FICTION / Friendship"
  },
  {
    "Code": "FIC012000",
    "Literal": "FICTION / Ghost"
  },
  {
    "Code": "FIC027040",
    "Literal": "FICTION / Gothic"
  },
  {
    "Code": "FIC056000",
    "Literal": "FICTION / Hispanic & Latino / General"
  },
  {
    "Code": "FIC056010",
    "Literal": "FICTION / Hispanic & Latino / Christian"
  },
  {
    "Code": "FIC056020",
    "Literal": "FICTION / Hispanic & Latino / Erotica"
  },
  {
    "Code": "FIC056030",
    "Literal": "FICTION / Hispanic & Latino / Family Life"
  },
  {
    "Code": "FIC056040",
    "Literal": "FICTION / Hispanic & Latino / Fantasy"
  },
  {
    "Code": "FIC056050",
    "Literal": "FICTION / Hispanic & Latino / Historical"
  },
  {
    "Code": "FIC056060",
    "Literal": "FICTION / Hispanic & Latino / Horror"
  },
  {
    "Code": "FIC056070",
    "Literal": "FICTION / Hispanic & Latino / Inspirational"
  },
  {
    "Code": "FIC056080",
    "Literal": "FICTION / Hispanic & Latino / Mystery & Detective"
  },
  {
    "Code": "FIC056090",
    "Literal": "FICTION / Hispanic & Latino / Science Fiction"
  },
  {
    "Code": "FIC056100",
    "Literal": "FICTION / Hispanic & Latino / Urban & Street Lit"
  },
  {
    "Code": "FIC056110",
    "Literal": "FICTION / Hispanic & Latino / Women"
  },
  {
    "Code": "FIC014000",
    "Literal": "FICTION / Historical / General"
  },
  {
    "Code": "FIC014010",
    "Literal": "FICTION / Historical / Ancient"
  },
  {
    "Code": "FIC014060",
    "Literal": "FICTION / Historical / Civil War Era"
  },
  {
    "Code": "FIC014070",
    "Literal": "FICTION / Historical / Colonial America & Revolution"
  },
  {
    "Code": "FIC014020",
    "Literal": "FICTION / Historical / Medieval"
  },
  {
    "Code": "FIC014030",
    "Literal": "FICTION / Historical / Renaissance"
  },
  {
    "Code": "FIC014080",
    "Literal": "FICTION / Historical / 20th Century / General"
  },
  {
    "Code": "FIC014040",
    "Literal": "FICTION / Historical / 20th Century / World War I"
  },
  {
    "Code": "FIC014050",
    "Literal": "FICTION / Historical / 20th Century / World War II & Holocaust"
  },
  {
    "Code": "FIC014090",
    "Literal": "FICTION / Historical / 20th Century / Post-World War II"
  },
  {
    "Code": "FIC058000",
    "Literal": "FICTION / Holidays"
  },
  {
    "Code": "FIC015000",
    "Literal": "FICTION / Horror"
  },
  {
    "Code": "FIC016000",
    "Literal": "FICTION / Humorous / General"
  },
  {
    "Code": "FIC060000",
    "Literal": "FICTION / Humorous / Dark Humor"
  },
  {
    "Code": "FIC134000",
    "Literal": "FICTION / Immigration"
  },
  {
    "Code": "FIC059000",
    "Literal": "FICTION / Indigenous / General (see also Indigenous Peoples of Turtle Island or Native American)"
  },
  {
    "Code": "FIC059010",
    "Literal": "FICTION / Indigenous / City Life"
  },
  {
    "Code": "FIC059020",
    "Literal": "FICTION / Indigenous / Elders"
  },
  {
    "Code": "FIC059030",
    "Literal": "FICTION / Indigenous / Erotica"
  },
  {
    "Code": "FIC059040",
    "Literal": "FICTION / Indigenous / Family Life"
  },
  {
    "Code": "FIC059050",
    "Literal": "FICTION / Indigenous / Historical"
  },
  {
    "Code": "FIC059060",
    "Literal": "FICTION / Indigenous / Horror"
  },
  {
    "Code": "FIC059070",
    "Literal": "FICTION / Indigenous / Indigenous Futurism"
  },
  {
    "Code": "FIC059080",
    "Literal": "FICTION / Indigenous / Life Stories"
  },
  {
    "Code": "FIC059090",
    "Literal": "FICTION / Indigenous / Mystery & Detective"
  },
  {
    "Code": "FIC059100",
    "Literal": "FICTION / Indigenous / Oral Storytelling & Teachings"
  },
  {
    "Code": "FIC059110",
    "Literal": "FICTION / Indigenous / Science Fiction"
  },
  {
    "Code": "FIC059120",
    "Literal": "FICTION / Indigenous / Women"
  },
  {
    "Code": "FIC135000",
    "Literal": "FICTION / Indigenous Peoples of Turtle Island"
  },
  {
    "Code": "FIC046000",
    "Literal": "FICTION / Jewish"
  },
  {
    "Code": "FIC034000",
    "Literal": "FICTION / Legal"
  },
  {
    "Code": "FIC068000",
    "Literal": "FICTION / LGBTQ+ / General"
  },
  {
    "Code": "FIC072000",
    "Literal": "FICTION / LGBTQ+ / Bisexual"
  },
  {
    "Code": "FIC011000",
    "Literal": "FICTION / LGBTQ+ / Gay"
  },
  {
    "Code": "FIC018000",
    "Literal": "FICTION / LGBTQ+ / Lesbian"
  },
  {
    "Code": "FIC073000",
    "Literal": "FICTION / LGBTQ+ / Transgender"
  },
  {
    "Code": "FIC136000",
    "Literal": "FICTION / LGBTQ+ / Two-Spirited & Indigiqueer"
  },
  {
    "Code": "FIC019000",
    "Literal": "FICTION / Literary"
  },
  {
    "Code": "FIC129000",
    "Literal": "FICTION / LitRPG (Literary Role-Playing Game)"
  },
  {
    "Code": "FIC061000",
    "Literal": "FICTION / Magical Realism"
  },
  {
    "Code": "FIC057000",
    "Literal": "FICTION / Mashups"
  },
  {
    "Code": "FIC021000",
    "Literal": "FICTION / Media Tie-In"
  },
  {
    "Code": "FIC035000",
    "Literal": "FICTION / Medical"
  },
  {
    "Code": "FIC131000",
    "Literal": "FICTION / Middle Eastern & Arab American"
  },
  {
    "Code": "FIC080000",
    "Literal": "FICTION / Multiple Timelines"
  },
  {
    "Code": "FIC081000",
    "Literal": "FICTION / Muslim"
  },
  {
    "Code": "FIC022000",
    "Literal": "FICTION / Mystery & Detective / General"
  },
  {
    "Code": "FIC022100",
    "Literal": "FICTION / Mystery & Detective / Amateur Sleuth"
  },
  {
    "Code": "FIC022050",
    "Literal": "FICTION / Mystery & Detective / Collections & Anthologies"
  },
  {
    "Code": "FIC022070",
    "Literal": "FICTION / Mystery & Detective / Cozy / General"
  },
  {
    "Code": "FIC022110",
    "Literal": "FICTION / Mystery & Detective / Cozy / Animals"
  },
  {
    "Code": "FIC022170",
    "Literal": "FICTION / Mystery & Detective / Cozy / Books, Bookstores & Libraries"
  },
  {
    "Code": "FIC022120",
    "Literal": "FICTION / Mystery & Detective / Cozy / Crafts"
  },
  {
    "Code": "FIC022130",
    "Literal": "FICTION / Mystery & Detective / Cozy / Culinary"
  },
  {
    "Code": "FIC022140",
    "Literal": "FICTION / Mystery & Detective / Cozy / Holidays & Vacation"
  },
  {
    "Code": "FIC022150",
    "Literal": "FICTION / Mystery & Detective / Cozy / Paranormal"
  },
  {
    "Code": "FIC022010",
    "Literal": "FICTION / Mystery & Detective / Hard-Boiled"
  },
  {
    "Code": "FIC022060",
    "Literal": "FICTION / Mystery & Detective / Historical"
  },
  {
    "Code": "FIC022080",
    "Literal": "FICTION / Mystery & Detective / International Crime & Mystery"
  },
  {
    "Code": "FIC022160",
    "Literal": "FICTION / Mystery & Detective / Jewish"
  },
  {
    "Code": "FIC022020",
    "Literal": "FICTION / Mystery & Detective / Police Procedural"
  },
  {
    "Code": "FIC022090",
    "Literal": "FICTION / Mystery & Detective / Private Investigators"
  },
  {
    "Code": "FIC022030",
    "Literal": "FICTION / Mystery & Detective / Traditional"
  },
  {
    "Code": "FIC022040",
    "Literal": "FICTION / Mystery & Detective / Women Sleuths"
  },
  {
    "Code": "FIC137000",
    "Literal": "FICTION / Native American"
  },
  {
    "Code": "FIC077000",
    "Literal": "FICTION / Nature & the Environment"
  },
  {
    "Code": "FIC132000",
    "Literal": "FICTION / Neurodiversity"
  },
  {
    "Code": "FIC062000",
    "Literal": "FICTION / Noir"
  },
  {
    "Code": "FIC024000",
    "Literal": "FICTION / Occult & Supernatural"
  },
  {
    "Code": "FIC082000",
    "Literal": "FICTION / Own Voices"
  },
  {
    "Code": "FIC133000",
    "Literal": "FICTION / Performing Arts / General"
  },
  {
    "Code": "FIC133010",
    "Literal": "FICTION / Performing Arts / Dance, Theater & Musicals"
  },
  {
    "Code": "FIC133020",
    "Literal": "FICTION / Performing Arts / Film, Television & Radio"
  },
  {
    "Code": "FIC133030",
    "Literal": "FICTION / Performing Arts / Music"
  },
  {
    "Code": "FIC037000",
    "Literal": "FICTION / Political"
  },
  {
    "Code": "FIC025000",
    "Literal": "FICTION / Psychological"
  },
  {
    "Code": "FIC026000",
    "Literal": "FICTION / Religious"
  },
  {
    "Code": "FIC027000",
    "Literal": "FICTION / Romance / General"
  },
  {
    "Code": "FIC027260",
    "Literal": "FICTION / Romance / Action & Adventure"
  },
  {
    "Code": "FIC049060",
    "Literal": "FICTION / Romance / African American & Black"
  },
  {
    "Code": "FIC027340",
    "Literal": "FICTION / Romance / Billionaires"
  },
  {
    "Code": "FIC027270",
    "Literal": "FICTION / Romance / Clean & Wholesome"
  },
  {
    "Code": "FIC027080",
    "Literal": "FICTION / Romance / Collections & Anthologies"
  },
  {
    "Code": "FIC027020",
    "Literal": "FICTION / Romance / Contemporary"
  },
  {
    "Code": "FIC027010",
    "Literal": "FICTION / Romance / Erotic"
  },
  {
    "Code": "FIC027030",
    "Literal": "FICTION / Romance / Fantasy"
  },
  {
    "Code": "FIC027350",
    "Literal": "FICTION / Romance / Firefighters"
  },
  {
    "Code": "FIC027500",
    "Literal": "FICTION / Romance / Hispanic & Latino"
  },
  {
    "Code": "FIC027050",
    "Literal": "FICTION / Romance / Historical / General"
  },
  {
    "Code": "FIC027360",
    "Literal": "FICTION / Romance / Historical / American"
  },
  {
    "Code": "FIC027140",
    "Literal": "FICTION / Romance / Historical / Ancient World"
  },
  {
    "Code": "FIC027460",
    "Literal": "FICTION / Romance / Historical / Gilded Age"
  },
  {
    "Code": "FIC027150",
    "Literal": "FICTION / Romance / Historical / Medieval"
  },
  {
    "Code": "FIC027070",
    "Literal": "FICTION / Romance / Historical / Regency"
  },
  {
    "Code": "FIC027370",
    "Literal": "FICTION / Romance / Historical / Renaissance"
  },
  {
    "Code": "FIC027160",
    "Literal": "FICTION / Romance / Historical / Scottish"
  },
  {
    "Code": "FIC027280",
    "Literal": "FICTION / Romance / Historical / Tudor"
  },
  {
    "Code": "FIC027200",
    "Literal": "FICTION / Romance / Historical / 20th Century"
  },
  {
    "Code": "FIC027170",
    "Literal": "FICTION / Romance / Historical / Victorian"
  },
  {
    "Code": "FIC027180",
    "Literal": "FICTION / Romance / Historical / Viking"
  },
  {
    "Code": "FIC027290",
    "Literal": "FICTION / Romance / Holiday"
  },
  {
    "Code": "FIC027510",
    "Literal": "FICTION / Romance / Indigenous"
  },
  {
    "Code": "FIC027490",
    "Literal": "FICTION / Romance / International"
  },
  {
    "Code": "FIC027380",
    "Literal": "FICTION / Romance / Later in Life"
  },
  {
    "Code": "FIC027300",
    "Literal": "FICTION / Romance / LGBTQ+ / General"
  },
  {
    "Code": "FIC027390",
    "Literal": "FICTION / Romance / LGBTQ+ / Bisexual"
  },
  {
    "Code": "FIC027190",
    "Literal": "FICTION / Romance / LGBTQ+ / Gay"
  },
  {
    "Code": "FIC027210",
    "Literal": "FICTION / Romance / LGBTQ+ / Lesbian"
  },
  {
    "Code": "FIC027400",
    "Literal": "FICTION / Romance / LGBTQ+ / Transgender"
  },
  {
    "Code": "FIC027520",
    "Literal": "FICTION / Romance / LGBTQ+ / Two-Spirited & Indigiqueer"
  },
  {
    "Code": "FIC027410",
    "Literal": "FICTION / Romance / Medical"
  },
  {
    "Code": "FIC027220",
    "Literal": "FICTION / Romance / Military"
  },
  {
    "Code": "FIC027230",
    "Literal": "FICTION / Romance / Multicultural & Interracial"
  },
  {
    "Code": "FIC027240",
    "Literal": "FICTION / Romance / New Adult"
  },
  {
    "Code": "FIC027120",
    "Literal": "FICTION / Romance / Paranormal / General"
  },
  {
    "Code": "FIC027310",
    "Literal": "FICTION / Romance / Paranormal / Shifters"
  },
  {
    "Code": "FIC027320",
    "Literal": "FICTION / Romance / Paranormal / Vampires"
  },
  {
    "Code": "FIC027440",
    "Literal": "FICTION / Romance / Paranormal / Witches"
  },
  {
    "Code": "FIC027420",
    "Literal": "FICTION / Romance / Police & Law Enforcement"
  },
  {
    "Code": "FIC027470",
    "Literal": "FICTION / Romance / Polyamory"
  },
  {
    "Code": "FIC027480",
    "Literal": "FICTION / Romance / Rock Stars"
  },
  {
    "Code": "FIC027250",
    "Literal": "FICTION / Romance / Romantic Comedy"
  },
  {
    "Code": "FIC027450",
    "Literal": "FICTION / Romance / Royalty"
  },
  {
    "Code": "FIC027130",
    "Literal": "FICTION / Romance / Science Fiction"
  },
  {
    "Code": "FIC027330",
    "Literal": "FICTION / Romance / Sports"
  },
  {
    "Code": "FIC027110",
    "Literal": "FICTION / Romance / Suspense"
  },
  {
    "Code": "FIC027090",
    "Literal": "FICTION / Romance / Time Travel"
  },
  {
    "Code": "FIC027100",
    "Literal": "FICTION / Romance / Western"
  },
  {
    "Code": "FIC027430",
    "Literal": "FICTION / Romance / Workplace"
  },
  {
    "Code": "FIC008000",
    "Literal": "FICTION / Sagas"
  },
  {
    "Code": "FIC052000",
    "Literal": "FICTION / Satire"
  },
  {
    "Code": "FIC028000",
    "Literal": "FICTION / Science Fiction / General"
  },
  {
    "Code": "FIC028010",
    "Literal": "FICTION / Science Fiction / Action & Adventure"
  },
  {
    "Code": "FIC028090",
    "Literal": "FICTION / Science Fiction / Alien Contact"
  },
  {
    "Code": "FIC028070",
    "Literal": "FICTION / Science Fiction / Apocalyptic & Post-Apocalyptic"
  },
  {
    "Code": "FIC028040",
    "Literal": "FICTION / Science Fiction / Collections & Anthologies"
  },
  {
    "Code": "FIC028140",
    "Literal": "FICTION / Science Fiction / Crime & Mystery"
  },
  {
    "Code": "FIC028100",
    "Literal": "FICTION / Science Fiction / Cyberpunk"
  },
  {
    "Code": "FIC028110",
    "Literal": "FICTION / Science Fiction / Genetic Engineering"
  },
  {
    "Code": "FIC028020",
    "Literal": "FICTION / Science Fiction / Hard Science Fiction"
  },
  {
    "Code": "FIC028120",
    "Literal": "FICTION / Science Fiction / Humorous"
  },
  {
    "Code": "FIC028050",
    "Literal": "FICTION / Science Fiction / Military"
  },
  {
    "Code": "FIC028130",
    "Literal": "FICTION / Science Fiction / Space Exploration"
  },
  {
    "Code": "FIC028030",
    "Literal": "FICTION / Science Fiction / Space Opera"
  },
  {
    "Code": "FIC028060",
    "Literal": "FICTION / Science Fiction / Steampunk"
  },
  {
    "Code": "FIC028080",
    "Literal": "FICTION / Science Fiction / Time Travel"
  },
  {
    "Code": "FIC047000",
    "Literal": "FICTION / Sea Stories"
  },
  {
    "Code": "FIC029000",
    "Literal": "FICTION / Short Stories (single author)"
  },
  {
    "Code": "FIC066000",
    "Literal": "FICTION / Small Town & Rural"
  },
  {
    "Code": "FIC074000",
    "Literal": "FICTION / Southern"
  },
  {
    "Code": "FIC038000",
    "Literal": "FICTION / Sports"
  },
  {
    "Code": "FIC138000",
    "Literal": "FICTION / Suburban"
  },
  {
    "Code": "FIC063000",
    "Literal": "FICTION / Superheroes (see also COMICS & GRAPHIC NOVELS / Superheroes)"
  },
  {
    "Code": "FIC031000",
    "Literal": "FICTION / Thrillers / General"
  },
  {
    "Code": "FIC031010",
    "Literal": "FICTION / Thrillers / Crime"
  },
  {
    "Code": "FIC031100",
    "Literal": "FICTION / Thrillers / Domestic"
  },
  {
    "Code": "FIC006000",
    "Literal": "FICTION / Thrillers / Espionage"
  },
  {
    "Code": "FIC031020",
    "Literal": "FICTION / Thrillers / Historical"
  },
  {
    "Code": "FIC031030",
    "Literal": "FICTION / Thrillers / Legal"
  },
  {
    "Code": "FIC031040",
    "Literal": "FICTION / Thrillers / Medical"
  },
  {
    "Code": "FIC031050",
    "Literal": "FICTION / Thrillers / Military"
  },
  {
    "Code": "FIC031060",
    "Literal": "FICTION / Thrillers / Political"
  },
  {
    "Code": "FIC031080",
    "Literal": "FICTION / Thrillers / Psychological"
  },
  {
    "Code": "FIC031070",
    "Literal": "FICTION / Thrillers / Supernatural"
  },
  {
    "Code": "FIC030000",
    "Literal": "FICTION / Thrillers / Suspense"
  },
  {
    "Code": "FIC036000",
    "Literal": "FICTION / Thrillers / Technological"
  },
  {
    "Code": "FIC031090",
    "Literal": "FICTION / Thrillers / Terrorism"
  },
  {
    "Code": "FIC048000",
    "Literal": "FICTION / Urban & Street Lit"
  },
  {
    "Code": "FIC039000",
    "Literal": "FICTION / Visionary & Metaphysical"
  },
  {
    "Code": "FIC032000",
    "Literal": "FICTION / War & Military"
  },
  {
    "Code": "FIC033000",
    "Literal": "FICTION / Westerns"
  },
  {
    "Code": "FIC044000",
    "Literal": "FICTION / Women"
  },
  {
    "Code": "FIC083000",
    "Literal": "FICTION / World Literature / Africa / General"
  },
  {
    "Code": "FIC083010",
    "Literal": "FICTION / World Literature / Africa / East Africa"
  },
  {
    "Code": "FIC083020",
    "Literal": "FICTION / World Literature / Africa / Nigeria"
  },
  {
    "Code": "FIC083030",
    "Literal": "FICTION / World Literature / Africa / Southern Africa"
  },
  {
    "Code": "FIC083040",
    "Literal": "FICTION / World Literature / Africa / West Africa"
  },
  {
    "Code": "FIC084000",
    "Literal": "FICTION / World Literature / American / General"
  },
  {
    "Code": "FIC084010",
    "Literal": "FICTION / World Literature / American / Colonial & Revolutionary Periods"
  },
  {
    "Code": "FIC084020",
    "Literal": "FICTION / World Literature / American / 19th Century"
  },
  {
    "Code": "FIC084030",
    "Literal": "FICTION / World Literature / American / 20th Century"
  },
  {
    "Code": "FIC084040",
    "Literal": "FICTION / World Literature / American / 21st Century"
  },
  {
    "Code": "FIC085000",
    "Literal": "FICTION / World Literature / Argentina"
  },
  {
    "Code": "FIC086000",
    "Literal": "FICTION / World Literature / Asia (General)"
  },
  {
    "Code": "FIC087000",
    "Literal": "FICTION / World Literature / Australia"
  },
  {
    "Code": "FIC088000",
    "Literal": "FICTION / World Literature / Austria"
  },
  {
    "Code": "FIC089000",
    "Literal": "FICTION / World Literature / Brazil"
  },
  {
    "Code": "FIC090000",
    "Literal": "FICTION / World Literature / Canada / General"
  },
  {
    "Code": "FIC090010",
    "Literal": "FICTION / World Literature / Canada / Colonial & 19th Century"
  },
  {
    "Code": "FIC090020",
    "Literal": "FICTION / World Literature / Canada / 20th Century"
  },
  {
    "Code": "FIC090030",
    "Literal": "FICTION / World Literature / Canada / 21st Century"
  },
  {
    "Code": "FIC091000",
    "Literal": "FICTION / World Literature / Caribbean & West Indies"
  },
  {
    "Code": "FIC092000",
    "Literal": "FICTION / World Literature / Central America"
  },
  {
    "Code": "FIC139000",
    "Literal": "FICTION / World Literature / Central Asia"
  },
  {
    "Code": "FIC093000",
    "Literal": "FICTION / World Literature / Chile"
  },
  {
    "Code": "FIC094000",
    "Literal": "FICTION / World Literature / China / General"
  },
  {
    "Code": "FIC094010",
    "Literal": "FICTION / World Literature / China / 19th Century"
  },
  {
    "Code": "FIC094020",
    "Literal": "FICTION / World Literature / China / 20th Century"
  },
  {
    "Code": "FIC094030",
    "Literal": "FICTION / World Literature / China / 21st Century"
  },
  {
    "Code": "FIC095000",
    "Literal": "FICTION / World Literature / Colombia"
  },
  {
    "Code": "FIC096000",
    "Literal": "FICTION / World Literature / Czech Republic"
  },
  {
    "Code": "FIC097000",
    "Literal": "FICTION / World Literature / Denmark"
  },
  {
    "Code": "FIC098000",
    "Literal": "FICTION / World Literature / England / General"
  },
  {
    "Code": "FIC098010",
    "Literal": "FICTION / World Literature / England / Early & Medieval Periods"
  },
  {
    "Code": "FIC098020",
    "Literal": "FICTION / World Literature / England / 16th & 17th Century"
  },
  {
    "Code": "FIC098030",
    "Literal": "FICTION / World Literature / England / 18th Century"
  },
  {
    "Code": "FIC098040",
    "Literal": "FICTION / World Literature / England / 19th Century"
  },
  {
    "Code": "FIC098050",
    "Literal": "FICTION / World Literature / England / 20th Century"
  },
  {
    "Code": "FIC098060",
    "Literal": "FICTION / World Literature / England / 21st Century"
  },
  {
    "Code": "FIC099000",
    "Literal": "FICTION / World Literature / Europe (General)"
  },
  {
    "Code": "FIC100000",
    "Literal": "FICTION / World Literature / Finland"
  },
  {
    "Code": "FIC101000",
    "Literal": "FICTION / World Literature / France / General"
  },
  {
    "Code": "FIC101010",
    "Literal": "FICTION / World Literature / France / 18th Century"
  },
  {
    "Code": "FIC101020",
    "Literal": "FICTION / World Literature / France / 19th Century"
  },
  {
    "Code": "FIC101030",
    "Literal": "FICTION / World Literature / France / 20th Century"
  },
  {
    "Code": "FIC101040",
    "Literal": "FICTION / World Literature / France / 21st Century"
  },
  {
    "Code": "FIC102000",
    "Literal": "FICTION / World Literature / Germany / General"
  },
  {
    "Code": "FIC102010",
    "Literal": "FICTION / World Literature / Germany / 20th Century"
  },
  {
    "Code": "FIC102020",
    "Literal": "FICTION / World Literature / Germany / 21st Century"
  },
  {
    "Code": "FIC103000",
    "Literal": "FICTION / World Literature / Greece"
  },
  {
    "Code": "FIC104000",
    "Literal": "FICTION / World Literature / Hungary"
  },
  {
    "Code": "FIC105000",
    "Literal": "FICTION / World Literature / India / General"
  },
  {
    "Code": "FIC105010",
    "Literal": "FICTION / World Literature / India / 19th Century"
  },
  {
    "Code": "FIC105020",
    "Literal": "FICTION / World Literature / India / 20th Century"
  },
  {
    "Code": "FIC105030",
    "Literal": "FICTION / World Literature / India / 21st Century"
  },
  {
    "Code": "FIC106000",
    "Literal": "FICTION / World Literature / Ireland / General"
  },
  {
    "Code": "FIC106010",
    "Literal": "FICTION / World Literature / Ireland / 19th Century"
  },
  {
    "Code": "FIC106020",
    "Literal": "FICTION / World Literature / Ireland / 20th Century"
  },
  {
    "Code": "FIC106030",
    "Literal": "FICTION / World Literature / Ireland / 21st Century"
  },
  {
    "Code": "FIC107000",
    "Literal": "FICTION / World Literature / Italy"
  },
  {
    "Code": "FIC108000",
    "Literal": "FICTION / World Literature / Japan"
  },
  {
    "Code": "FIC109000",
    "Literal": "FICTION / World Literature / Korea"
  },
  {
    "Code": "FIC110000",
    "Literal": "FICTION / World Literature / Mexico"
  },
  {
    "Code": "FIC111000",
    "Literal": "FICTION / World Literature / Middle East / General"
  },
  {
    "Code": "FIC111010",
    "Literal": "FICTION / World Literature / Middle East / Arabian Peninsula"
  },
  {
    "Code": "FIC111020",
    "Literal": "FICTION / World Literature / Middle East / Egypt & North Africa"
  },
  {
    "Code": "FIC111030",
    "Literal": "FICTION / World Literature / Middle East / Israel"
  },
  {
    "Code": "FIC112000",
    "Literal": "FICTION / World Literature / Netherlands"
  },
  {
    "Code": "FIC113000",
    "Literal": "FICTION / World Literature / New Zealand"
  },
  {
    "Code": "FIC114000",
    "Literal": "FICTION / World Literature / Norway"
  },
  {
    "Code": "FIC115000",
    "Literal": "FICTION / World Literature / Oceania"
  },
  {
    "Code": "FIC116000",
    "Literal": "FICTION / World Literature / Pakistan"
  },
  {
    "Code": "FIC117000",
    "Literal": "FICTION / World Literature / Peru"
  },
  {
    "Code": "FIC118000",
    "Literal": "FICTION / World Literature / Poland"
  },
  {
    "Code": "FIC119000",
    "Literal": "FICTION / World Literature / Portugal"
  },
  {
    "Code": "FIC120000",
    "Literal": "FICTION / World Literature / Russia / General"
  },
  {
    "Code": "FIC120010",
    "Literal": "FICTION / World Literature / Russia / 19th Century"
  },
  {
    "Code": "FIC120020",
    "Literal": "FICTION / World Literature / Russia / 20th Century"
  },
  {
    "Code": "FIC120030",
    "Literal": "FICTION / World Literature / Russia / 21st Century"
  },
  {
    "Code": "FIC121000",
    "Literal": "FICTION / World Literature / Scotland / General"
  },
  {
    "Code": "FIC121010",
    "Literal": "FICTION / World Literature / Scotland / 19th Century"
  },
  {
    "Code": "FIC121020",
    "Literal": "FICTION / World Literature / Scotland / 20th Century"
  },
  {
    "Code": "FIC121030",
    "Literal": "FICTION / World Literature / Scotland / 21st Century"
  },
  {
    "Code": "FIC122000",
    "Literal": "FICTION / World Literature / South America (General)"
  },
  {
    "Code": "FIC123000",
    "Literal": "FICTION / World Literature / Southeast Asia"
  },
  {
    "Code": "FIC124000",
    "Literal": "FICTION / World Literature / Spain / General"
  },
  {
    "Code": "FIC124010",
    "Literal": "FICTION / World Literature / Spain / 19th Century"
  },
  {
    "Code": "FIC124020",
    "Literal": "FICTION / World Literature / Spain / 20th Century"
  },
  {
    "Code": "FIC124030",
    "Literal": "FICTION / World Literature / Spain / 21st Century"
  },
  {
    "Code": "FIC125000",
    "Literal": "FICTION / World Literature / Sweden"
  },
  {
    "Code": "FIC126000",
    "Literal": "FICTION / World Literature / Turkey"
  },
  {
    "Code": "FIC127000",
    "Literal": "FICTION / World Literature / Uruguay"
  },
  {
    "Code": "FIC128000",
    "Literal": "FICTION / World Literature / Wales"
  },
  {
    "Code": "FOR000000",
    "Literal": "FOREIGN LANGUAGE STUDY / General"
  },
  {
    "Code": "FOR001000",
    "Literal": "FOREIGN LANGUAGE STUDY / African Languages (see also Swahili)"
  },
  {
    "Code": "FOR033000",
    "Literal": "FOREIGN LANGUAGE STUDY / Ancient Languages (see also Latin)"
  },
  {
    "Code": "FOR002000",
    "Literal": "FOREIGN LANGUAGE STUDY / Arabic"
  },
  {
    "Code": "FOR034000",
    "Literal": "FOREIGN LANGUAGE STUDY / Baltic Languages"
  },
  {
    "Code": "FOR029000",
    "Literal": "FOREIGN LANGUAGE STUDY / Celtic Languages"
  },
  {
    "Code": "FOR003000",
    "Literal": "FOREIGN LANGUAGE STUDY / Chinese"
  },
  {
    "Code": "FOR035000",
    "Literal": "FOREIGN LANGUAGE STUDY / Creole Languages"
  },
  {
    "Code": "FOR036000",
    "Literal": "FOREIGN LANGUAGE STUDY / Czech"
  },
  {
    "Code": "FOR004000",
    "Literal": "FOREIGN LANGUAGE STUDY / Danish"
  },
  {
    "Code": "FOR006000",
    "Literal": "FOREIGN LANGUAGE STUDY / Dutch"
  },
  {
    "Code": "FOR007000",
    "Literal": "FOREIGN LANGUAGE STUDY / English as a Second Language"
  },
  {
    "Code": "FOR037000",
    "Literal": "FOREIGN LANGUAGE STUDY / Finnish"
  },
  {
    "Code": "FOR008000",
    "Literal": "FOREIGN LANGUAGE STUDY / French"
  },
  {
    "Code": "FOR009000",
    "Literal": "FOREIGN LANGUAGE STUDY / German"
  },
  {
    "Code": "FOR010000",
    "Literal": "FOREIGN LANGUAGE STUDY / Greek (Modern)"
  },
  {
    "Code": "FOR011000",
    "Literal": "FOREIGN LANGUAGE STUDY / Hebrew"
  },
  {
    "Code": "FOR038000",
    "Literal": "FOREIGN LANGUAGE STUDY / Hindi"
  },
  {
    "Code": "FOR012000",
    "Literal": "FOREIGN LANGUAGE STUDY / Hungarian"
  },
  {
    "Code": "FOR030000",
    "Literal": "FOREIGN LANGUAGE STUDY / Indic Languages"
  },
  {
    "Code": "FOR031000",
    "Literal": "FOREIGN LANGUAGE STUDY / Indigenous Languages in the Americas"
  },
  {
    "Code": "FOR013000",
    "Literal": "FOREIGN LANGUAGE STUDY / Italian"
  },
  {
    "Code": "FOR014000",
    "Literal": "FOREIGN LANGUAGE STUDY / Japanese"
  },
  {
    "Code": "FOR015000",
    "Literal": "FOREIGN LANGUAGE STUDY / Korean"
  },
  {
    "Code": "FOR016000",
    "Literal": "FOREIGN LANGUAGE STUDY / Latin"
  },
  {
    "Code": "FOR017000",
    "Literal": "FOREIGN LANGUAGE STUDY / Miscellaneous"
  },
  {
    "Code": "FOR005000",
    "Literal": "FOREIGN LANGUAGE STUDY / Multi-Language Dictionaries"
  },
  {
    "Code": "FOR018000",
    "Literal": "FOREIGN LANGUAGE STUDY / Multi-Language Phrasebooks"
  },
  {
    "Code": "FOR039000",
    "Literal": "FOREIGN LANGUAGE STUDY / Norwegian"
  },
  {
    "Code": "FOR032000",
    "Literal": "FOREIGN LANGUAGE STUDY / Oceanic & Australian Languages"
  },
  {
    "Code": "FOR045000",
    "Literal": "FOREIGN LANGUAGE STUDY / Old & Middle English"
  },
  {
    "Code": "FOR040000",
    "Literal": "FOREIGN LANGUAGE STUDY / Persian"
  },
  {
    "Code": "FOR019000",
    "Literal": "FOREIGN LANGUAGE STUDY / Polish"
  },
  {
    "Code": "FOR020000",
    "Literal": "FOREIGN LANGUAGE STUDY / Portuguese"
  },
  {
    "Code": "FOR041000",
    "Literal": "FOREIGN LANGUAGE STUDY / Romance Languages (Other)"
  },
  {
    "Code": "FOR021000",
    "Literal": "FOREIGN LANGUAGE STUDY / Russian"
  },
  {
    "Code": "FOR022000",
    "Literal": "FOREIGN LANGUAGE STUDY / Scandinavian Languages (Other)"
  },
  {
    "Code": "FOR023000",
    "Literal": "FOREIGN LANGUAGE STUDY / Serbian & Croatian"
  },
  {
    "Code": "FOR024000",
    "Literal": "FOREIGN LANGUAGE STUDY / Slavic Languages (Other)"
  },
  {
    "Code": "FOR025000",
    "Literal": "FOREIGN LANGUAGE STUDY / Southeast Asian Languages (see also Vietnamese)"
  },
  {
    "Code": "FOR026000",
    "Literal": "FOREIGN LANGUAGE STUDY / Spanish"
  },
  {
    "Code": "FOR042000",
    "Literal": "FOREIGN LANGUAGE STUDY / Swahili"
  },
  {
    "Code": "FOR043000",
    "Literal": "FOREIGN LANGUAGE STUDY / Swedish"
  },
  {
    "Code": "FOR027000",
    "Literal": "FOREIGN LANGUAGE STUDY / Turkish & Turkic Languages"
  },
  {
    "Code": "FOR044000",
    "Literal": "FOREIGN LANGUAGE STUDY / Vietnamese"
  },
  {
    "Code": "FOR028000",
    "Literal": "FOREIGN LANGUAGE STUDY / Yiddish"
  },
  {
    "Code": "HIS000000",
    "Literal": "HISTORY / General"
  },
  {
    "Code": "HIS001000",
    "Literal": "HISTORY / Africa / General"
  },
  {
    "Code": "HIS001010",
    "Literal": "HISTORY / Africa / Central"
  },
  {
    "Code": "HIS001020",
    "Literal": "HISTORY / Africa / East"
  },
  {
    "Code": "HIS001030",
    "Literal": "HISTORY / Africa / North"
  },
  {
    "Code": "HIS001040",
    "Literal": "HISTORY / Africa / South / General"
  },
  {
    "Code": "HIS047000",
    "Literal": "HISTORY / Africa / South / Republic of South Africa"
  },
  {
    "Code": "HIS001050",
    "Literal": "HISTORY / Africa / West"
  },
  {
    "Code": "HIS056000",
    "Literal": "HISTORY / African American & Black"
  },
  {
    "Code": "HIS038000",
    "Literal": "HISTORY / Americas (North, Central, South, West Indies)"
  },
  {
    "Code": "HIS002000",
    "Literal": "HISTORY / Ancient / General"
  },
  {
    "Code": "HIS002030",
    "Literal": "HISTORY / Ancient / Egypt"
  },
  {
    "Code": "HIS002010",
    "Literal": "HISTORY / Ancient / Greece"
  },
  {
    "Code": "HIS002020",
    "Literal": "HISTORY / Ancient / Rome"
  },
  {
    "Code": "HIS003000",
    "Literal": "HISTORY / Asia / General"
  },
  {
    "Code": "HIS050000",
    "Literal": "HISTORY / Asia / Central Asia"
  },
  {
    "Code": "HIS008000",
    "Literal": "HISTORY / Asia / China"
  },
  {
    "Code": "HIS021000",
    "Literal": "HISTORY / Asia / Japan"
  },
  {
    "Code": "HIS023000",
    "Literal": "HISTORY / Asia / Korea"
  },
  {
    "Code": "HIS017000",
    "Literal": "HISTORY / Asia / South / General"
  },
  {
    "Code": "HIS062000",
    "Literal": "HISTORY / Asia / South / India"
  },
  {
    "Code": "HIS048000",
    "Literal": "HISTORY / Asia / Southeast Asia"
  },
  {
    "Code": "HIS004000",
    "Literal": "HISTORY / Australia & New Zealand"
  },
  {
    "Code": "HIS059000",
    "Literal": "HISTORY / Byzantine Empire"
  },
  {
    "Code": "HIS006000",
    "Literal": "HISTORY / Canada / General"
  },
  {
    "Code": "HIS006010",
    "Literal": "HISTORY / Canada / Pre-Confederation (to 1867)"
  },
  {
    "Code": "HIS006020",
    "Literal": "HISTORY / Canada / Post-Confederation (1867-)"
  },
  {
    "Code": "HIS006030",
    "Literal": "HISTORY / Canada / Provincial, Territorial & Local / General"
  },
  {
    "Code": "HIS006040",
    "Literal": "HISTORY / Canada / Provincial, Territorial & Local / Atlantic Provinces (NB, NL, NS, PE)"
  },
  {
    "Code": "HIS006050",
    "Literal": "HISTORY / Canada / Provincial, Territorial & Local / British Columbia (BC)"
  },
  {
    "Code": "HIS006060",
    "Literal": "HISTORY / Canada / Provincial, Territorial & Local / Northern Territories (NT, NU, YT)"
  },
  {
    "Code": "HIS006070",
    "Literal": "HISTORY / Canada / Provincial, Territorial & Local / Ontario (ON)"
  },
  {
    "Code": "HIS006080",
    "Literal": "HISTORY / Canada / Provincial, Territorial & Local / Prairie Provinces (AB, MB, SK)"
  },
  {
    "Code": "HIS006090",
    "Literal": "HISTORY / Canada / Provincial, Territorial & Local / Quebec (QC)"
  },
  {
    "Code": "HIS041000",
    "Literal": "HISTORY / Caribbean & West Indies / General"
  },
  {
    "Code": "HIS041010",
    "Literal": "HISTORY / Caribbean & West Indies / Cuba"
  },
  {
    "Code": "HIS041020",
    "Literal": "HISTORY / Caribbean & West Indies / Dominican Republic"
  },
  {
    "Code": "HIS041030",
    "Literal": "HISTORY / Caribbean & West Indies / Haiti"
  },
  {
    "Code": "HIS041040",
    "Literal": "HISTORY / Caribbean & West Indies / Jamaica"
  },
  {
    "Code": "HIS039000",
    "Literal": "HISTORY / Civilization"
  },
  {
    "Code": "HIS049000",
    "Literal": "HISTORY / Essays"
  },
  {
    "Code": "HIS010000",
    "Literal": "HISTORY / Europe / General"
  },
  {
    "Code": "HIS037010",
    "Literal": "HISTORY / Europe / Medieval"
  },
  {
    "Code": "HIS037020",
    "Literal": "HISTORY / Europe / Renaissance"
  },
  {
    "Code": "HIS040000",
    "Literal": "HISTORY / Europe / Austria & Hungary"
  },
  {
    "Code": "HIS005000",
    "Literal": "HISTORY / Europe / Baltic States"
  },
  {
    "Code": "HIS063000",
    "Literal": "HISTORY / Europe / Benelux Countries (Belgium, Netherlands, Luxembourg)"
  },
  {
    "Code": "HIS010010",
    "Literal": "HISTORY / Europe / Eastern"
  },
  {
    "Code": "HIS013000",
    "Literal": "HISTORY / Europe / France"
  },
  {
    "Code": "HIS014000",
    "Literal": "HISTORY / Europe / Germany"
  },
  {
    "Code": "HIS015000",
    "Literal": "HISTORY / Europe / Great Britain / General"
  },
  {
    "Code": "HIS015010",
    "Literal": "HISTORY / Europe / Great Britain / Middle Ages (449-1066)"
  },
  {
    "Code": "HIS015020",
    "Literal": "HISTORY / Europe / Great Britain / Norman Conquest to Late Medieval (1066-1485)"
  },
  {
    "Code": "HIS015030",
    "Literal": "HISTORY / Europe / Great Britain / Tudor & Elizabethan Era (1485-1603)"
  },
  {
    "Code": "HIS015040",
    "Literal": "HISTORY / Europe / Great Britain / Stuart Era (1603-1714)"
  },
  {
    "Code": "HIS015050",
    "Literal": "HISTORY / Europe / Great Britain / Georgian Era (1714-1837)"
  },
  {
    "Code": "HIS015060",
    "Literal": "HISTORY / Europe / Great Britain / Victorian Era (1837-1901)"
  },
  {
    "Code": "HIS015070",
    "Literal": "HISTORY / Europe / Great Britain / 20th Century"
  },
  {
    "Code": "HIS015080",
    "Literal": "HISTORY / Europe / Great Britain / 21st Century"
  },
  {
    "Code": "HIS015090",
    "Literal": "HISTORY / Europe / Great Britain / Scotland"
  },
  {
    "Code": "HIS015100",
    "Literal": "HISTORY / Europe / Great Britain / Wales"
  },
  {
    "Code": "HIS042000",
    "Literal": "HISTORY / Europe / Greece (see also Ancient / Greece)"
  },
  {
    "Code": "HIS018000",
    "Literal": "HISTORY / Europe / Ireland"
  },
  {
    "Code": "HIS020000",
    "Literal": "HISTORY / Europe / Italy"
  },
  {
    "Code": "HIS044000",
    "Literal": "HISTORY / Europe / Nordic Countries"
  },
  {
    "Code": "HIS060000",
    "Literal": "HISTORY / Europe / Poland"
  },
  {
    "Code": "HIS064000",
    "Literal": "HISTORY / Europe / Portugal"
  },
  {
    "Code": "HIS045000",
    "Literal": "HISTORY / Europe / Spain"
  },
  {
    "Code": "HIS067000",
    "Literal": "HISTORY / Europe / Ukraine"
  },
  {
    "Code": "HIS010020",
    "Literal": "HISTORY / Europe / Western"
  },
  {
    "Code": "HIS051000",
    "Literal": "HISTORY / Expeditions & Discoveries"
  },
  {
    "Code": "HIS068000",
    "Literal": "HISTORY / Hispanic & Latino"
  },
  {
    "Code": "HIS052000",
    "Literal": "HISTORY / Historical Geography"
  },
  {
    "Code": "HIS016000",
    "Literal": "HISTORY / Historiography"
  },
  {
    "Code": "HIS028000",
    "Literal": "HISTORY / Indigenous / General"
  },
  {
    "Code": "HIS028010",
    "Literal": "HISTORY / Indigenous / Creation & Origins"
  },
  {
    "Code": "HIS028020",
    "Literal": "HISTORY / Indigenous / Migration"
  },
  {
    "Code": "HIS028030",
    "Literal": "HISTORY / Indigenous / Archaeological Stages & Interpretations of Oral History"
  },
  {
    "Code": "HIS028040",
    "Literal": "HISTORY / Indigenous / Contact, European Invasion & Exploration"
  },
  {
    "Code": "HIS028050",
    "Literal": "HISTORY / Indigenous / Colonial History & Interaction with Nations, Tribes, Bands & Communities"
  },
  {
    "Code": "HIS028060",
    "Literal": "HISTORY / Indigenous / Modern"
  },
  {
    "Code": "HIS069000",
    "Literal": "HISTORY / Indigenous Peoples of Turtle Island"
  },
  {
    "Code": "HIS065000",
    "Literal": "HISTORY / Islamic"
  },
  {
    "Code": "HIS022000",
    "Literal": "HISTORY / Jewish"
  },
  {
    "Code": "HIS024000",
    "Literal": "HISTORY / Latin America / General"
  },
  {
    "Code": "HIS061000",
    "Literal": "HISTORY / Latin America / Pre-Columbian Era"
  },
  {
    "Code": "HIS007000",
    "Literal": "HISTORY / Latin America / Central America"
  },
  {
    "Code": "HIS025000",
    "Literal": "HISTORY / Latin America / Mexico"
  },
  {
    "Code": "HIS033000",
    "Literal": "HISTORY / Latin America / South America"
  },
  {
    "Code": "HIS066000",
    "Literal": "HISTORY / LGBTQ+"
  },
  {
    "Code": "HIS057000",
    "Literal": "HISTORY / Maritime History & Piracy"
  },
  {
    "Code": "HIS026000",
    "Literal": "HISTORY / Middle East / General"
  },
  {
    "Code": "HIS026010",
    "Literal": "HISTORY / Middle East / Arabian Peninsula"
  },
  {
    "Code": "HIS009000",
    "Literal": "HISTORY / Middle East / Egypt (see also Ancient / Egypt)"
  },
  {
    "Code": "HIS026020",
    "Literal": "HISTORY / Middle East / Iran"
  },
  {
    "Code": "HIS026030",
    "Literal": "HISTORY / Middle East / Iraq"
  },
  {
    "Code": "HIS019000",
    "Literal": "HISTORY / Middle East / Israel & Palestine"
  },
  {
    "Code": "HIS026040",
    "Literal": "HISTORY / Middle East / Syria"
  },
  {
    "Code": "HIS055000",
    "Literal": "HISTORY / Middle East / Turkey & Ottoman Empire"
  },
  {
    "Code": "HIS027000",
    "Literal": "HISTORY / Military / General"
  },
  {
    "Code": "HIS027220",
    "Literal": "HISTORY / Military / Ancient"
  },
  {
    "Code": "HIS027140",
    "Literal": "HISTORY / Military / Aviation & Space"
  },
  {
    "Code": "HIS027010",
    "Literal": "HISTORY / Military / Biological & Chemical Warfare"
  },
  {
    "Code": "HIS027160",
    "Literal": "HISTORY / Military / Canada"
  },
  {
    "Code": "HIS027250",
    "Literal": "HISTORY / Military / Civil Wars (see also United States / Civil War Period (1850-1877))"
  },
  {
    "Code": "HIS027260",
    "Literal": "HISTORY / Military / Early Modern Warfare (1500-1800)"
  },
  {
    "Code": "HIS027270",
    "Literal": "HISTORY / Military / Fortifications"
  },
  {
    "Code": "HIS027280",
    "Literal": "HISTORY / Military / Guerrilla Warfare"
  },
  {
    "Code": "HIS027290",
    "Literal": "HISTORY / Military / Intelligence & Espionage"
  },
  {
    "Code": "HIS027300",
    "Literal": "HISTORY / Military / Land Forces"
  },
  {
    "Code": "HIS027230",
    "Literal": "HISTORY / Military / Medieval"
  },
  {
    "Code": "HIS027150",
    "Literal": "HISTORY / Military / Naval"
  },
  {
    "Code": "HIS027030",
    "Literal": "HISTORY / Military / Nuclear Warfare"
  },
  {
    "Code": "HIS027050",
    "Literal": "HISTORY / Military / Pictorial"
  },
  {
    "Code": "HIS027180",
    "Literal": "HISTORY / Military / Special Forces"
  },
  {
    "Code": "HIS027060",
    "Literal": "HISTORY / Military / Strategy"
  },
  {
    "Code": "HIS027310",
    "Literal": "HISTORY / Military / Uniforms"
  },
  {
    "Code": "HIS027110",
    "Literal": "HISTORY / Military / United States"
  },
  {
    "Code": "HIS027240",
    "Literal": "HISTORY / Military / Vehicles / General"
  },
  {
    "Code": "HIS027320",
    "Literal": "HISTORY / Military / Vehicles / Air"
  },
  {
    "Code": "HIS027330",
    "Literal": "HISTORY / Military / Vehicles / Land"
  },
  {
    "Code": "HIS027340",
    "Literal": "HISTORY / Military / Vehicles / Sea (see also TRANSPORTATION / Ships & Shipbuilding / Submarines)"
  },
  {
    "Code": "HIS027120",
    "Literal": "HISTORY / Military / Veterans"
  },
  {
    "Code": "HIS027080",
    "Literal": "HISTORY / Military / Weapons"
  },
  {
    "Code": "HIS037030",
    "Literal": "HISTORY / Modern / General"
  },
  {
    "Code": "HIS037090",
    "Literal": "HISTORY / Modern / 16th Century"
  },
  {
    "Code": "HIS037040",
    "Literal": "HISTORY / Modern / 17th Century"
  },
  {
    "Code": "HIS037050",
    "Literal": "HISTORY / Modern / 18th Century"
  },
  {
    "Code": "HIS037060",
    "Literal": "HISTORY / Modern / 19th Century"
  },
  {
    "Code": "HIS037070",
    "Literal": "HISTORY / Modern / 20th Century / General"
  },
  {
    "Code": "HIS037100",
    "Literal": "HISTORY / Modern / 20th Century / Cold War"
  },
  {
    "Code": "HIS043000",
    "Literal": "HISTORY / Modern / 20th Century / Holocaust"
  },
  {
    "Code": "HIS037080",
    "Literal": "HISTORY / Modern / 21st Century"
  },
  {
    "Code": "HIS070000",
    "Literal": "HISTORY / Native American"
  },
  {
    "Code": "HIS029000",
    "Literal": "HISTORY / North America"
  },
  {
    "Code": "HIS053000",
    "Literal": "HISTORY / Oceania"
  },
  {
    "Code": "HIS046000",
    "Literal": "HISTORY / Polar Regions"
  },
  {
    "Code": "HIS030000",
    "Literal": "HISTORY / Reference"
  },
  {
    "Code": "HIS031000",
    "Literal": "HISTORY / Revolutions, Uprisings & Rebellions"
  },
  {
    "Code": "HIS032000",
    "Literal": "HISTORY / Russia / General"
  },
  {
    "Code": "HIS032010",
    "Literal": "HISTORY / Russia / Imperial"
  },
  {
    "Code": "HIS032020",
    "Literal": "HISTORY / Russia / Soviet Era"
  },
  {
    "Code": "HIS032030",
    "Literal": "HISTORY / Russia / Post-Soviet"
  },
  {
    "Code": "HIS054000",
    "Literal": "HISTORY / Social History"
  },
  {
    "Code": "HIS035000",
    "Literal": "HISTORY / Study & Teaching"
  },
  {
    "Code": "HIS036000",
    "Literal": "HISTORY / United States / General"
  },
  {
    "Code": "HIS036020",
    "Literal": "HISTORY / United States / Colonial Period (1600-1775)"
  },
  {
    "Code": "HIS036030",
    "Literal": "HISTORY / United States / Revolutionary Period (1775-1800)"
  },
  {
    "Code": "HIS036040",
    "Literal": "HISTORY / United States / 19th Century"
  },
  {
    "Code": "HIS036050",
    "Literal": "HISTORY / United States / Civil War Period (1850-1877)"
  },
  {
    "Code": "HIS036060",
    "Literal": "HISTORY / United States / 20th Century"
  },
  {
    "Code": "HIS036070",
    "Literal": "HISTORY / United States / 21st Century"
  },
  {
    "Code": "HIS036010",
    "Literal": "HISTORY / United States / State & Local / General"
  },
  {
    "Code": "HIS036080",
    "Literal": "HISTORY / United States / State & Local / Middle Atlantic (DC, DE, MD, NJ, NY, PA)"
  },
  {
    "Code": "HIS036090",
    "Literal": "HISTORY / United States / State & Local / Midwest (IA, IL, IN, KS, MI, MN, MO, ND, NE, OH, SD, WI)"
  },
  {
    "Code": "HIS036100",
    "Literal": "HISTORY / United States / State & Local / New England (CT, MA, ME, NH, RI, VT)"
  },
  {
    "Code": "HIS036110",
    "Literal": "HISTORY / United States / State & Local / Pacific Northwest (OR, WA)"
  },
  {
    "Code": "HIS036120",
    "Literal": "HISTORY / United States / State & Local / South (AL, AR, FL, GA, KY, LA, MS, NC, SC, TN, VA, WV)"
  },
  {
    "Code": "HIS036130",
    "Literal": "HISTORY / United States / State & Local / Southwest (AZ, NM, OK, TX)"
  },
  {
    "Code": "HIS036140",
    "Literal": "HISTORY / United States / State & Local / West (AK, CA, CO, HI, ID, MT, NV, UT, WY)"
  },
  {
    "Code": "HIS027130",
    "Literal": "HISTORY / Wars & Conflicts / General"
  },
  {
    "Code": "HIS027350",
    "Literal": "HISTORY / Wars & Conflicts / Crusades"
  },
  {
    "Code": "HIS027200",
    "Literal": "HISTORY / Wars & Conflicts / Napoleonic Wars"
  },
  {
    "Code": "HIS027210",
    "Literal": "HISTORY / Wars & Conflicts / War of 1812"
  },
  {
    "Code": "HIS027090",
    "Literal": "HISTORY / Wars & Conflicts / World War I"
  },
  {
    "Code": "HIS027100",
    "Literal": "HISTORY / Wars & Conflicts / World War II / General"
  },
  {
    "Code": "HIS027360",
    "Literal": "HISTORY / Wars & Conflicts / World War II / Eastern Front"
  },
  {
    "Code": "HIS027370",
    "Literal": "HISTORY / Wars & Conflicts / World War II / European Theater"
  },
  {
    "Code": "HIS027380",
    "Literal": "HISTORY / Wars & Conflicts / World War II / Mediterranean Theater"
  },
  {
    "Code": "HIS027390",
    "Literal": "HISTORY / Wars & Conflicts / World War II / Pacific Theater"
  },
  {
    "Code": "HIS027020",
    "Literal": "HISTORY / Wars & Conflicts / Korean War"
  },
  {
    "Code": "HIS027070",
    "Literal": "HISTORY / Wars & Conflicts / Vietnam War"
  },
  {
    "Code": "HIS027040",
    "Literal": "HISTORY / Wars & Conflicts / Persian Gulf War (1991)"
  },
  {
    "Code": "HIS027190",
    "Literal": "HISTORY / Wars & Conflicts / Afghan War (2001-2021)"
  },
  {
    "Code": "HIS027170",
    "Literal": "HISTORY / Wars & Conflicts / Iraq War (2003-2011)"
  },
  {
    "Code": "HIS058000",
    "Literal": "HISTORY / Women"
  },
  {
    "Code": "HIS037000",
    "Literal": "HISTORY / World"
  },
  {
    "Code": "HUM000000",
    "Literal": "HUMOR / General"
  },
  {
    "Code": "HUM015000",
    "Literal": "HUMOR / Form / Anecdotes & Quotations"
  },
  {
    "Code": "HUM001000",
    "Literal": "HUMOR / Form / Comic Strips & Cartoons"
  },
  {
    "Code": "HUM003000",
    "Literal": "HUMOR / Form / Essays"
  },
  {
    "Code": "HUM004000",
    "Literal": "HUMOR / Form / Jokes & Riddles"
  },
  {
    "Code": "HUM005000",
    "Literal": "HUMOR / Form / Limericks & Verse"
  },
  {
    "Code": "HUM007000",
    "Literal": "HUMOR / Form / Parodies"
  },
  {
    "Code": "HUM017000",
    "Literal": "HUMOR / Form / Pictorial"
  },
  {
    "Code": "HUM018000",
    "Literal": "HUMOR / Form / Puns & Wordplay"
  },
  {
    "Code": "HUM016000",
    "Literal": "HUMOR / Form / Trivia"
  },
  {
    "Code": "HUM008000",
    "Literal": "HUMOR / Topic / Adult"
  },
  {
    "Code": "HUM009000",
    "Literal": "HUMOR / Topic / Animals"
  },
  {
    "Code": "HUM010000",
    "Literal": "HUMOR / Topic / Business & Professional"
  },
  {
    "Code": "HUM020000",
    "Literal": "HUMOR / Topic / Celebrity & Popular Culture"
  },
  {
    "Code": "HUM027000",
    "Literal": "HUMOR / Topic / Health & Aging"
  },
  {
    "Code": "HUM022000",
    "Literal": "HUMOR / Topic / History"
  },
  {
    "Code": "HUM023000",
    "Literal": "HUMOR / Topic / Internet & Social Media"
  },
  {
    "Code": "HUM019000",
    "Literal": "HUMOR / Topic / Language"
  },
  {
    "Code": "HUM024000",
    "Literal": "HUMOR / Topic / LGBTQ+"
  },
  {
    "Code": "HUM011000",
    "Literal": "HUMOR / Topic / Marriage & Family"
  },
  {
    "Code": "HUM012000",
    "Literal": "HUMOR / Topic / Men, Women & Relationships"
  },
  {
    "Code": "HUM006000",
    "Literal": "HUMOR / Topic / Politics"
  },
  {
    "Code": "HUM021000",
    "Literal": "HUMOR / Topic / Regional & Cultural"
  },
  {
    "Code": "HUM014000",
    "Literal": "HUMOR / Topic / Religion"
  },
  {
    "Code": "HUM025000",
    "Literal": "HUMOR / Topic / School & Education"
  },
  {
    "Code": "HUM013000",
    "Literal": "HUMOR / Topic / Sports"
  },
  {
    "Code": "HUM026000",
    "Literal": "HUMOR / Topic / Travel"
  },
  {
    "Code": "JUV000000",
    "Literal": "JUVENILE FICTION / General"
  },
  {
    "Code": "JUV001000",
    "Literal": "JUVENILE FICTION / Action & Adventure / General"
  },
  {
    "Code": "JUV001020",
    "Literal": "JUVENILE FICTION / Action & Adventure / Pirates"
  },
  {
    "Code": "JUV001010",
    "Literal": "JUVENILE FICTION / Action & Adventure / Survival Stories"
  },
  {
    "Code": "JUV054000",
    "Literal": "JUVENILE FICTION / Activity Books / General"
  },
  {
    "Code": "JUV054010",
    "Literal": "JUVENILE FICTION / Activity Books / Coloring"
  },
  {
    "Code": "JUV054020",
    "Literal": "JUVENILE FICTION / Activity Books / Sticker"
  },
  {
    "Code": "JUV011010",
    "Literal": "JUVENILE FICTION / African American & Black"
  },
  {
    "Code": "JUV002000",
    "Literal": "JUVENILE FICTION / Animals / General"
  },
  {
    "Code": "JUV002010",
    "Literal": "JUVENILE FICTION / Animals / Alligators & Crocodiles"
  },
  {
    "Code": "JUV002020",
    "Literal": "JUVENILE FICTION / Animals / Apes, Monkeys, etc."
  },
  {
    "Code": "JUV002370",
    "Literal": "JUVENILE FICTION / Animals / Baby Animals"
  },
  {
    "Code": "JUV002030",
    "Literal": "JUVENILE FICTION / Animals / Bears"
  },
  {
    "Code": "JUV002040",
    "Literal": "JUVENILE FICTION / Animals / Birds"
  },
  {
    "Code": "JUV002300",
    "Literal": "JUVENILE FICTION / Animals / Butterflies, Moths & Caterpillars"
  },
  {
    "Code": "JUV002050",
    "Literal": "JUVENILE FICTION / Animals / Cats"
  },
  {
    "Code": "JUV002310",
    "Literal": "JUVENILE FICTION / Animals / Cows"
  },
  {
    "Code": "JUV002290",
    "Literal": "JUVENILE FICTION / Animals / Deer, Moose & Caribou"
  },
  {
    "Code": "JUV002060",
    "Literal": "JUVENILE FICTION / Animals / Dinosaurs & Prehistoric Creatures"
  },
  {
    "Code": "JUV002070",
    "Literal": "JUVENILE FICTION / Animals / Dogs"
  },
  {
    "Code": "JUV002270",
    "Literal": "JUVENILE FICTION / Animals / Dragons, Unicorns & Mythical"
  },
  {
    "Code": "JUV002280",
    "Literal": "JUVENILE FICTION / Animals / Ducks, Geese, etc."
  },
  {
    "Code": "JUV002080",
    "Literal": "JUVENILE FICTION / Animals / Elephants"
  },
  {
    "Code": "JUV002090",
    "Literal": "JUVENILE FICTION / Animals / Farm Animals"
  },
  {
    "Code": "JUV002100",
    "Literal": "JUVENILE FICTION / Animals / Fish"
  },
  {
    "Code": "JUV002110",
    "Literal": "JUVENILE FICTION / Animals / Foxes"
  },
  {
    "Code": "JUV002120",
    "Literal": "JUVENILE FICTION / Animals / Frogs & Toads"
  },
  {
    "Code": "JUV002320",
    "Literal": "JUVENILE FICTION / Animals / Giraffes"
  },
  {
    "Code": "JUV002330",
    "Literal": "JUVENILE FICTION / Animals / Hippos & Rhinos"
  },
  {
    "Code": "JUV002130",
    "Literal": "JUVENILE FICTION / Animals / Horses"
  },
  {
    "Code": "JUV002140",
    "Literal": "JUVENILE FICTION / Animals / Insects, Spiders, etc."
  },
  {
    "Code": "JUV002340",
    "Literal": "JUVENILE FICTION / Animals / Jungle Animals"
  },
  {
    "Code": "JUV002350",
    "Literal": "JUVENILE FICTION / Animals / Kangaroos"
  },
  {
    "Code": "JUV002150",
    "Literal": "JUVENILE FICTION / Animals / Lions, Tigers, Leopards, etc."
  },
  {
    "Code": "JUV002160",
    "Literal": "JUVENILE FICTION / Animals / Mammals"
  },
  {
    "Code": "JUV002170",
    "Literal": "JUVENILE FICTION / Animals / Marine Life"
  },
  {
    "Code": "JUV002180",
    "Literal": "JUVENILE FICTION / Animals / Mice, Hamsters, Guinea Pigs, etc."
  },
  {
    "Code": "JUV002360",
    "Literal": "JUVENILE FICTION / Animals / Nocturnal"
  },
  {
    "Code": "JUV002380",
    "Literal": "JUVENILE FICTION / Animals / Penguins"
  },
  {
    "Code": "JUV002190",
    "Literal": "JUVENILE FICTION / Animals / Pets"
  },
  {
    "Code": "JUV002200",
    "Literal": "JUVENILE FICTION / Animals / Pigs"
  },
  {
    "Code": "JUV002210",
    "Literal": "JUVENILE FICTION / Animals / Rabbits"
  },
  {
    "Code": "JUV002220",
    "Literal": "JUVENILE FICTION / Animals / Reptiles & Amphibians"
  },
  {
    "Code": "JUV002230",
    "Literal": "JUVENILE FICTION / Animals / Squirrels"
  },
  {
    "Code": "JUV002240",
    "Literal": "JUVENILE FICTION / Animals / Turtles & Tortoises"
  },
  {
    "Code": "JUV002250",
    "Literal": "JUVENILE FICTION / Animals / Wolves, Coyotes & Wild Dogs"
  },
  {
    "Code": "JUV002390",
    "Literal": "JUVENILE FICTION / Animals / Worms"
  },
  {
    "Code": "JUV002260",
    "Literal": "JUVENILE FICTION / Animals / Zoos"
  },
  {
    "Code": "JUV073000",
    "Literal": "JUVENILE FICTION / Architecture"
  },
  {
    "Code": "JUV003000",
    "Literal": "JUVENILE FICTION / Art"
  },
  {
    "Code": "JUV011020",
    "Literal": "JUVENILE FICTION / Asian American & Pacific Islander"
  },
  {
    "Code": "JUV010000",
    "Literal": "JUVENILE FICTION / Bedtime & Dreams"
  },
  {
    "Code": "JUV004000",
    "Literal": "JUVENILE FICTION / Biographical / General"
  },
  {
    "Code": "JUV004050",
    "Literal": "JUVENILE FICTION / Biographical / Africa"
  },
  {
    "Code": "JUV004060",
    "Literal": "JUVENILE FICTION / Biographical / Asia"
  },
  {
    "Code": "JUV004080",
    "Literal": "JUVENILE FICTION / Biographical / Australia & Oceania"
  },
  {
    "Code": "JUV004040",
    "Literal": "JUVENILE FICTION / Biographical / Canada"
  },
  {
    "Code": "JUV004010",
    "Literal": "JUVENILE FICTION / Biographical / Europe"
  },
  {
    "Code": "JUV004070",
    "Literal": "JUVENILE FICTION / Biographical / Latin America"
  },
  {
    "Code": "JUV004020",
    "Literal": "JUVENILE FICTION / Biographical / United States"
  },
  {
    "Code": "JUV081000",
    "Literal": "JUVENILE FICTION / Biracial & Multiracial"
  },
  {
    "Code": "JUV047000",
    "Literal": "JUVENILE FICTION / Books & Libraries"
  },
  {
    "Code": "JUV005000",
    "Literal": "JUVENILE FICTION / Boys & Men"
  },
  {
    "Code": "JUV006000",
    "Literal": "JUVENILE FICTION / Business, Careers, Occupations"
  },
  {
    "Code": "JUV007000",
    "Literal": "JUVENILE FICTION / Classics"
  },
  {
    "Code": "JUV048000",
    "Literal": "JUVENILE FICTION / Clothing & Dress"
  },
  {
    "Code": "JUV008000",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / General"
  },
  {
    "Code": "JUV008040",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Action & Adventure"
  },
  {
    "Code": "JUV008150",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / African American & Black"
  },
  {
    "Code": "JUV008050",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Animals"
  },
  {
    "Code": "JUV008190",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Asian American & Pacific Islander"
  },
  {
    "Code": "JUV008060",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Classic Adaptation"
  },
  {
    "Code": "JUV008070",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Fairy Tales, Folklore, Legends & Mythology"
  },
  {
    "Code": "JUV008080",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Fantasy"
  },
  {
    "Code": "JUV008200",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Hispanic & Latino"
  },
  {
    "Code": "JUV008090",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Historical"
  },
  {
    "Code": "JUV008100",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Horror"
  },
  {
    "Code": "JUV008110",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Humorous"
  },
  {
    "Code": "JUV008210",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Indigenous"
  },
  {
    "Code": "JUV008160",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Indigenous Peoples of Turtle Island"
  },
  {
    "Code": "JUV008180",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / LGBTQ+"
  },
  {
    "Code": "JUV008010",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Manga"
  },
  {
    "Code": "JUV008030",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Media Tie-In"
  },
  {
    "Code": "JUV008120",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Mystery & Detective"
  },
  {
    "Code": "JUV008170",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Native American"
  },
  {
    "Code": "JUV008130",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Paranormal"
  },
  {
    "Code": "JUV008140",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Science Fiction"
  },
  {
    "Code": "JUV008020",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Superheroes"
  },
  {
    "Code": "JUV049000",
    "Literal": "JUVENILE FICTION / Computers & Digital Media"
  },
  {
    "Code": "JUV009000",
    "Literal": "JUVENILE FICTION / Concepts / General"
  },
  {
    "Code": "JUV009010",
    "Literal": "JUVENILE FICTION / Concepts / Alphabet"
  },
  {
    "Code": "JUV009120",
    "Literal": "JUVENILE FICTION / Concepts / Body"
  },
  {
    "Code": "JUV009020",
    "Literal": "JUVENILE FICTION / Concepts / Colors"
  },
  {
    "Code": "JUV009030",
    "Literal": "JUVENILE FICTION / Concepts / Counting & Numbers"
  },
  {
    "Code": "JUV009070",
    "Literal": "JUVENILE FICTION / Concepts / Date & Time"
  },
  {
    "Code": "JUV009130",
    "Literal": "JUVENILE FICTION / Concepts / Language"
  },
  {
    "Code": "JUV009090",
    "Literal": "JUVENILE FICTION / Concepts / Money"
  },
  {
    "Code": "JUV009040",
    "Literal": "JUVENILE FICTION / Concepts / Opposites"
  },
  {
    "Code": "JUV009100",
    "Literal": "JUVENILE FICTION / Concepts / Seasons"
  },
  {
    "Code": "JUV009050",
    "Literal": "JUVENILE FICTION / Concepts / Senses & Sensation"
  },
  {
    "Code": "JUV009060",
    "Literal": "JUVENILE FICTION / Concepts / Size & Shape"
  },
  {
    "Code": "JUV009110",
    "Literal": "JUVENILE FICTION / Concepts / Sounds"
  },
  {
    "Code": "JUV009080",
    "Literal": "JUVENILE FICTION / Concepts / Words"
  },
  {
    "Code": "JUV050000",
    "Literal": "JUVENILE FICTION / Cooking & Food"
  },
  {
    "Code": "JUV039150",
    "Literal": "JUVENILE FICTION / Disabilities"
  },
  {
    "Code": "JUV074000",
    "Literal": "JUVENILE FICTION / Diversity & Multicultural"
  },
  {
    "Code": "JUV059000",
    "Literal": "JUVENILE FICTION / Dystopian"
  },
  {
    "Code": "JUV012030",
    "Literal": "JUVENILE FICTION / Fairy Tales & Folklore / General"
  },
  {
    "Code": "JUV012040",
    "Literal": "JUVENILE FICTION / Fairy Tales & Folklore / Adaptations"
  },
  {
    "Code": "JUV012000",
    "Literal": "JUVENILE FICTION / Fairy Tales & Folklore / Anthologies"
  },
  {
    "Code": "JUV012020",
    "Literal": "JUVENILE FICTION / Fairy Tales & Folklore / Country & Cultural"
  },
  {
    "Code": "JUV013000",
    "Literal": "JUVENILE FICTION / Family / General (see also headings under Social Themes)"
  },
  {
    "Code": "JUV013010",
    "Literal": "JUVENILE FICTION / Family / Adoption"
  },
  {
    "Code": "JUV013090",
    "Literal": "JUVENILE FICTION / Family / Alternative Family"
  },
  {
    "Code": "JUV013080",
    "Literal": "JUVENILE FICTION / Family / Blended Families"
  },
  {
    "Code": "JUV013100",
    "Literal": "JUVENILE FICTION / Family / Grandparents"
  },
  {
    "Code": "JUV013020",
    "Literal": "JUVENILE FICTION / Family / Marriage & Divorce"
  },
  {
    "Code": "JUV013030",
    "Literal": "JUVENILE FICTION / Family / Multigenerational"
  },
  {
    "Code": "JUV013040",
    "Literal": "JUVENILE FICTION / Family / New Baby"
  },
  {
    "Code": "JUV013050",
    "Literal": "JUVENILE FICTION / Family / Orphans & Foster Homes"
  },
  {
    "Code": "JUV013060",
    "Literal": "JUVENILE FICTION / Family / Parents"
  },
  {
    "Code": "JUV013070",
    "Literal": "JUVENILE FICTION / Family / Siblings"
  },
  {
    "Code": "JUV037000",
    "Literal": "JUVENILE FICTION / Fantasy & Magic"
  },
  {
    "Code": "JUV082000",
    "Literal": "JUVENILE FICTION / First Nations"
  },
  {
    "Code": "JUV069000",
    "Literal": "JUVENILE FICTION / Ghost Stories"
  },
  {
    "Code": "JUV014000",
    "Literal": "JUVENILE FICTION / Girls & Women"
  },
  {
    "Code": "JUV015000",
    "Literal": "JUVENILE FICTION / Health & Daily Living / General"
  },
  {
    "Code": "JUV015010",
    "Literal": "JUVENILE FICTION / Health & Daily Living / Daily Activities"
  },
  {
    "Code": "JUV015020",
    "Literal": "JUVENILE FICTION / Health & Daily Living / Diseases, Illnesses & Injuries"
  },
  {
    "Code": "JUV015030",
    "Literal": "JUVENILE FICTION / Health & Daily Living / Mental Health"
  },
  {
    "Code": "JUV015040",
    "Literal": "JUVENILE FICTION / Health & Daily Living / Mindfulness & Meditation"
  },
  {
    "Code": "JUV039170",
    "Literal": "JUVENILE FICTION / Health & Daily Living / Toilet Training"
  },
  {
    "Code": "JUV011030",
    "Literal": "JUVENILE FICTION / Hispanic & Latino"
  },
  {
    "Code": "JUV016000",
    "Literal": "JUVENILE FICTION / Historical / General"
  },
  {
    "Code": "JUV016010",
    "Literal": "JUVENILE FICTION / Historical / Africa"
  },
  {
    "Code": "JUV016020",
    "Literal": "JUVENILE FICTION / Historical / Ancient Civilizations"
  },
  {
    "Code": "JUV016030",
    "Literal": "JUVENILE FICTION / Historical / Asia"
  },
  {
    "Code": "JUV016220",
    "Literal": "JUVENILE FICTION / Historical / Australia & Oceania"
  },
  {
    "Code": "JUV016160",
    "Literal": "JUVENILE FICTION / Historical / Canada / General"
  },
  {
    "Code": "JUV016170",
    "Literal": "JUVENILE FICTION / Historical / Canada / Pre-Confederation (to 1867)"
  },
  {
    "Code": "JUV016180",
    "Literal": "JUVENILE FICTION / Historical / Canada / Post-Confederation (1867-)"
  },
  {
    "Code": "JUV016230",
    "Literal": "JUVENILE FICTION / Historical / Caribbean & Latin America"
  },
  {
    "Code": "JUV016040",
    "Literal": "JUVENILE FICTION / Historical / Europe"
  },
  {
    "Code": "JUV016050",
    "Literal": "JUVENILE FICTION / Historical / Exploration & Discovery"
  },
  {
    "Code": "JUV016060",
    "Literal": "JUVENILE FICTION / Historical / Holocaust"
  },
  {
    "Code": "JUV016070",
    "Literal": "JUVENILE FICTION / Historical / Medieval"
  },
  {
    "Code": "JUV016210",
    "Literal": "JUVENILE FICTION / Historical / Middle East"
  },
  {
    "Code": "JUV016080",
    "Literal": "JUVENILE FICTION / Historical / Military & Wars"
  },
  {
    "Code": "JUV016090",
    "Literal": "JUVENILE FICTION / Historical / Prehistory"
  },
  {
    "Code": "JUV016100",
    "Literal": "JUVENILE FICTION / Historical / Renaissance"
  },
  {
    "Code": "JUV016110",
    "Literal": "JUVENILE FICTION / Historical / United States / General"
  },
  {
    "Code": "JUV016120",
    "Literal": "JUVENILE FICTION / Historical / United States / Colonial & Revolutionary Periods"
  },
  {
    "Code": "JUV016140",
    "Literal": "JUVENILE FICTION / Historical / United States / 19th Century"
  },
  {
    "Code": "JUV016200",
    "Literal": "JUVENILE FICTION / Historical / United States / Civil War Period (1850-1877)"
  },
  {
    "Code": "JUV016150",
    "Literal": "JUVENILE FICTION / Historical / United States / 20th Century"
  },
  {
    "Code": "JUV016190",
    "Literal": "JUVENILE FICTION / Historical / United States / 21st Century"
  },
  {
    "Code": "JUV017000",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / General (see also Religious / Christian / Holidays & Celebrations)"
  },
  {
    "Code": "JUV017100",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Birthdays"
  },
  {
    "Code": "JUV017010",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Christmas & Advent"
  },
  {
    "Code": "JUV017160",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Day of the Dead"
  },
  {
    "Code": "JUV017170",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Diwali"
  },
  {
    "Code": "JUV017020",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Easter & Lent"
  },
  {
    "Code": "JUV017140",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Father's Day"
  },
  {
    "Code": "JUV017030",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Halloween"
  },
  {
    "Code": "JUV017110",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Hanukkah"
  },
  {
    "Code": "JUV017180",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Juneteenth"
  },
  {
    "Code": "JUV017050",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Kwanzaa"
  },
  {
    "Code": "JUV017190",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Lunar New Year"
  },
  {
    "Code": "JUV017150",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Mother's Day"
  },
  {
    "Code": "JUV017120",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Passover"
  },
  {
    "Code": "JUV017130",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Patriotic Holidays"
  },
  {
    "Code": "JUV017200",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Ramadan"
  },
  {
    "Code": "JUV017060",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Thanksgiving"
  },
  {
    "Code": "JUV017070",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Valentine's Day"
  },
  {
    "Code": "JUV017080",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Other, Non-Religious"
  },
  {
    "Code": "JUV017090",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Other, Religious"
  },
  {
    "Code": "JUV018000",
    "Literal": "JUVENILE FICTION / Horror"
  },
  {
    "Code": "JUV019000",
    "Literal": "JUVENILE FICTION / Humorous Stories"
  },
  {
    "Code": "JUV051000",
    "Literal": "JUVENILE FICTION / Imagination & Play"
  },
  {
    "Code": "JUV083000",
    "Literal": "JUVENILE FICTION / Indigenous / General"
  },
  {
    "Code": "JUV083010",
    "Literal": "JUVENILE FICTION / Indigenous / Animal Stories"
  },
  {
    "Code": "JUV083020",
    "Literal": "JUVENILE FICTION / Indigenous / Cautionary Tales"
  },
  {
    "Code": "JUV083030",
    "Literal": "JUVENILE FICTION / Indigenous / Elders"
  },
  {
    "Code": "JUV083040",
    "Literal": "JUVENILE FICTION / Indigenous / Family Life"
  },
  {
    "Code": "JUV083050",
    "Literal": "JUVENILE FICTION / Indigenous / Historical"
  },
  {
    "Code": "JUV083060",
    "Literal": "JUVENILE FICTION / Indigenous / Life Stories (see also headings under Biographical)"
  },
  {
    "Code": "JUV083070",
    "Literal": "JUVENILE FICTION / Indigenous / Oral Stories"
  },
  {
    "Code": "JUV083080",
    "Literal": "JUVENILE FICTION / Indigenous / Retellings"
  },
  {
    "Code": "JUV030140",
    "Literal": "JUVENILE FICTION / Indigenous / Teachings"
  },
  {
    "Code": "JUV030090",
    "Literal": "JUVENILE FICTION / Indigenous Peoples of Turtle Island"
  },
  {
    "Code": "JUV020000",
    "Literal": "JUVENILE FICTION / Interactive Adventures"
  },
  {
    "Code": "JUV084000",
    "Literal": "JUVENILE FICTION / Inuit"
  },
  {
    "Code": "JUV021000",
    "Literal": "JUVENILE FICTION / Law & Crime"
  },
  {
    "Code": "JUV022000",
    "Literal": "JUVENILE FICTION / Legends, Myths, Fables / General"
  },
  {
    "Code": "JUV012050",
    "Literal": "JUVENILE FICTION / Legends, Myths, Fables / African"
  },
  {
    "Code": "JUV022010",
    "Literal": "JUVENILE FICTION / Legends, Myths, Fables / Arthurian"
  },
  {
    "Code": "JUV012060",
    "Literal": "JUVENILE FICTION / Legends, Myths, Fables / Asian"
  },
  {
    "Code": "JUV012070",
    "Literal": "JUVENILE FICTION / Legends, Myths, Fables / Caribbean & Latin American"
  },
  {
    "Code": "JUV022020",
    "Literal": "JUVENILE FICTION / Legends, Myths, Fables / Greek & Roman"
  },
  {
    "Code": "JUV022030",
    "Literal": "JUVENILE FICTION / Legends, Myths, Fables / Norse"
  },
  {
    "Code": "JUV060000",
    "Literal": "JUVENILE FICTION / LGBTQ+"
  },
  {
    "Code": "JUV023000",
    "Literal": "JUVENILE FICTION / Lifestyles / City & Town Life"
  },
  {
    "Code": "JUV024000",
    "Literal": "JUVENILE FICTION / Lifestyles / Country Life"
  },
  {
    "Code": "JUV025000",
    "Literal": "JUVENILE FICTION / Lifestyles / Farm & Ranch Life"
  },
  {
    "Code": "JUV026000",
    "Literal": "JUVENILE FICTION / Love & Romance"
  },
  {
    "Code": "JUV072000",
    "Literal": "JUVENILE FICTION / Mathematics"
  },
  {
    "Code": "JUV027000",
    "Literal": "JUVENILE FICTION / Media Tie-In"
  },
  {
    "Code": "JUV066000",
    "Literal": "JUVENILE FICTION / Mermaids & Mermen"
  },
  {
    "Code": "JUV085000",
    "Literal": "JUVENILE FICTION / Métis"
  },
  {
    "Code": "JUV030130",
    "Literal": "JUVENILE FICTION / Middle Eastern & Arab American"
  },
  {
    "Code": "JUV052000",
    "Literal": "JUVENILE FICTION / Monsters"
  },
  {
    "Code": "JUV028000",
    "Literal": "JUVENILE FICTION / Mysteries & Detective Stories"
  },
  {
    "Code": "JUV011040",
    "Literal": "JUVENILE FICTION / Native American"
  },
  {
    "Code": "JUV077000",
    "Literal": "JUVENILE FICTION / Neurodiversity"
  },
  {
    "Code": "JUV055000",
    "Literal": "JUVENILE FICTION / Nursery Rhymes"
  },
  {
    "Code": "JUV058000",
    "Literal": "JUVENILE FICTION / Paranormal, Occult & Supernatural"
  },
  {
    "Code": "JUV031000",
    "Literal": "JUVENILE FICTION / Performing Arts / General"
  },
  {
    "Code": "JUV031010",
    "Literal": "JUVENILE FICTION / Performing Arts / Circus"
  },
  {
    "Code": "JUV031020",
    "Literal": "JUVENILE FICTION / Performing Arts / Dance"
  },
  {
    "Code": "JUV031030",
    "Literal": "JUVENILE FICTION / Performing Arts / Film"
  },
  {
    "Code": "JUV031040",
    "Literal": "JUVENILE FICTION / Performing Arts / Music"
  },
  {
    "Code": "JUV031050",
    "Literal": "JUVENILE FICTION / Performing Arts / Television & Radio"
  },
  {
    "Code": "JUV031060",
    "Literal": "JUVENILE FICTION / Performing Arts / Theater & Musicals"
  },
  {
    "Code": "JUV030000",
    "Literal": "JUVENILE FICTION / Places / General"
  },
  {
    "Code": "JUV030010",
    "Literal": "JUVENILE FICTION / Places / Africa"
  },
  {
    "Code": "JUV030020",
    "Literal": "JUVENILE FICTION / Places / Asia"
  },
  {
    "Code": "JUV030080",
    "Literal": "JUVENILE FICTION / Places / Australia & Oceania"
  },
  {
    "Code": "JUV030030",
    "Literal": "JUVENILE FICTION / Places / Canada"
  },
  {
    "Code": "JUV030040",
    "Literal": "JUVENILE FICTION / Places / Caribbean & Latin America"
  },
  {
    "Code": "JUV030050",
    "Literal": "JUVENILE FICTION / Places / Europe"
  },
  {
    "Code": "JUV030100",
    "Literal": "JUVENILE FICTION / Places / Mexico"
  },
  {
    "Code": "JUV030110",
    "Literal": "JUVENILE FICTION / Places / Middle East"
  },
  {
    "Code": "JUV030120",
    "Literal": "JUVENILE FICTION / Places / Polar Regions"
  },
  {
    "Code": "JUV030060",
    "Literal": "JUVENILE FICTION / Places / United States"
  },
  {
    "Code": "JUV070000",
    "Literal": "JUVENILE FICTION / Poetry (see also Stories in Verse)"
  },
  {
    "Code": "JUV061000",
    "Literal": "JUVENILE FICTION / Politics & Government"
  },
  {
    "Code": "JUV043000",
    "Literal": "JUVENILE FICTION / Readers / Beginner"
  },
  {
    "Code": "JUV044000",
    "Literal": "JUVENILE FICTION / Readers / Intermediate"
  },
  {
    "Code": "JUV045000",
    "Literal": "JUVENILE FICTION / Readers / Chapter Books"
  },
  {
    "Code": "JUV086000",
    "Literal": "JUVENILE FICTION / Readers / Hi-Lo"
  },
  {
    "Code": "JUV063000",
    "Literal": "JUVENILE FICTION / Recycling & Green Living"
  },
  {
    "Code": "JUV033000",
    "Literal": "JUVENILE FICTION / Religious / General"
  },
  {
    "Code": "JUV033250",
    "Literal": "JUVENILE FICTION / Religious / Buddhist"
  },
  {
    "Code": "JUV033010",
    "Literal": "JUVENILE FICTION / Religious / Christian / General"
  },
  {
    "Code": "JUV033040",
    "Literal": "JUVENILE FICTION / Religious / Christian / Action & Adventure"
  },
  {
    "Code": "JUV033050",
    "Literal": "JUVENILE FICTION / Religious / Christian / Animals"
  },
  {
    "Code": "JUV033060",
    "Literal": "JUVENILE FICTION / Religious / Christian / Bedtime & Dreams"
  },
  {
    "Code": "JUV033070",
    "Literal": "JUVENILE FICTION / Religious / Christian / Comics & Graphic Novels"
  },
  {
    "Code": "JUV033080",
    "Literal": "JUVENILE FICTION / Religious / Christian / Early Readers"
  },
  {
    "Code": "JUV033090",
    "Literal": "JUVENILE FICTION / Religious / Christian / Emotions & Feelings"
  },
  {
    "Code": "JUV033100",
    "Literal": "JUVENILE FICTION / Religious / Christian / Family"
  },
  {
    "Code": "JUV033110",
    "Literal": "JUVENILE FICTION / Religious / Christian / Fantasy & Science Fiction"
  },
  {
    "Code": "JUV033120",
    "Literal": "JUVENILE FICTION / Religious / Christian / Friendship"
  },
  {
    "Code": "JUV033140",
    "Literal": "JUVENILE FICTION / Religious / Christian / Historical"
  },
  {
    "Code": "JUV033150",
    "Literal": "JUVENILE FICTION / Religious / Christian / Holidays & Celebrations"
  },
  {
    "Code": "JUV033160",
    "Literal": "JUVENILE FICTION / Religious / Christian / Humorous"
  },
  {
    "Code": "JUV033280",
    "Literal": "JUVENILE FICTION / Religious / Christian / Inspirational"
  },
  {
    "Code": "JUV033170",
    "Literal": "JUVENILE FICTION / Religious / Christian / Learning Concepts"
  },
  {
    "Code": "JUV033180",
    "Literal": "JUVENILE FICTION / Religious / Christian / Mysteries & Detective Stories"
  },
  {
    "Code": "JUV033190",
    "Literal": "JUVENILE FICTION / Religious / Christian / People & Places"
  },
  {
    "Code": "JUV033200",
    "Literal": "JUVENILE FICTION / Religious / Christian / Relationships"
  },
  {
    "Code": "JUV033220",
    "Literal": "JUVENILE FICTION / Religious / Christian / Social Issues"
  },
  {
    "Code": "JUV033230",
    "Literal": "JUVENILE FICTION / Religious / Christian / Sports & Recreation"
  },
  {
    "Code": "JUV033240",
    "Literal": "JUVENILE FICTION / Religious / Christian / Values & Virtues"
  },
  {
    "Code": "JUV033260",
    "Literal": "JUVENILE FICTION / Religious / Hindu"
  },
  {
    "Code": "JUV033020",
    "Literal": "JUVENILE FICTION / Religious / Jewish"
  },
  {
    "Code": "JUV033270",
    "Literal": "JUVENILE FICTION / Religious / Muslim"
  },
  {
    "Code": "JUV056000",
    "Literal": "JUVENILE FICTION / Robots"
  },
  {
    "Code": "JUV034000",
    "Literal": "JUVENILE FICTION / Royalty"
  },
  {
    "Code": "JUV035000",
    "Literal": "JUVENILE FICTION / School & Education"
  },
  {
    "Code": "JUV029000",
    "Literal": "JUVENILE FICTION / Science & Nature / General"
  },
  {
    "Code": "JUV029030",
    "Literal": "JUVENILE FICTION / Science & Nature / Disasters"
  },
  {
    "Code": "JUV029010",
    "Literal": "JUVENILE FICTION / Science & Nature / Environment"
  },
  {
    "Code": "JUV029040",
    "Literal": "JUVENILE FICTION / Science & Nature / Flowers & Plants"
  },
  {
    "Code": "JUV029050",
    "Literal": "JUVENILE FICTION / Science & Nature / Trees & Forests"
  },
  {
    "Code": "JUV029020",
    "Literal": "JUVENILE FICTION / Science & Nature / Weather"
  },
  {
    "Code": "JUV053000",
    "Literal": "JUVENILE FICTION / Science Fiction / General"
  },
  {
    "Code": "JUV053010",
    "Literal": "JUVENILE FICTION / Science Fiction / Alien Contact"
  },
  {
    "Code": "JUV053020",
    "Literal": "JUVENILE FICTION / Science Fiction / Space Exploration"
  },
  {
    "Code": "JUV064000",
    "Literal": "JUVENILE FICTION / Science Fiction / Time Travel"
  },
  {
    "Code": "JUV038000",
    "Literal": "JUVENILE FICTION / Short Stories"
  },
  {
    "Code": "JUV039000",
    "Literal": "JUVENILE FICTION / Social Themes / General (see also headings under Family)"
  },
  {
    "Code": "JUV039290",
    "Literal": "JUVENILE FICTION / Social Themes / Activism & Social Justice"
  },
  {
    "Code": "JUV039020",
    "Literal": "JUVENILE FICTION / Social Themes / Adolescence & Coming of Age"
  },
  {
    "Code": "JUV039230",
    "Literal": "JUVENILE FICTION / Social Themes / Bullying"
  },
  {
    "Code": "JUV039190",
    "Literal": "JUVENILE FICTION / Social Themes / Dating & Relationships"
  },
  {
    "Code": "JUV039030",
    "Literal": "JUVENILE FICTION / Social Themes / Death, Grief, Bereavement"
  },
  {
    "Code": "JUV039240",
    "Literal": "JUVENILE FICTION / Social Themes / Depression & Mental Illness"
  },
  {
    "Code": "JUV039040",
    "Literal": "JUVENILE FICTION / Social Themes / Drugs, Alcohol, Substance Abuse"
  },
  {
    "Code": "JUV039250",
    "Literal": "JUVENILE FICTION / Social Themes / Emigration & Immigration"
  },
  {
    "Code": "JUV039050",
    "Literal": "JUVENILE FICTION / Social Themes / Emotions & Feelings"
  },
  {
    "Code": "JUV039060",
    "Literal": "JUVENILE FICTION / Social Themes / Friendship"
  },
  {
    "Code": "JUV039200",
    "Literal": "JUVENILE FICTION / Social Themes / Manners & Etiquette"
  },
  {
    "Code": "JUV039090",
    "Literal": "JUVENILE FICTION / Social Themes / New Experience"
  },
  {
    "Code": "JUV039100",
    "Literal": "JUVENILE FICTION / Social Themes / Peer Pressure"
  },
  {
    "Code": "JUV039010",
    "Literal": "JUVENILE FICTION / Social Themes / Physical & Emotional Abuse (see also Social Themes / Sexual Abuse)"
  },
  {
    "Code": "JUV039070",
    "Literal": "JUVENILE FICTION / Social Themes / Poverty & Homelessness"
  },
  {
    "Code": "JUV039120",
    "Literal": "JUVENILE FICTION / Social Themes / Prejudice & Racism"
  },
  {
    "Code": "JUV039280",
    "Literal": "JUVENILE FICTION / Social Themes / Religion & Faith"
  },
  {
    "Code": "JUV039130",
    "Literal": "JUVENILE FICTION / Social Themes / Runaways"
  },
  {
    "Code": "JUV039140",
    "Literal": "JUVENILE FICTION / Social Themes / Self-Esteem & Self-Reliance"
  },
  {
    "Code": "JUV039210",
    "Literal": "JUVENILE FICTION / Social Themes / Sexual Abuse"
  },
  {
    "Code": "JUV039270",
    "Literal": "JUVENILE FICTION / Social Themes / Strangers"
  },
  {
    "Code": "JUV039220",
    "Literal": "JUVENILE FICTION / Social Themes / Values & Virtues"
  },
  {
    "Code": "JUV039180",
    "Literal": "JUVENILE FICTION / Social Themes / Violence"
  },
  {
    "Code": "JUV076000",
    "Literal": "JUVENILE FICTION / Spies & Spying"
  },
  {
    "Code": "JUV032000",
    "Literal": "JUVENILE FICTION / Sports & Recreation / General"
  },
  {
    "Code": "JUV032010",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Baseball & Softball"
  },
  {
    "Code": "JUV032020",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Basketball"
  },
  {
    "Code": "JUV032170",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Camping & Outdoor Activities"
  },
  {
    "Code": "JUV032220",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Cheerleading"
  },
  {
    "Code": "JUV032180",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Cycling"
  },
  {
    "Code": "JUV032090",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Equestrian"
  },
  {
    "Code": "JUV032100",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Extreme Sports"
  },
  {
    "Code": "JUV032030",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Football"
  },
  {
    "Code": "JUV032040",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Games"
  },
  {
    "Code": "JUV032190",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Golf"
  },
  {
    "Code": "JUV032200",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Gymnastics"
  },
  {
    "Code": "JUV032110",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Hockey"
  },
  {
    "Code": "JUV032120",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Ice Skating"
  },
  {
    "Code": "JUV032070",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Martial Arts"
  },
  {
    "Code": "JUV032230",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Motor Sports"
  },
  {
    "Code": "JUV032240",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Olympics & Paralympics"
  },
  {
    "Code": "JUV032140",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Skateboarding"
  },
  {
    "Code": "JUV032150",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Soccer"
  },
  {
    "Code": "JUV032210",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Track & Field"
  },
  {
    "Code": "JUV032060",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Water Sports"
  },
  {
    "Code": "JUV032080",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Winter Sports"
  },
  {
    "Code": "JUV032160",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Wrestling"
  },
  {
    "Code": "JUV062000",
    "Literal": "JUVENILE FICTION / Steampunk"
  },
  {
    "Code": "JUV057000",
    "Literal": "JUVENILE FICTION / Stories in Verse (see also Poetry)"
  },
  {
    "Code": "JUV071000",
    "Literal": "JUVENILE FICTION / Superheroes"
  },
  {
    "Code": "JUV036000",
    "Literal": "JUVENILE FICTION / Technology / General"
  },
  {
    "Code": "JUV036010",
    "Literal": "JUVENILE FICTION / Technology / Astronauts & Space"
  },
  {
    "Code": "JUV036020",
    "Literal": "JUVENILE FICTION / Technology / Inventions"
  },
  {
    "Code": "JUV067000",
    "Literal": "JUVENILE FICTION / Thrillers & Suspense"
  },
  {
    "Code": "JUV040000",
    "Literal": "JUVENILE FICTION / Toys, Dolls & Puppets"
  },
  {
    "Code": "JUV041000",
    "Literal": "JUVENILE FICTION / Transportation / General"
  },
  {
    "Code": "JUV041010",
    "Literal": "JUVENILE FICTION / Transportation / Aviation"
  },
  {
    "Code": "JUV041020",
    "Literal": "JUVENILE FICTION / Transportation / Boats, Ships & Underwater Craft"
  },
  {
    "Code": "JUV041030",
    "Literal": "JUVENILE FICTION / Transportation / Cars & Trucks"
  },
  {
    "Code": "JUV041050",
    "Literal": "JUVENILE FICTION / Transportation / Railroads & Trains"
  },
  {
    "Code": "JUV068000",
    "Literal": "JUVENILE FICTION / Travel"
  },
  {
    "Code": "JUV087000",
    "Literal": "JUVENILE FICTION / Trickster Tales"
  },
  {
    "Code": "JUV078000",
    "Literal": "JUVENILE FICTION / Vampires"
  },
  {
    "Code": "JUV046000",
    "Literal": "JUVENILE FICTION / Visionary & Metaphysical"
  },
  {
    "Code": "JUV075000",
    "Literal": "JUVENILE FICTION / War & Military"
  },
  {
    "Code": "JUV079000",
    "Literal": "JUVENILE FICTION / Werewolves & Shifters"
  },
  {
    "Code": "JUV042000",
    "Literal": "JUVENILE FICTION / Westerns"
  },
  {
    "Code": "JUV080000",
    "Literal": "JUVENILE FICTION / Zombies"
  },
  {
    "Code": "JNF000000",
    "Literal": "JUVENILE NONFICTION / General"
  },
  {
    "Code": "JNF071000",
    "Literal": "JUVENILE NONFICTION / Activism & Social Justice"
  },
  {
    "Code": "JNF001000",
    "Literal": "JUVENILE NONFICTION / Activity Books / General"
  },
  {
    "Code": "JNF001010",
    "Literal": "JUVENILE NONFICTION / Activity Books / Coloring"
  },
  {
    "Code": "JNF001020",
    "Literal": "JUVENILE NONFICTION / Activity Books / Sticker"
  },
  {
    "Code": "JNF002000",
    "Literal": "JUVENILE NONFICTION / Adventure & Adventurers"
  },
  {
    "Code": "JNF018010",
    "Literal": "JUVENILE NONFICTION / African American & Black"
  },
  {
    "Code": "JNF003000",
    "Literal": "JUVENILE NONFICTION / Animals / General"
  },
  {
    "Code": "JNF003220",
    "Literal": "JUVENILE NONFICTION / Animals / Animal Welfare"
  },
  {
    "Code": "JNF003010",
    "Literal": "JUVENILE NONFICTION / Animals / Apes, Monkeys, etc."
  },
  {
    "Code": "JNF003330",
    "Literal": "JUVENILE NONFICTION / Animals / Baby Animals"
  },
  {
    "Code": "JNF003020",
    "Literal": "JUVENILE NONFICTION / Animals / Bears"
  },
  {
    "Code": "JNF003030",
    "Literal": "JUVENILE NONFICTION / Animals / Birds"
  },
  {
    "Code": "JNF003250",
    "Literal": "JUVENILE NONFICTION / Animals / Butterflies, Moths & Caterpillars"
  },
  {
    "Code": "JNF003040",
    "Literal": "JUVENILE NONFICTION / Animals / Cats"
  },
  {
    "Code": "JNF003260",
    "Literal": "JUVENILE NONFICTION / Animals / Cows"
  },
  {
    "Code": "JNF003230",
    "Literal": "JUVENILE NONFICTION / Animals / Deer, Moose & Caribou"
  },
  {
    "Code": "JNF003050",
    "Literal": "JUVENILE NONFICTION / Animals / Dinosaurs & Prehistoric Creatures"
  },
  {
    "Code": "JNF003060",
    "Literal": "JUVENILE NONFICTION / Animals / Dogs"
  },
  {
    "Code": "JNF003210",
    "Literal": "JUVENILE NONFICTION / Animals / Ducks, Geese, etc."
  },
  {
    "Code": "JNF003070",
    "Literal": "JUVENILE NONFICTION / Animals / Elephants"
  },
  {
    "Code": "JNF003270",
    "Literal": "JUVENILE NONFICTION / Animals / Endangered"
  },
  {
    "Code": "JNF003080",
    "Literal": "JUVENILE NONFICTION / Animals / Farm Animals"
  },
  {
    "Code": "JNF003090",
    "Literal": "JUVENILE NONFICTION / Animals / Fish"
  },
  {
    "Code": "JNF003100",
    "Literal": "JUVENILE NONFICTION / Animals / Foxes"
  },
  {
    "Code": "JNF003340",
    "Literal": "JUVENILE NONFICTION / Animals / Frogs & Toads"
  },
  {
    "Code": "JNF003280",
    "Literal": "JUVENILE NONFICTION / Animals / Giraffes"
  },
  {
    "Code": "JNF003290",
    "Literal": "JUVENILE NONFICTION / Animals / Hippos & Rhinos"
  },
  {
    "Code": "JNF003110",
    "Literal": "JUVENILE NONFICTION / Animals / Horses"
  },
  {
    "Code": "JNF003120",
    "Literal": "JUVENILE NONFICTION / Animals / Insects, Spiders, etc."
  },
  {
    "Code": "JNF003300",
    "Literal": "JUVENILE NONFICTION / Animals / Jungle Animals"
  },
  {
    "Code": "JNF003310",
    "Literal": "JUVENILE NONFICTION / Animals / Kangaroos"
  },
  {
    "Code": "JNF003130",
    "Literal": "JUVENILE NONFICTION / Animals / Lions, Tigers, Leopards, etc."
  },
  {
    "Code": "JNF003140",
    "Literal": "JUVENILE NONFICTION / Animals / Mammals"
  },
  {
    "Code": "JNF003150",
    "Literal": "JUVENILE NONFICTION / Animals / Marine Life"
  },
  {
    "Code": "JNF003160",
    "Literal": "JUVENILE NONFICTION / Animals / Mice, Hamsters, Guinea Pigs, Squirrels, etc."
  },
  {
    "Code": "JNF003320",
    "Literal": "JUVENILE NONFICTION / Animals / Nocturnal"
  },
  {
    "Code": "JNF003350",
    "Literal": "JUVENILE NONFICTION / Animals / Penguins"
  },
  {
    "Code": "JNF003170",
    "Literal": "JUVENILE NONFICTION / Animals / Pets"
  },
  {
    "Code": "JNF003180",
    "Literal": "JUVENILE NONFICTION / Animals / Rabbits"
  },
  {
    "Code": "JNF003190",
    "Literal": "JUVENILE NONFICTION / Animals / Reptiles & Amphibians"
  },
  {
    "Code": "JNF003360",
    "Literal": "JUVENILE NONFICTION / Animals / Turtles & Tortoises"
  },
  {
    "Code": "JNF003240",
    "Literal": "JUVENILE NONFICTION / Animals / Wolves, Coyotes & Wild Dogs"
  },
  {
    "Code": "JNF003370",
    "Literal": "JUVENILE NONFICTION / Animals / Worms"
  },
  {
    "Code": "JNF003200",
    "Literal": "JUVENILE NONFICTION / Animals / Zoos"
  },
  {
    "Code": "JNF004000",
    "Literal": "JUVENILE NONFICTION / Antiques & Collectibles"
  },
  {
    "Code": "JNF005000",
    "Literal": "JUVENILE NONFICTION / Architecture"
  },
  {
    "Code": "JNF006000",
    "Literal": "JUVENILE NONFICTION / Art / General"
  },
  {
    "Code": "JNF006010",
    "Literal": "JUVENILE NONFICTION / Art / Cartooning"
  },
  {
    "Code": "JNF006020",
    "Literal": "JUVENILE NONFICTION / Art / Drawing"
  },
  {
    "Code": "JNF006030",
    "Literal": "JUVENILE NONFICTION / Art / Fashion"
  },
  {
    "Code": "JNF006040",
    "Literal": "JUVENILE NONFICTION / Art / History"
  },
  {
    "Code": "JNF006050",
    "Literal": "JUVENILE NONFICTION / Art / Painting"
  },
  {
    "Code": "JNF006060",
    "Literal": "JUVENILE NONFICTION / Art / Sculpture"
  },
  {
    "Code": "JNF006070",
    "Literal": "JUVENILE NONFICTION / Art / Techniques"
  },
  {
    "Code": "JNF018020",
    "Literal": "JUVENILE NONFICTION / Asian American & Pacific Islander"
  },
  {
    "Code": "JNF067000",
    "Literal": "JUVENILE NONFICTION / Bedtime & Dreams"
  },
  {
    "Code": "JNF007000",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / General"
  },
  {
    "Code": "JNF007010",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Art"
  },
  {
    "Code": "JNF007050",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Cultural & Regional"
  },
  {
    "Code": "JNF007020",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Historical"
  },
  {
    "Code": "JNF007150",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / LGBTQ+"
  },
  {
    "Code": "JNF007030",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Literary"
  },
  {
    "Code": "JNF007040",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Music"
  },
  {
    "Code": "JNF007060",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Performing Arts"
  },
  {
    "Code": "JNF007070",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Political"
  },
  {
    "Code": "JNF007130",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Presidents & First Families (U.S.)"
  },
  {
    "Code": "JNF007080",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Religious (see also Religious / Christian / Biography & Autobiography)"
  },
  {
    "Code": "JNF007140",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Royalty"
  },
  {
    "Code": "JNF007090",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Science & Technology"
  },
  {
    "Code": "JNF007110",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Social Activists"
  },
  {
    "Code": "JNF007100",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Sports & Recreation"
  },
  {
    "Code": "JNF007120",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Women"
  },
  {
    "Code": "JNF074000",
    "Literal": "JUVENILE NONFICTION / Biracial & Multiracial"
  },
  {
    "Code": "JNF063000",
    "Literal": "JUVENILE NONFICTION / Books & Libraries"
  },
  {
    "Code": "JNF009000",
    "Literal": "JUVENILE NONFICTION / Boys & Men"
  },
  {
    "Code": "JNF010000",
    "Literal": "JUVENILE NONFICTION / Business & Economics"
  },
  {
    "Code": "JNF011000",
    "Literal": "JUVENILE NONFICTION / Careers"
  },
  {
    "Code": "JNF059000",
    "Literal": "JUVENILE NONFICTION / Clothing & Dress"
  },
  {
    "Code": "JNF062000",
    "Literal": "JUVENILE NONFICTION / Comics & Graphic Novels / General"
  },
  {
    "Code": "JNF062010",
    "Literal": "JUVENILE NONFICTION / Comics & Graphic Novels / Biography & Memoir"
  },
  {
    "Code": "JNF062020",
    "Literal": "JUVENILE NONFICTION / Comics & Graphic Novels / History"
  },
  {
    "Code": "JNF062030",
    "Literal": "JUVENILE NONFICTION / Comics & Graphic Novels / Science & Nature"
  },
  {
    "Code": "JNF062040",
    "Literal": "JUVENILE NONFICTION / Comics & Graphic Novels / Social Topics"
  },
  {
    "Code": "JNF012000",
    "Literal": "JUVENILE NONFICTION / Computers / General"
  },
  {
    "Code": "JNF012040",
    "Literal": "JUVENILE NONFICTION / Computers / Coding & Programming"
  },
  {
    "Code": "JNF012010",
    "Literal": "JUVENILE NONFICTION / Computers / Entertainment & Games"
  },
  {
    "Code": "JNF012030",
    "Literal": "JUVENILE NONFICTION / Computers / Internet & Social Media"
  },
  {
    "Code": "JNF012050",
    "Literal": "JUVENILE NONFICTION / Computers / Software"
  },
  {
    "Code": "JNF013000",
    "Literal": "JUVENILE NONFICTION / Concepts / General"
  },
  {
    "Code": "JNF013010",
    "Literal": "JUVENILE NONFICTION / Concepts / Alphabet"
  },
  {
    "Code": "JNF013110",
    "Literal": "JUVENILE NONFICTION / Concepts / Body"
  },
  {
    "Code": "JNF013020",
    "Literal": "JUVENILE NONFICTION / Concepts / Colors"
  },
  {
    "Code": "JNF013030",
    "Literal": "JUVENILE NONFICTION / Concepts / Counting & Numbers"
  },
  {
    "Code": "JNF013080",
    "Literal": "JUVENILE NONFICTION / Concepts / Date & Time"
  },
  {
    "Code": "JNF013040",
    "Literal": "JUVENILE NONFICTION / Concepts / Money"
  },
  {
    "Code": "JNF013050",
    "Literal": "JUVENILE NONFICTION / Concepts / Opposites"
  },
  {
    "Code": "JNF013090",
    "Literal": "JUVENILE NONFICTION / Concepts / Seasons"
  },
  {
    "Code": "JNF013060",
    "Literal": "JUVENILE NONFICTION / Concepts / Senses & Sensation"
  },
  {
    "Code": "JNF013070",
    "Literal": "JUVENILE NONFICTION / Concepts / Size & Shape"
  },
  {
    "Code": "JNF013100",
    "Literal": "JUVENILE NONFICTION / Concepts / Sounds"
  },
  {
    "Code": "JNF013120",
    "Literal": "JUVENILE NONFICTION / Concepts / Words (see also headings under Language Arts)"
  },
  {
    "Code": "JNF014000",
    "Literal": "JUVENILE NONFICTION / Cooking & Food"
  },
  {
    "Code": "JNF015000",
    "Literal": "JUVENILE NONFICTION / Crafts & Hobbies"
  },
  {
    "Code": "JNF016000",
    "Literal": "JUVENILE NONFICTION / Curiosities & Wonders"
  },
  {
    "Code": "JNF053180",
    "Literal": "JUVENILE NONFICTION / Disabilities"
  },
  {
    "Code": "JNF069000",
    "Literal": "JUVENILE NONFICTION / Diversity & Multicultural"
  },
  {
    "Code": "JNF017000",
    "Literal": "JUVENILE NONFICTION / Drama"
  },
  {
    "Code": "JNF019000",
    "Literal": "JUVENILE NONFICTION / Family / General (see also headings under Social Topics)"
  },
  {
    "Code": "JNF019010",
    "Literal": "JUVENILE NONFICTION / Family / Adoption"
  },
  {
    "Code": "JNF019090",
    "Literal": "JUVENILE NONFICTION / Family / Alternative Family"
  },
  {
    "Code": "JNF019080",
    "Literal": "JUVENILE NONFICTION / Family / Blended Families"
  },
  {
    "Code": "JNF019100",
    "Literal": "JUVENILE NONFICTION / Family / Grandparents"
  },
  {
    "Code": "JNF019020",
    "Literal": "JUVENILE NONFICTION / Family / Marriage & Divorce"
  },
  {
    "Code": "JNF019030",
    "Literal": "JUVENILE NONFICTION / Family / Multigenerational"
  },
  {
    "Code": "JNF019040",
    "Literal": "JUVENILE NONFICTION / Family / New Baby"
  },
  {
    "Code": "JNF019050",
    "Literal": "JUVENILE NONFICTION / Family / Orphans & Foster Homes"
  },
  {
    "Code": "JNF019060",
    "Literal": "JUVENILE NONFICTION / Family / Parents"
  },
  {
    "Code": "JNF019070",
    "Literal": "JUVENILE NONFICTION / Family / Siblings"
  },
  {
    "Code": "JNF075000",
    "Literal": "JUVENILE NONFICTION / First Nations"
  },
  {
    "Code": "JNF021000",
    "Literal": "JUVENILE NONFICTION / Games & Activities / General"
  },
  {
    "Code": "JNF021010",
    "Literal": "JUVENILE NONFICTION / Games & Activities / Board Games"
  },
  {
    "Code": "JNF021020",
    "Literal": "JUVENILE NONFICTION / Games & Activities / Card Games"
  },
  {
    "Code": "JNF021030",
    "Literal": "JUVENILE NONFICTION / Games & Activities / Magic"
  },
  {
    "Code": "JNF021040",
    "Literal": "JUVENILE NONFICTION / Games & Activities / Puzzles"
  },
  {
    "Code": "JNF021050",
    "Literal": "JUVENILE NONFICTION / Games & Activities / Questions & Answers"
  },
  {
    "Code": "JNF021060",
    "Literal": "JUVENILE NONFICTION / Games & Activities / Video & Electronic Games"
  },
  {
    "Code": "JNF021070",
    "Literal": "JUVENILE NONFICTION / Games & Activities / Word Games"
  },
  {
    "Code": "JNF022000",
    "Literal": "JUVENILE NONFICTION / Gardening"
  },
  {
    "Code": "JNF023000",
    "Literal": "JUVENILE NONFICTION / Girls & Women"
  },
  {
    "Code": "JNF024000",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / General"
  },
  {
    "Code": "JNF024120",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Daily Activities"
  },
  {
    "Code": "JNF024010",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Diet & Nutrition"
  },
  {
    "Code": "JNF024020",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Diseases, Illnesses & Injuries"
  },
  {
    "Code": "JNF024100",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Drugs, Alcohol, Substance Abuse"
  },
  {
    "Code": "JNF024030",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / First Aid"
  },
  {
    "Code": "JNF024040",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Fitness & Exercise"
  },
  {
    "Code": "JNF024050",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Maturing"
  },
  {
    "Code": "JNF024140",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Mental Health"
  },
  {
    "Code": "JNF024130",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Mindfulness & Meditation"
  },
  {
    "Code": "JNF024060",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Personal Hygiene"
  },
  {
    "Code": "JNF024070",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Physical Impairments"
  },
  {
    "Code": "JNF024080",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Safety"
  },
  {
    "Code": "JNF024090",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Sexuality & Pregnancy"
  },
  {
    "Code": "JNF024110",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Toilet Training"
  },
  {
    "Code": "JNF018030",
    "Literal": "JUVENILE NONFICTION / Hispanic & Latino"
  },
  {
    "Code": "JNF025000",
    "Literal": "JUVENILE NONFICTION / History / General"
  },
  {
    "Code": "JNF025010",
    "Literal": "JUVENILE NONFICTION / History / Africa"
  },
  {
    "Code": "JNF025020",
    "Literal": "JUVENILE NONFICTION / History / Ancient"
  },
  {
    "Code": "JNF025030",
    "Literal": "JUVENILE NONFICTION / History / Asia"
  },
  {
    "Code": "JNF025040",
    "Literal": "JUVENILE NONFICTION / History / Australia & Oceania"
  },
  {
    "Code": "JNF025050",
    "Literal": "JUVENILE NONFICTION / History / Canada / General"
  },
  {
    "Code": "JNF025230",
    "Literal": "JUVENILE NONFICTION / History / Canada / Pre-Confederation (to 1867)"
  },
  {
    "Code": "JNF025240",
    "Literal": "JUVENILE NONFICTION / History / Canada / Post-Confederation (1867-)"
  },
  {
    "Code": "JNF025060",
    "Literal": "JUVENILE NONFICTION / History / Central & South America"
  },
  {
    "Code": "JNF025070",
    "Literal": "JUVENILE NONFICTION / History / Europe"
  },
  {
    "Code": "JNF025080",
    "Literal": "JUVENILE NONFICTION / History / Exploration & Discovery"
  },
  {
    "Code": "JNF025090",
    "Literal": "JUVENILE NONFICTION / History / Holocaust"
  },
  {
    "Code": "JNF025100",
    "Literal": "JUVENILE NONFICTION / History / Medieval"
  },
  {
    "Code": "JNF025110",
    "Literal": "JUVENILE NONFICTION / History / Mexico"
  },
  {
    "Code": "JNF025120",
    "Literal": "JUVENILE NONFICTION / History / Middle East"
  },
  {
    "Code": "JNF025130",
    "Literal": "JUVENILE NONFICTION / History / Military & Wars"
  },
  {
    "Code": "JNF025140",
    "Literal": "JUVENILE NONFICTION / History / Modern"
  },
  {
    "Code": "JNF025150",
    "Literal": "JUVENILE NONFICTION / History / Prehistoric"
  },
  {
    "Code": "JNF025160",
    "Literal": "JUVENILE NONFICTION / History / Renaissance"
  },
  {
    "Code": "JNF025260",
    "Literal": "JUVENILE NONFICTION / History / Symbols, Monuments, National Parks, etc."
  },
  {
    "Code": "JNF025170",
    "Literal": "JUVENILE NONFICTION / History / United States / General"
  },
  {
    "Code": "JNF025180",
    "Literal": "JUVENILE NONFICTION / History / United States / State & Local"
  },
  {
    "Code": "JNF025190",
    "Literal": "JUVENILE NONFICTION / History / United States / Colonial & Revolutionary Periods"
  },
  {
    "Code": "JNF025200",
    "Literal": "JUVENILE NONFICTION / History / United States / 19th Century"
  },
  {
    "Code": "JNF025270",
    "Literal": "JUVENILE NONFICTION / History / United States / Civil War Period (1850-1877)"
  },
  {
    "Code": "JNF025210",
    "Literal": "JUVENILE NONFICTION / History / United States / 20th Century"
  },
  {
    "Code": "JNF025250",
    "Literal": "JUVENILE NONFICTION / History / United States / 21st Century"
  },
  {
    "Code": "JNF026000",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / General (see also Religious / Christian / Holidays & Celebrations)"
  },
  {
    "Code": "JNF026100",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Birthdays"
  },
  {
    "Code": "JNF026010",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Christmas & Advent"
  },
  {
    "Code": "JNF026140",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Day of the Dead"
  },
  {
    "Code": "JNF026150",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Diwali"
  },
  {
    "Code": "JNF026020",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Easter & Lent"
  },
  {
    "Code": "JNF026030",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Halloween"
  },
  {
    "Code": "JNF026110",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Hanukkah"
  },
  {
    "Code": "JNF026160",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Juneteenth"
  },
  {
    "Code": "JNF026050",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Kwanzaa"
  },
  {
    "Code": "JNF026170",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Lunar New Year"
  },
  {
    "Code": "JNF026120",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Passover"
  },
  {
    "Code": "JNF026130",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Patriotic Holidays"
  },
  {
    "Code": "JNF026180",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Ramadan"
  },
  {
    "Code": "JNF026060",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Thanksgiving"
  },
  {
    "Code": "JNF026070",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Valentine's Day"
  },
  {
    "Code": "JNF026080",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Other, Non-Religious"
  },
  {
    "Code": "JNF026090",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Other, Religious"
  },
  {
    "Code": "JNF027000",
    "Literal": "JUVENILE NONFICTION / House & Home"
  },
  {
    "Code": "JNF028000",
    "Literal": "JUVENILE NONFICTION / Humor / General"
  },
  {
    "Code": "JNF028010",
    "Literal": "JUVENILE NONFICTION / Humor / Comic Strips & Cartoons"
  },
  {
    "Code": "JNF028020",
    "Literal": "JUVENILE NONFICTION / Humor / Jokes & Riddles"
  },
  {
    "Code": "JNF076000",
    "Literal": "JUVENILE NONFICTION / Indigenous / General"
  },
  {
    "Code": "JNF076010",
    "Literal": "JUVENILE NONFICTION / Indigenous / Animal Stories"
  },
  {
    "Code": "JNF076020",
    "Literal": "JUVENILE NONFICTION / Indigenous / Elders"
  },
  {
    "Code": "JNF076030",
    "Literal": "JUVENILE NONFICTION / Indigenous / Family Life"
  },
  {
    "Code": "JNF076040",
    "Literal": "JUVENILE NONFICTION / Indigenous / Health & Healing"
  },
  {
    "Code": "JNF076050",
    "Literal": "JUVENILE NONFICTION / Indigenous / History"
  },
  {
    "Code": "JNF076060",
    "Literal": "JUVENILE NONFICTION / Indigenous / Land-Based Knowledge"
  },
  {
    "Code": "JNF076070",
    "Literal": "JUVENILE NONFICTION / Indigenous / Reconciliation"
  },
  {
    "Code": "JNF038120",
    "Literal": "JUVENILE NONFICTION / Indigenous Peoples of Turtle Island"
  },
  {
    "Code": "JNF070000",
    "Literal": "JUVENILE NONFICTION / Inspirational & Personal Growth"
  },
  {
    "Code": "JNF077000",
    "Literal": "JUVENILE NONFICTION / Inuit"
  },
  {
    "Code": "JNF029000",
    "Literal": "JUVENILE NONFICTION / Language Arts / General"
  },
  {
    "Code": "JNF029010",
    "Literal": "JUVENILE NONFICTION / Language Arts / Composition & Creative Writing"
  },
  {
    "Code": "JNF029020",
    "Literal": "JUVENILE NONFICTION / Language Arts / Grammar"
  },
  {
    "Code": "JNF029030",
    "Literal": "JUVENILE NONFICTION / Language Arts / Handwriting"
  },
  {
    "Code": "JNF029060",
    "Literal": "JUVENILE NONFICTION / Language Arts / Journal Writing"
  },
  {
    "Code": "JNF029050",
    "Literal": "JUVENILE NONFICTION / Language Arts / Sign Language"
  },
  {
    "Code": "JNF029040",
    "Literal": "JUVENILE NONFICTION / Language Arts / Vocabulary & Spelling"
  },
  {
    "Code": "JNF020000",
    "Literal": "JUVENILE NONFICTION / Language Study / General"
  },
  {
    "Code": "JNF020010",
    "Literal": "JUVENILE NONFICTION / Language Study / English as a Second Language"
  },
  {
    "Code": "JNF020020",
    "Literal": "JUVENILE NONFICTION / Language Study / French"
  },
  {
    "Code": "JNF020040",
    "Literal": "JUVENILE NONFICTION / Language Study / Indigenous Languages in the Americas"
  },
  {
    "Code": "JNF020030",
    "Literal": "JUVENILE NONFICTION / Language Study / Spanish"
  },
  {
    "Code": "JNF030000",
    "Literal": "JUVENILE NONFICTION / Law & Crime"
  },
  {
    "Code": "JNF053080",
    "Literal": "JUVENILE NONFICTION / LGBTQ+"
  },
  {
    "Code": "JNF031000",
    "Literal": "JUVENILE NONFICTION / Lifestyles / City & Town Life"
  },
  {
    "Code": "JNF032000",
    "Literal": "JUVENILE NONFICTION / Lifestyles / Country Life"
  },
  {
    "Code": "JNF033000",
    "Literal": "JUVENILE NONFICTION / Lifestyles / Farm & Ranch Life"
  },
  {
    "Code": "JNF034000",
    "Literal": "JUVENILE NONFICTION / Literary Criticism & Collections"
  },
  {
    "Code": "JNF035000",
    "Literal": "JUVENILE NONFICTION / Mathematics / General"
  },
  {
    "Code": "JNF035020",
    "Literal": "JUVENILE NONFICTION / Mathematics / Algebra"
  },
  {
    "Code": "JNF035030",
    "Literal": "JUVENILE NONFICTION / Mathematics / Arithmetic"
  },
  {
    "Code": "JNF035040",
    "Literal": "JUVENILE NONFICTION / Mathematics / Fractions"
  },
  {
    "Code": "JNF035050",
    "Literal": "JUVENILE NONFICTION / Mathematics / Geometry"
  },
  {
    "Code": "JNF060000",
    "Literal": "JUVENILE NONFICTION / Media Studies"
  },
  {
    "Code": "JNF064000",
    "Literal": "JUVENILE NONFICTION / Media Tie-In"
  },
  {
    "Code": "JNF078000",
    "Literal": "JUVENILE NONFICTION / Métis"
  },
  {
    "Code": "JNF038130",
    "Literal": "JUVENILE NONFICTION / Middle Eastern & Arab American"
  },
  {
    "Code": "JNF036000",
    "Literal": "JUVENILE NONFICTION / Music / General"
  },
  {
    "Code": "JNF036010",
    "Literal": "JUVENILE NONFICTION / Music / Classical"
  },
  {
    "Code": "JNF036020",
    "Literal": "JUVENILE NONFICTION / Music / History"
  },
  {
    "Code": "JNF036030",
    "Literal": "JUVENILE NONFICTION / Music / Instruction & Study"
  },
  {
    "Code": "JNF036090",
    "Literal": "JUVENILE NONFICTION / Music / Instruments"
  },
  {
    "Code": "JNF036040",
    "Literal": "JUVENILE NONFICTION / Music / Jazz"
  },
  {
    "Code": "JNF036050",
    "Literal": "JUVENILE NONFICTION / Music / Popular"
  },
  {
    "Code": "JNF036060",
    "Literal": "JUVENILE NONFICTION / Music / Rap & Hip Hop"
  },
  {
    "Code": "JNF036070",
    "Literal": "JUVENILE NONFICTION / Music / Rock"
  },
  {
    "Code": "JNF036080",
    "Literal": "JUVENILE NONFICTION / Music / Songbooks"
  },
  {
    "Code": "JNF018040",
    "Literal": "JUVENILE NONFICTION / Native American"
  },
  {
    "Code": "JNF072000",
    "Literal": "JUVENILE NONFICTION / Neurodiversity"
  },
  {
    "Code": "JNF008000",
    "Literal": "JUVENILE NONFICTION / Paranormal & Supernatural"
  },
  {
    "Code": "JNF039000",
    "Literal": "JUVENILE NONFICTION / Performing Arts / General"
  },
  {
    "Code": "JNF039010",
    "Literal": "JUVENILE NONFICTION / Performing Arts / Circus"
  },
  {
    "Code": "JNF039020",
    "Literal": "JUVENILE NONFICTION / Performing Arts / Dance"
  },
  {
    "Code": "JNF039030",
    "Literal": "JUVENILE NONFICTION / Performing Arts / Film"
  },
  {
    "Code": "JNF039040",
    "Literal": "JUVENILE NONFICTION / Performing Arts / Television & Radio"
  },
  {
    "Code": "JNF039050",
    "Literal": "JUVENILE NONFICTION / Performing Arts / Theater & Musicals"
  },
  {
    "Code": "JNF040000",
    "Literal": "JUVENILE NONFICTION / Philosophy"
  },
  {
    "Code": "JNF041000",
    "Literal": "JUVENILE NONFICTION / Photography"
  },
  {
    "Code": "JNF066000",
    "Literal": "JUVENILE NONFICTION / Pirates"
  },
  {
    "Code": "JNF038000",
    "Literal": "JUVENILE NONFICTION / Places / General"
  },
  {
    "Code": "JNF038010",
    "Literal": "JUVENILE NONFICTION / Places / Africa"
  },
  {
    "Code": "JNF038020",
    "Literal": "JUVENILE NONFICTION / Places / Asia"
  },
  {
    "Code": "JNF038030",
    "Literal": "JUVENILE NONFICTION / Places / Australia & Oceania"
  },
  {
    "Code": "JNF038040",
    "Literal": "JUVENILE NONFICTION / Places / Canada"
  },
  {
    "Code": "JNF038050",
    "Literal": "JUVENILE NONFICTION / Places / Caribbean & Latin America"
  },
  {
    "Code": "JNF038060",
    "Literal": "JUVENILE NONFICTION / Places / Europe"
  },
  {
    "Code": "JNF038070",
    "Literal": "JUVENILE NONFICTION / Places / Mexico"
  },
  {
    "Code": "JNF038080",
    "Literal": "JUVENILE NONFICTION / Places / Middle East"
  },
  {
    "Code": "JNF038090",
    "Literal": "JUVENILE NONFICTION / Places / Polar Regions"
  },
  {
    "Code": "JNF038100",
    "Literal": "JUVENILE NONFICTION / Places / United States"
  },
  {
    "Code": "JNF042000",
    "Literal": "JUVENILE NONFICTION / Poetry / General"
  },
  {
    "Code": "JNF042010",
    "Literal": "JUVENILE NONFICTION / Poetry / Humorous"
  },
  {
    "Code": "JNF045000",
    "Literal": "JUVENILE NONFICTION / Readers / Beginner"
  },
  {
    "Code": "JNF046000",
    "Literal": "JUVENILE NONFICTION / Readers / Intermediate"
  },
  {
    "Code": "JNF047000",
    "Literal": "JUVENILE NONFICTION / Readers / Chapter Books"
  },
  {
    "Code": "JNF079000",
    "Literal": "JUVENILE NONFICTION / Readers / Hi-Lo"
  },
  {
    "Code": "JNF065000",
    "Literal": "JUVENILE NONFICTION / Recycling & Green Living"
  },
  {
    "Code": "JNF048000",
    "Literal": "JUVENILE NONFICTION / Reference / General"
  },
  {
    "Code": "JNF048010",
    "Literal": "JUVENILE NONFICTION / Reference / Almanacs"
  },
  {
    "Code": "JNF048020",
    "Literal": "JUVENILE NONFICTION / Reference / Atlases"
  },
  {
    "Code": "JNF048030",
    "Literal": "JUVENILE NONFICTION / Reference / Dictionaries"
  },
  {
    "Code": "JNF048040",
    "Literal": "JUVENILE NONFICTION / Reference / Encyclopedias"
  },
  {
    "Code": "JNF048050",
    "Literal": "JUVENILE NONFICTION / Reference / Thesauri"
  },
  {
    "Code": "JNF049000",
    "Literal": "JUVENILE NONFICTION / Religion / General"
  },
  {
    "Code": "JNF049040",
    "Literal": "JUVENILE NONFICTION / Religion / Bible Stories / General"
  },
  {
    "Code": "JNF049140",
    "Literal": "JUVENILE NONFICTION / Religion / Bible Stories / Old Testament"
  },
  {
    "Code": "JNF049150",
    "Literal": "JUVENILE NONFICTION / Religion / Bible Stories / New Testament"
  },
  {
    "Code": "JNF049020",
    "Literal": "JUVENILE NONFICTION / Religion / Biblical Biography"
  },
  {
    "Code": "JNF049170",
    "Literal": "JUVENILE NONFICTION / Religion / Biblical Reference"
  },
  {
    "Code": "JNF049010",
    "Literal": "JUVENILE NONFICTION / Religion / Biblical Studies"
  },
  {
    "Code": "JNF049320",
    "Literal": "JUVENILE NONFICTION / Religion / Buddhism"
  },
  {
    "Code": "JNF049080",
    "Literal": "JUVENILE NONFICTION / Religion / Christianity"
  },
  {
    "Code": "JNF049090",
    "Literal": "JUVENILE NONFICTION / Religion / Eastern"
  },
  {
    "Code": "JNF049330",
    "Literal": "JUVENILE NONFICTION / Religion / Hinduism"
  },
  {
    "Code": "JNF049340",
    "Literal": "JUVENILE NONFICTION / Religion / Indigenous / General"
  },
  {
    "Code": "JNF049350",
    "Literal": "JUVENILE NONFICTION / Religion / Indigenous / Ceremony & Spiritual Practice"
  },
  {
    "Code": "JNF049360",
    "Literal": "JUVENILE NONFICTION / Religion / Indigenous / Sacred Stories"
  },
  {
    "Code": "JNF049370",
    "Literal": "JUVENILE NONFICTION / Religion / Indigenous / Scripture & Prayerbooks"
  },
  {
    "Code": "JNF049380",
    "Literal": "JUVENILE NONFICTION / Religion / Indigenous / Spirituality"
  },
  {
    "Code": "JNF038140",
    "Literal": "JUVENILE NONFICTION / Religion / Indigenous / Teachings & Traditions"
  },
  {
    "Code": "JNF049100",
    "Literal": "JUVENILE NONFICTION / Religion / Islam"
  },
  {
    "Code": "JNF049110",
    "Literal": "JUVENILE NONFICTION / Religion / Judaism"
  },
  {
    "Code": "JNF049130",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / General"
  },
  {
    "Code": "JNF049180",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Biography & Autobiography (see also Biography & Autobiography / Religious)"
  },
  {
    "Code": "JNF049190",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Comics & Graphic Novels"
  },
  {
    "Code": "JNF049120",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Devotional & Prayer"
  },
  {
    "Code": "JNF049200",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Early Readers"
  },
  {
    "Code": "JNF049210",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Family & Relationships"
  },
  {
    "Code": "JNF049220",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Games & Activities"
  },
  {
    "Code": "JNF049240",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Holidays & Celebrations"
  },
  {
    "Code": "JNF049250",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Inspirational"
  },
  {
    "Code": "JNF049260",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Learning Concepts"
  },
  {
    "Code": "JNF049280",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Science & Nature"
  },
  {
    "Code": "JNF049290",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Social Topics"
  },
  {
    "Code": "JNF049310",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Values & Virtues"
  },
  {
    "Code": "JNF050000",
    "Literal": "JUVENILE NONFICTION / School & Education"
  },
  {
    "Code": "JNF051000",
    "Literal": "JUVENILE NONFICTION / Science & Nature / General (see also headings under Animals or Technology)"
  },
  {
    "Code": "JNF051030",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Anatomy & Physiology"
  },
  {
    "Code": "JNF051040",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Astronomy"
  },
  {
    "Code": "JNF051050",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Biology"
  },
  {
    "Code": "JNF051070",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Chemistry"
  },
  {
    "Code": "JNF051160",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Disasters"
  },
  {
    "Code": "JNF051170",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Discoveries"
  },
  {
    "Code": "JNF051080",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Earth Sciences / General"
  },
  {
    "Code": "JNF037010",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Earth Sciences / Earthquakes & Volcanoes"
  },
  {
    "Code": "JNF051180",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Earth Sciences / Geography"
  },
  {
    "Code": "JNF037060",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Earth Sciences / Rocks & Minerals"
  },
  {
    "Code": "JNF037070",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Earth Sciences / Water (Oceans, Lakes, etc.)"
  },
  {
    "Code": "JNF037080",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Earth Sciences / Weather"
  },
  {
    "Code": "JNF037020",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Environmental Conservation & Protection"
  },
  {
    "Code": "JNF051100",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Environmental Science & Ecosystems"
  },
  {
    "Code": "JNF051110",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Experiments & Projects"
  },
  {
    "Code": "JNF037030",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Flowers & Plants"
  },
  {
    "Code": "JNF037050",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Fossils"
  },
  {
    "Code": "JNF051190",
    "Literal": "JUVENILE NONFICTION / Science & Nature / History of Science"
  },
  {
    "Code": "JNF051140",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Physics"
  },
  {
    "Code": "JNF037040",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Trees & Forests"
  },
  {
    "Code": "JNF051200",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Weights & Measures"
  },
  {
    "Code": "JNF051150",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Zoology"
  },
  {
    "Code": "JNF052000",
    "Literal": "JUVENILE NONFICTION / Social Science / General"
  },
  {
    "Code": "JNF052010",
    "Literal": "JUVENILE NONFICTION / Social Science / Archaeology"
  },
  {
    "Code": "JNF052020",
    "Literal": "JUVENILE NONFICTION / Social Science / Customs, Traditions, Anthropology"
  },
  {
    "Code": "JNF052030",
    "Literal": "JUVENILE NONFICTION / Social Science / Folklore & Mythology"
  },
  {
    "Code": "JNF043000",
    "Literal": "JUVENILE NONFICTION / Social Science / Politics & Government"
  },
  {
    "Code": "JNF044000",
    "Literal": "JUVENILE NONFICTION / Social Science / Psychology"
  },
  {
    "Code": "JNF052040",
    "Literal": "JUVENILE NONFICTION / Social Science / Sociology"
  },
  {
    "Code": "JNF053000",
    "Literal": "JUVENILE NONFICTION / Social Topics / General (see also headings under Family)"
  },
  {
    "Code": "JNF053010",
    "Literal": "JUVENILE NONFICTION / Social Topics / Adolescence"
  },
  {
    "Code": "JNF053220",
    "Literal": "JUVENILE NONFICTION / Social Topics / Bullying"
  },
  {
    "Code": "JNF053270",
    "Literal": "JUVENILE NONFICTION / Social Topics / Civil & Human Rights"
  },
  {
    "Code": "JNF053020",
    "Literal": "JUVENILE NONFICTION / Social Topics / Dating & Relationships"
  },
  {
    "Code": "JNF053030",
    "Literal": "JUVENILE NONFICTION / Social Topics / Death, Grief, Bereavement"
  },
  {
    "Code": "JNF053230",
    "Literal": "JUVENILE NONFICTION / Social Topics / Depression & Mental Illness"
  },
  {
    "Code": "JNF053040",
    "Literal": "JUVENILE NONFICTION / Social Topics / Drugs, Alcohol, Substance Abuse"
  },
  {
    "Code": "JNF053240",
    "Literal": "JUVENILE NONFICTION / Social Topics / Emigration & Immigration"
  },
  {
    "Code": "JNF053050",
    "Literal": "JUVENILE NONFICTION / Social Topics / Emotions & Feelings"
  },
  {
    "Code": "JNF053060",
    "Literal": "JUVENILE NONFICTION / Social Topics / Friendship"
  },
  {
    "Code": "JNF053090",
    "Literal": "JUVENILE NONFICTION / Social Topics / Manners & Etiquette"
  },
  {
    "Code": "JNF053100",
    "Literal": "JUVENILE NONFICTION / Social Topics / New Experience"
  },
  {
    "Code": "JNF053110",
    "Literal": "JUVENILE NONFICTION / Social Topics / Peer Pressure"
  },
  {
    "Code": "JNF053120",
    "Literal": "JUVENILE NONFICTION / Social Topics / Physical & Emotional Abuse (see also Social Topics / Sexual Abuse)"
  },
  {
    "Code": "JNF053070",
    "Literal": "JUVENILE NONFICTION / Social Topics / Poverty & Homelessness"
  },
  {
    "Code": "JNF053140",
    "Literal": "JUVENILE NONFICTION / Social Topics / Prejudice & Racism"
  },
  {
    "Code": "JNF053160",
    "Literal": "JUVENILE NONFICTION / Social Topics / Self-Esteem & Self-Reliance"
  },
  {
    "Code": "JNF053170",
    "Literal": "JUVENILE NONFICTION / Social Topics / Sexual Abuse"
  },
  {
    "Code": "JNF053200",
    "Literal": "JUVENILE NONFICTION / Social Topics / Values & Virtues"
  },
  {
    "Code": "JNF053210",
    "Literal": "JUVENILE NONFICTION / Social Topics / Violence"
  },
  {
    "Code": "JNF068000",
    "Literal": "JUVENILE NONFICTION / Spies & Spying"
  },
  {
    "Code": "JNF054000",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / General"
  },
  {
    "Code": "JNF054010",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Baseball & Softball"
  },
  {
    "Code": "JNF054020",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Basketball"
  },
  {
    "Code": "JNF054030",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Camping & Outdoor Activities"
  },
  {
    "Code": "JNF054240",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Cheerleading"
  },
  {
    "Code": "JNF054040",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Cycling"
  },
  {
    "Code": "JNF054170",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Equestrian"
  },
  {
    "Code": "JNF054180",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Extreme Sports"
  },
  {
    "Code": "JNF054050",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Football"
  },
  {
    "Code": "JNF054230",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Golf"
  },
  {
    "Code": "JNF054060",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Gymnastics"
  },
  {
    "Code": "JNF054070",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Hockey"
  },
  {
    "Code": "JNF054190",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Ice Skating"
  },
  {
    "Code": "JNF054080",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Martial Arts"
  },
  {
    "Code": "JNF054100",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Motor Sports"
  },
  {
    "Code": "JNF054110",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Olympics & Paralympics"
  },
  {
    "Code": "JNF054120",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Racket Sports"
  },
  {
    "Code": "JNF054200",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Roller & In-Line Skating"
  },
  {
    "Code": "JNF054210",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Skateboarding"
  },
  {
    "Code": "JNF054130",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Soccer"
  },
  {
    "Code": "JNF054140",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Track & Field"
  },
  {
    "Code": "JNF054150",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Water Sports"
  },
  {
    "Code": "JNF054160",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Winter Sports"
  },
  {
    "Code": "JNF054220",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Wrestling"
  },
  {
    "Code": "JNF055000",
    "Literal": "JUVENILE NONFICTION / Study Aids / General"
  },
  {
    "Code": "JNF055010",
    "Literal": "JUVENILE NONFICTION / Study Aids / Book Notes (see also STUDY AIDS / Book Notes)"
  },
  {
    "Code": "JNF055030",
    "Literal": "JUVENILE NONFICTION / Study Aids / Test Preparation"
  },
  {
    "Code": "JNF061000",
    "Literal": "JUVENILE NONFICTION / Technology / General"
  },
  {
    "Code": "JNF051010",
    "Literal": "JUVENILE NONFICTION / Technology / Aeronautics, Astronautics & Space Science"
  },
  {
    "Code": "JNF051020",
    "Literal": "JUVENILE NONFICTION / Technology / Agriculture"
  },
  {
    "Code": "JNF051090",
    "Literal": "JUVENILE NONFICTION / Technology / Electricity & Electronics"
  },
  {
    "Code": "JNF051120",
    "Literal": "JUVENILE NONFICTION / Technology / How Things Work-Are Made"
  },
  {
    "Code": "JNF061010",
    "Literal": "JUVENILE NONFICTION / Technology / Inventions"
  },
  {
    "Code": "JNF051130",
    "Literal": "JUVENILE NONFICTION / Technology / Machinery & Tools"
  },
  {
    "Code": "JNF061020",
    "Literal": "JUVENILE NONFICTION / Technology / Robotics"
  },
  {
    "Code": "JNF056000",
    "Literal": "JUVENILE NONFICTION / Toys, Dolls & Puppets"
  },
  {
    "Code": "JNF057000",
    "Literal": "JUVENILE NONFICTION / Transportation / General"
  },
  {
    "Code": "JNF057010",
    "Literal": "JUVENILE NONFICTION / Transportation / Aviation"
  },
  {
    "Code": "JNF057020",
    "Literal": "JUVENILE NONFICTION / Transportation / Boats, Ships & Underwater Craft"
  },
  {
    "Code": "JNF057030",
    "Literal": "JUVENILE NONFICTION / Transportation / Cars & Trucks"
  },
  {
    "Code": "JNF057040",
    "Literal": "JUVENILE NONFICTION / Transportation / Motorcycles"
  },
  {
    "Code": "JNF057050",
    "Literal": "JUVENILE NONFICTION / Transportation / Railroads & Trains"
  },
  {
    "Code": "JNF058000",
    "Literal": "JUVENILE NONFICTION / Travel"
  },
  {
    "Code": "JNF073000",
    "Literal": "JUVENILE NONFICTION / Volunteering"
  },
  {
    "Code": "LAN000000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / General"
  },
  {
    "Code": "LAN001000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Alphabets & Writing Systems"
  },
  {
    "Code": "LAN004000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Communication Studies"
  },
  {
    "Code": "LAN022000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Editing & Proofreading"
  },
  {
    "Code": "LAN006000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Grammar & Punctuation"
  },
  {
    "Code": "LAN007000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Handwriting"
  },
  {
    "Code": "LAN008000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Journalism"
  },
  {
    "Code": "LAN029000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Lexicography"
  },
  {
    "Code": "LAN025000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Library & Information Science / General"
  },
  {
    "Code": "LAN025010",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Library & Information Science / Administration & Management"
  },
  {
    "Code": "LAN025020",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Library & Information Science / Archives & Special Libraries"
  },
  {
    "Code": "LAN025030",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Library & Information Science / Cataloging & Classification"
  },
  {
    "Code": "LAN025040",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Library & Information Science / Collection Development"
  },
  {
    "Code": "LAN025060",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Library & Information Science / Digital & Online Resources"
  },
  {
    "Code": "LAN025050",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Library & Information Science / School Media"
  },
  {
    "Code": "LAN009000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / General"
  },
  {
    "Code": "LAN024000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Etymology"
  },
  {
    "Code": "LAN009010",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Historical & Comparative"
  },
  {
    "Code": "LAN009020",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Morphology"
  },
  {
    "Code": "LAN011000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Phonetics & Phonology"
  },
  {
    "Code": "LAN009030",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Pragmatics"
  },
  {
    "Code": "LAN009040",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Psycholinguistics / General"
  },
  {
    "Code": "LAN009070",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Psycholinguistics / Language Acquisition"
  },
  {
    "Code": "LAN009080",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Psycholinguistics / Neurolinguistics"
  },
  {
    "Code": "LAN016000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Semantics"
  },
  {
    "Code": "LAN009050",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Sociolinguistics"
  },
  {
    "Code": "LAN009060",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Syntax"
  },
  {
    "Code": "LAN010000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Literacy"
  },
  {
    "Code": "LAN030000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Orality"
  },
  {
    "Code": "LAN026000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Public Speaking & Speech Writing"
  },
  {
    "Code": "LAN027000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Publishers & Publishing Industry"
  },
  {
    "Code": "LAN012000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Readers"
  },
  {
    "Code": "LAN013000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Reading Skills"
  },
  {
    "Code": "LAN015000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Rhetoric"
  },
  {
    "Code": "LAN017000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Sign Language"
  },
  {
    "Code": "LAN018000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Speech & Pronunciation"
  },
  {
    "Code": "LAN021000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Spelling & Vocabulary"
  },
  {
    "Code": "LAN020000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Study & Teaching"
  },
  {
    "Code": "LAN028000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Style Manuals"
  },
  {
    "Code": "LAN023000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Translating & Interpreting"
  },
  {
    "Code": "LAN005000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / General"
  },
  {
    "Code": "LAN005010",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Academic & Scholarly"
  },
  {
    "Code": "LAN002000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Authorship"
  },
  {
    "Code": "LAN005020",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Business Aspects"
  },
  {
    "Code": "LAN005030",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Children's & Young Adult"
  },
  {
    "Code": "LAN005040",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Composition"
  },
  {
    "Code": "LAN005050",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Fiction Writing"
  },
  {
    "Code": "LAN005060",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Nonfiction (incl. Memoirs)"
  },
  {
    "Code": "LAN005070",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Poetry"
  },
  {
    "Code": "LCO000000",
    "Literal": "LITERARY COLLECTIONS / General"
  },
  {
    "Code": "LCO001000",
    "Literal": "LITERARY COLLECTIONS / African"
  },
  {
    "Code": "LCO002000",
    "Literal": "LITERARY COLLECTIONS / American / General"
  },
  {
    "Code": "LCO002010",
    "Literal": "LITERARY COLLECTIONS / American / African American & Black"
  },
  {
    "Code": "LCO002020",
    "Literal": "LITERARY COLLECTIONS / American / Asian American & Pacific Islander"
  },
  {
    "Code": "LCO002030",
    "Literal": "LITERARY COLLECTIONS / American / Hispanic & Latino"
  },
  {
    "Code": "LCO003000",
    "Literal": "LITERARY COLLECTIONS / Ancient & Classical"
  },
  {
    "Code": "LCO004000",
    "Literal": "LITERARY COLLECTIONS / Asian / General"
  },
  {
    "Code": "LCO004010",
    "Literal": "LITERARY COLLECTIONS / Asian / Chinese"
  },
  {
    "Code": "LCO004020",
    "Literal": "LITERARY COLLECTIONS / Asian / Indic"
  },
  {
    "Code": "LCO004030",
    "Literal": "LITERARY COLLECTIONS / Asian / Japanese"
  },
  {
    "Code": "LCO005000",
    "Literal": "LITERARY COLLECTIONS / Australian & Oceanian"
  },
  {
    "Code": "LCO006000",
    "Literal": "LITERARY COLLECTIONS / Canadian"
  },
  {
    "Code": "LCO007000",
    "Literal": "LITERARY COLLECTIONS / Caribbean & Latin American"
  },
  {
    "Code": "LCO015000",
    "Literal": "LITERARY COLLECTIONS / Diaries & Journals"
  },
  {
    "Code": "LCO008000",
    "Literal": "LITERARY COLLECTIONS / European / General"
  },
  {
    "Code": "LCO008010",
    "Literal": "LITERARY COLLECTIONS / European / Eastern (see also Russian & Soviet)"
  },
  {
    "Code": "LCO009000",
    "Literal": "LITERARY COLLECTIONS / European / English, Irish, Scottish, Welsh"
  },
  {
    "Code": "LCO008020",
    "Literal": "LITERARY COLLECTIONS / European / French"
  },
  {
    "Code": "LCO008030",
    "Literal": "LITERARY COLLECTIONS / European / German"
  },
  {
    "Code": "LCO008040",
    "Literal": "LITERARY COLLECTIONS / European / Italian"
  },
  {
    "Code": "LCO008050",
    "Literal": "LITERARY COLLECTIONS / European / Scandinavian"
  },
  {
    "Code": "LCO008060",
    "Literal": "LITERARY COLLECTIONS / European / Spanish & Portuguese"
  },
  {
    "Code": "LCO010000",
    "Literal": "LITERARY COLLECTIONS / Essays"
  },
  {
    "Code": "LCO013000",
    "Literal": "LITERARY COLLECTIONS / Indigenous"
  },
  {
    "Code": "LCO023000",
    "Literal": "LITERARY COLLECTIONS / Indigenous Peoples of Turtle Island"
  },
  {
    "Code": "LCO020000",
    "Literal": "LITERARY COLLECTIONS / Interviews"
  },
  {
    "Code": "LCO011000",
    "Literal": "LITERARY COLLECTIONS / Letters"
  },
  {
    "Code": "LCO016000",
    "Literal": "LITERARY COLLECTIONS / LGBTQ+"
  },
  {
    "Code": "LCO017000",
    "Literal": "LITERARY COLLECTIONS / Medieval"
  },
  {
    "Code": "LCO012000",
    "Literal": "LITERARY COLLECTIONS / Middle Eastern"
  },
  {
    "Code": "LCO024000",
    "Literal": "LITERARY COLLECTIONS / Native American"
  },
  {
    "Code": "LCO021000",
    "Literal": "LITERARY COLLECTIONS / Prisoners' Writings"
  },
  {
    "Code": "LCO014000",
    "Literal": "LITERARY COLLECTIONS / Russian & Soviet"
  },
  {
    "Code": "LCO018000",
    "Literal": "LITERARY COLLECTIONS / Speeches"
  },
  {
    "Code": "LCO022000",
    "Literal": "LITERARY COLLECTIONS / Subjects & Themes / General"
  },
  {
    "Code": "LCO022010",
    "Literal": "LITERARY COLLECTIONS / Subjects & Themes / Animals & Nature"
  },
  {
    "Code": "LCO022020",
    "Literal": "LITERARY COLLECTIONS / Subjects & Themes / Love & Erotica"
  },
  {
    "Code": "LCO022030",
    "Literal": "LITERARY COLLECTIONS / Subjects & Themes / Places"
  },
  {
    "Code": "LCO022040",
    "Literal": "LITERARY COLLECTIONS / Subjects & Themes / Religious & Inspirational"
  },
  {
    "Code": "LCO022050",
    "Literal": "LITERARY COLLECTIONS / Subjects & Themes / War"
  },
  {
    "Code": "LCO019000",
    "Literal": "LITERARY COLLECTIONS / Women Authors"
  },
  {
    "Code": "LIT000000",
    "Literal": "LITERARY CRITICISM / General"
  },
  {
    "Code": "LIT004010",
    "Literal": "LITERARY CRITICISM / African"
  },
  {
    "Code": "LIT004020",
    "Literal": "LITERARY CRITICISM / American / General"
  },
  {
    "Code": "LIT004040",
    "Literal": "LITERARY CRITICISM / American / African American & Black"
  },
  {
    "Code": "LIT004030",
    "Literal": "LITERARY CRITICISM / American / Asian American & Pacific Islander"
  },
  {
    "Code": "LIT004050",
    "Literal": "LITERARY CRITICISM / American / Hispanic & Latino"
  },
  {
    "Code": "LIT023000",
    "Literal": "LITERARY CRITICISM / American / Regional"
  },
  {
    "Code": "LIT004190",
    "Literal": "LITERARY CRITICISM / Ancient & Classical"
  },
  {
    "Code": "LIT008000",
    "Literal": "LITERARY CRITICISM / Asian / General"
  },
  {
    "Code": "LIT008010",
    "Literal": "LITERARY CRITICISM / Asian / Chinese"
  },
  {
    "Code": "LIT008020",
    "Literal": "LITERARY CRITICISM / Asian / Indic"
  },
  {
    "Code": "LIT008030",
    "Literal": "LITERARY CRITICISM / Asian / Japanese"
  },
  {
    "Code": "LIT004070",
    "Literal": "LITERARY CRITICISM / Australian & Oceanian"
  },
  {
    "Code": "LIT007000",
    "Literal": "LITERARY CRITICISM / Books & Reading"
  },
  {
    "Code": "LIT004080",
    "Literal": "LITERARY CRITICISM / Canadian"
  },
  {
    "Code": "LIT004100",
    "Literal": "LITERARY CRITICISM / Caribbean & Latin American"
  },
  {
    "Code": "LIT009000",
    "Literal": "LITERARY CRITICISM / Children's & Young Adult Literature"
  },
  {
    "Code": "LIT017000",
    "Literal": "LITERARY CRITICISM / Comics & Graphic Novels"
  },
  {
    "Code": "LIT020000",
    "Literal": "LITERARY CRITICISM / Comparative Literature"
  },
  {
    "Code": "LIT013000",
    "Literal": "LITERARY CRITICISM / Drama"
  },
  {
    "Code": "LIT004130",
    "Literal": "LITERARY CRITICISM / European / General"
  },
  {
    "Code": "LIT004110",
    "Literal": "LITERARY CRITICISM / European / Eastern (see also Russian & Soviet)"
  },
  {
    "Code": "LIT004120",
    "Literal": "LITERARY CRITICISM / European / English, Irish, Scottish, Welsh"
  },
  {
    "Code": "LIT004150",
    "Literal": "LITERARY CRITICISM / European / French"
  },
  {
    "Code": "LIT004170",
    "Literal": "LITERARY CRITICISM / European / German"
  },
  {
    "Code": "LIT004200",
    "Literal": "LITERARY CRITICISM / European / Italian"
  },
  {
    "Code": "LIT004250",
    "Literal": "LITERARY CRITICISM / European / Scandinavian"
  },
  {
    "Code": "LIT004280",
    "Literal": "LITERARY CRITICISM / European / Spanish & Portuguese"
  },
  {
    "Code": "LIT022000",
    "Literal": "LITERARY CRITICISM / Fairy Tales, Folk Tales, Legends & Mythology"
  },
  {
    "Code": "LIT003000",
    "Literal": "LITERARY CRITICISM / Feminist"
  },
  {
    "Code": "LIT004180",
    "Literal": "LITERARY CRITICISM / Gothic & Romance"
  },
  {
    "Code": "LIT021000",
    "Literal": "LITERARY CRITICISM / Horror & Supernatural"
  },
  {
    "Code": "LIT016000",
    "Literal": "LITERARY CRITICISM / Humor"
  },
  {
    "Code": "LIT004060",
    "Literal": "LITERARY CRITICISM / Indigenous"
  },
  {
    "Code": "LIT027000",
    "Literal": "LITERARY CRITICISM / Indigenous Peoples of Turtle Island"
  },
  {
    "Code": "LIT004210",
    "Literal": "LITERARY CRITICISM / Jewish"
  },
  {
    "Code": "LIT004160",
    "Literal": "LITERARY CRITICISM / LGBTQ+"
  },
  {
    "Code": "LIT011000",
    "Literal": "LITERARY CRITICISM / Medieval"
  },
  {
    "Code": "LIT004220",
    "Literal": "LITERARY CRITICISM / Middle Eastern"
  },
  {
    "Code": "LIT024000",
    "Literal": "LITERARY CRITICISM / Modern / General"
  },
  {
    "Code": "LIT024010",
    "Literal": "LITERARY CRITICISM / Modern / 16th Century"
  },
  {
    "Code": "LIT024020",
    "Literal": "LITERARY CRITICISM / Modern / 17th Century"
  },
  {
    "Code": "LIT024030",
    "Literal": "LITERARY CRITICISM / Modern / 18th Century"
  },
  {
    "Code": "LIT024040",
    "Literal": "LITERARY CRITICISM / Modern / 19th Century"
  },
  {
    "Code": "LIT024050",
    "Literal": "LITERARY CRITICISM / Modern / 20th Century"
  },
  {
    "Code": "LIT024060",
    "Literal": "LITERARY CRITICISM / Modern / 21st Century"
  },
  {
    "Code": "LIT004230",
    "Literal": "LITERARY CRITICISM / Mystery & Detective"
  },
  {
    "Code": "LIT028000",
    "Literal": "LITERARY CRITICISM / Native American"
  },
  {
    "Code": "LIT026000",
    "Literal": "LITERARY CRITICISM / Novel as Form"
  },
  {
    "Code": "LIT014000",
    "Literal": "LITERARY CRITICISM / Poetry"
  },
  {
    "Code": "LIT012000",
    "Literal": "LITERARY CRITICISM / Reference"
  },
  {
    "Code": "LIT019000",
    "Literal": "LITERARY CRITICISM / Renaissance"
  },
  {
    "Code": "LIT004240",
    "Literal": "LITERARY CRITICISM / Russian & Soviet"
  },
  {
    "Code": "LIT004260",
    "Literal": "LITERARY CRITICISM / Science Fiction & Fantasy"
  },
  {
    "Code": "LIT006000",
    "Literal": "LITERARY CRITICISM / Semiotics & Theory"
  },
  {
    "Code": "LIT015000",
    "Literal": "LITERARY CRITICISM / Shakespeare"
  },
  {
    "Code": "LIT018000",
    "Literal": "LITERARY CRITICISM / Short Stories"
  },
  {
    "Code": "LIT025000",
    "Literal": "LITERARY CRITICISM / Subjects & Themes / General"
  },
  {
    "Code": "LIT025060",
    "Literal": "LITERARY CRITICISM / Subjects & Themes / Culture, Race & Ethnicity"
  },
  {
    "Code": "LIT025070",
    "Literal": "LITERARY CRITICISM / Subjects & Themes / Gender Identity"
  },
  {
    "Code": "LIT025010",
    "Literal": "LITERARY CRITICISM / Subjects & Themes / Historical Events"
  },
  {
    "Code": "LIT025080",
    "Literal": "LITERARY CRITICISM / Subjects & Themes / Love & Erotica"
  },
  {
    "Code": "LIT025020",
    "Literal": "LITERARY CRITICISM / Subjects & Themes / Nature"
  },
  {
    "Code": "LIT025030",
    "Literal": "LITERARY CRITICISM / Subjects & Themes / Politics"
  },
  {
    "Code": "LIT025040",
    "Literal": "LITERARY CRITICISM / Subjects & Themes / Religion"
  },
  {
    "Code": "LIT025050",
    "Literal": "LITERARY CRITICISM / Subjects & Themes / Women"
  },
  {
    "Code": "LIT004290",
    "Literal": "LITERARY CRITICISM / Women Authors"
  },
  {
    "Code": "MUS000000",
    "Literal": "MUSIC / General"
  },
  {
    "Code": "MUS004000",
    "Literal": "MUSIC / Business Aspects"
  },
  {
    "Code": "MUS012000",
    "Literal": "MUSIC / Discography & Buyer's Guides"
  },
  {
    "Code": "MUS055000",
    "Literal": "MUSIC / Essays"
  },
  {
    "Code": "MUS014000",
    "Literal": "MUSIC / Ethnic"
  },
  {
    "Code": "MUS015000",
    "Literal": "MUSIC / Ethnomusicology"
  },
  {
    "Code": "MUS049000",
    "Literal": "MUSIC / Genres & Styles / General"
  },
  {
    "Code": "MUS002000",
    "Literal": "MUSIC / Genres & Styles / Ballet"
  },
  {
    "Code": "MUS053000",
    "Literal": "MUSIC / Genres & Styles / Big Band & Swing"
  },
  {
    "Code": "MUS003000",
    "Literal": "MUSIC / Genres & Styles / Blues"
  },
  {
    "Code": "MUS005000",
    "Literal": "MUSIC / Genres & Styles / Chamber"
  },
  {
    "Code": "MUS026000",
    "Literal": "MUSIC / Genres & Styles / Children's"
  },
  {
    "Code": "MUS051000",
    "Literal": "MUSIC / Genres & Styles / Choral"
  },
  {
    "Code": "MUS006000",
    "Literal": "MUSIC / Genres & Styles / Classical"
  },
  {
    "Code": "MUS010000",
    "Literal": "MUSIC / Genres & Styles / Country & Bluegrass"
  },
  {
    "Code": "MUS011000",
    "Literal": "MUSIC / Genres & Styles / Dance"
  },
  {
    "Code": "MUS013000",
    "Literal": "MUSIC / Genres & Styles / Electronic"
  },
  {
    "Code": "MUS017000",
    "Literal": "MUSIC / Genres & Styles / Folk & Traditional"
  },
  {
    "Code": "MUS019000",
    "Literal": "MUSIC / Genres & Styles / Heavy Metal"
  },
  {
    "Code": "MUS056000",
    "Literal": "MUSIC / Genres & Styles / Indigenous"
  },
  {
    "Code": "MUS024000",
    "Literal": "MUSIC / Genres & Styles / International"
  },
  {
    "Code": "MUS025000",
    "Literal": "MUSIC / Genres & Styles / Jazz"
  },
  {
    "Code": "MUS036000",
    "Literal": "MUSIC / Genres & Styles / Latin"
  },
  {
    "Code": "MUS045000",
    "Literal": "MUSIC / Genres & Styles / Military & Marches"
  },
  {
    "Code": "MUS046000",
    "Literal": "MUSIC / Genres & Styles / Musicals"
  },
  {
    "Code": "MUS027000",
    "Literal": "MUSIC / Genres & Styles / New Age"
  },
  {
    "Code": "MUS028000",
    "Literal": "MUSIC / Genres & Styles / Opera"
  },
  {
    "Code": "MUS029000",
    "Literal": "MUSIC / Genres & Styles / Pop Vocal"
  },
  {
    "Code": "MUS030000",
    "Literal": "MUSIC / Genres & Styles / Punk"
  },
  {
    "Code": "MUS031000",
    "Literal": "MUSIC / Genres & Styles / Rap & Hip Hop"
  },
  {
    "Code": "MUS047000",
    "Literal": "MUSIC / Genres & Styles / Reggae"
  },
  {
    "Code": "MUS035000",
    "Literal": "MUSIC / Genres & Styles / Rock"
  },
  {
    "Code": "MUS039000",
    "Literal": "MUSIC / Genres & Styles / Soul & R 'n B"
  },
  {
    "Code": "MUS020000",
    "Literal": "MUSIC / History & Criticism"
  },
  {
    "Code": "MUS050000",
    "Literal": "MUSIC / Individual Composer & Musician"
  },
  {
    "Code": "MUS022000",
    "Literal": "MUSIC / Instruction & Study / General"
  },
  {
    "Code": "MUS001000",
    "Literal": "MUSIC / Instruction & Study / Appreciation"
  },
  {
    "Code": "MUS007000",
    "Literal": "MUSIC / Instruction & Study / Composition"
  },
  {
    "Code": "MUS008000",
    "Literal": "MUSIC / Instruction & Study / Conducting"
  },
  {
    "Code": "MUS016000",
    "Literal": "MUSIC / Instruction & Study / Exercises"
  },
  {
    "Code": "MUS038000",
    "Literal": "MUSIC / Instruction & Study / Songwriting"
  },
  {
    "Code": "MUS040000",
    "Literal": "MUSIC / Instruction & Study / Techniques"
  },
  {
    "Code": "MUS041000",
    "Literal": "MUSIC / Instruction & Study / Theory"
  },
  {
    "Code": "MUS042000",
    "Literal": "MUSIC / Instruction & Study / Voice"
  },
  {
    "Code": "MUS052000",
    "Literal": "MUSIC / Lyrics"
  },
  {
    "Code": "MUS023000",
    "Literal": "MUSIC / Musical Instruments / General"
  },
  {
    "Code": "MUS023010",
    "Literal": "MUSIC / Musical Instruments / Brass"
  },
  {
    "Code": "MUS023060",
    "Literal": "MUSIC / Musical Instruments / Guitar"
  },
  {
    "Code": "MUS023020",
    "Literal": "MUSIC / Musical Instruments / Percussion"
  },
  {
    "Code": "MUS023030",
    "Literal": "MUSIC / Musical Instruments / Piano & Keyboard"
  },
  {
    "Code": "MUS023040",
    "Literal": "MUSIC / Musical Instruments / Strings"
  },
  {
    "Code": "MUS023050",
    "Literal": "MUSIC / Musical Instruments / Woodwinds"
  },
  {
    "Code": "MUS054000",
    "Literal": "MUSIC / Philosophy & Social Aspects"
  },
  {
    "Code": "MUS037000",
    "Literal": "MUSIC / Printed Music / General"
  },
  {
    "Code": "MUS037010",
    "Literal": "MUSIC / Printed Music / Artist Specific"
  },
  {
    "Code": "MUS037020",
    "Literal": "MUSIC / Printed Music / Band & Orchestra"
  },
  {
    "Code": "MUS037120",
    "Literal": "MUSIC / Printed Music / Brass"
  },
  {
    "Code": "MUS037030",
    "Literal": "MUSIC / Printed Music / Choral"
  },
  {
    "Code": "MUS037040",
    "Literal": "MUSIC / Printed Music / Guitar & Fretted Instruments"
  },
  {
    "Code": "MUS037050",
    "Literal": "MUSIC / Printed Music / Mixed Collections"
  },
  {
    "Code": "MUS037060",
    "Literal": "MUSIC / Printed Music / Musicals, Film & TV"
  },
  {
    "Code": "MUS037070",
    "Literal": "MUSIC / Printed Music / Opera & Classical Scores"
  },
  {
    "Code": "MUS037080",
    "Literal": "MUSIC / Printed Music / Percussion"
  },
  {
    "Code": "MUS037090",
    "Literal": "MUSIC / Printed Music / Piano & Keyboard Repertoire"
  },
  {
    "Code": "MUS037100",
    "Literal": "MUSIC / Printed Music / Piano-Vocal-Guitar"
  },
  {
    "Code": "MUS037130",
    "Literal": "MUSIC / Printed Music / Strings"
  },
  {
    "Code": "MUS037110",
    "Literal": "MUSIC / Printed Music / Vocal"
  },
  {
    "Code": "MUS037140",
    "Literal": "MUSIC / Printed Music / Woodwinds"
  },
  {
    "Code": "MUS032000",
    "Literal": "MUSIC / Recording & Reproduction"
  },
  {
    "Code": "MUS033000",
    "Literal": "MUSIC / Reference"
  },
  {
    "Code": "MUS048000",
    "Literal": "MUSIC / Religious / General"
  },
  {
    "Code": "MUS048010",
    "Literal": "MUSIC / Religious / Christian"
  },
  {
    "Code": "MUS009000",
    "Literal": "MUSIC / Religious / Contemporary Christian"
  },
  {
    "Code": "MUS018000",
    "Literal": "MUSIC / Religious / Gospel"
  },
  {
    "Code": "MUS021000",
    "Literal": "MUSIC / Religious / Hymns"
  },
  {
    "Code": "MUS048020",
    "Literal": "MUSIC / Religious / Jewish"
  },
  {
    "Code": "MUS048030",
    "Literal": "MUSIC / Religious / Muslim"
  },
  {
    "Code": "NAT000000",
    "Literal": "NATURE / General"
  },
  {
    "Code": "NAT039000",
    "Literal": "NATURE / Animal Rights"
  },
  {
    "Code": "NAT001000",
    "Literal": "NATURE / Animals / General"
  },
  {
    "Code": "NAT003000",
    "Literal": "NATURE / Animals / Bears"
  },
  {
    "Code": "NAT042000",
    "Literal": "NATURE / Animals / Big Cats"
  },
  {
    "Code": "NAT043000",
    "Literal": "NATURE / Animals / Birds"
  },
  {
    "Code": "NAT005000",
    "Literal": "NATURE / Animals / Butterflies & Moths"
  },
  {
    "Code": "NAT007000",
    "Literal": "NATURE / Animals / Dinosaurs & Prehistoric Creatures"
  },
  {
    "Code": "NAT012000",
    "Literal": "NATURE / Animals / Fish"
  },
  {
    "Code": "NAT016000",
    "Literal": "NATURE / Animals / Horses"
  },
  {
    "Code": "NAT017000",
    "Literal": "NATURE / Animals / Insects & Spiders"
  },
  {
    "Code": "NAT019000",
    "Literal": "NATURE / Animals / Mammals"
  },
  {
    "Code": "NAT020000",
    "Literal": "NATURE / Animals / Marine Life"
  },
  {
    "Code": "NAT002000",
    "Literal": "NATURE / Animals / Primates"
  },
  {
    "Code": "NAT028000",
    "Literal": "NATURE / Animals / Reptiles & Amphibians"
  },
  {
    "Code": "NAT037000",
    "Literal": "NATURE / Animals / Wildlife"
  },
  {
    "Code": "NAT044000",
    "Literal": "NATURE / Animals / Wolves"
  },
  {
    "Code": "NAT004000",
    "Literal": "NATURE / Birdwatching Guides"
  },
  {
    "Code": "NAT050000",
    "Literal": "NATURE / Cultural Botany"
  },
  {
    "Code": "NAT009000",
    "Literal": "NATURE / Earthquakes & Volcanoes"
  },
  {
    "Code": "NAT010000",
    "Literal": "NATURE / Ecology"
  },
  {
    "Code": "NAT045000",
    "Literal": "NATURE / Ecosystems & Habitats / General"
  },
  {
    "Code": "NAT045050",
    "Literal": "NATURE / Ecosystems & Habitats / Coastal Regions & Shorelines"
  },
  {
    "Code": "NAT045010",
    "Literal": "NATURE / Ecosystems & Habitats / Deserts"
  },
  {
    "Code": "NAT014000",
    "Literal": "NATURE / Ecosystems & Habitats / Forests & Rainforests"
  },
  {
    "Code": "NAT018000",
    "Literal": "NATURE / Ecosystems & Habitats / Lakes, Ponds & Swamps"
  },
  {
    "Code": "NAT041000",
    "Literal": "NATURE / Ecosystems & Habitats / Mountains"
  },
  {
    "Code": "NAT025000",
    "Literal": "NATURE / Ecosystems & Habitats / Oceans & Seas"
  },
  {
    "Code": "NAT045020",
    "Literal": "NATURE / Ecosystems & Habitats / Plains & Prairies"
  },
  {
    "Code": "NAT045030",
    "Literal": "NATURE / Ecosystems & Habitats / Polar Regions"
  },
  {
    "Code": "NAT029000",
    "Literal": "NATURE / Ecosystems & Habitats / Rivers"
  },
  {
    "Code": "NAT045040",
    "Literal": "NATURE / Ecosystems & Habitats / Wilderness"
  },
  {
    "Code": "NAT046000",
    "Literal": "NATURE / Endangered Species"
  },
  {
    "Code": "NAT011000",
    "Literal": "NATURE / Environmental Conservation & Protection"
  },
  {
    "Code": "NAT024000",
    "Literal": "NATURE / Essays"
  },
  {
    "Code": "NAT051000",
    "Literal": "NATURE / Foraging"
  },
  {
    "Code": "NAT015000",
    "Literal": "NATURE / Fossils"
  },
  {
    "Code": "NAT022000",
    "Literal": "NATURE / Fungi & Mushrooms"
  },
  {
    "Code": "NAT052000",
    "Literal": "NATURE / Indigenous Stewardship"
  },
  {
    "Code": "NAT023000",
    "Literal": "NATURE / Natural Disasters"
  },
  {
    "Code": "NAT038000",
    "Literal": "NATURE / Natural Resources"
  },
  {
    "Code": "NAT026000",
    "Literal": "NATURE / Plants / General"
  },
  {
    "Code": "NAT047000",
    "Literal": "NATURE / Plants / Aquatic"
  },
  {
    "Code": "NAT048000",
    "Literal": "NATURE / Plants / Cacti & Succulents"
  },
  {
    "Code": "NAT013000",
    "Literal": "NATURE / Plants / Flowers"
  },
  {
    "Code": "NAT034000",
    "Literal": "NATURE / Plants / Trees"
  },
  {
    "Code": "NAT027000",
    "Literal": "NATURE / Reference"
  },
  {
    "Code": "NAT049000",
    "Literal": "NATURE / Regional"
  },
  {
    "Code": "NAT030000",
    "Literal": "NATURE / Rocks & Minerals"
  },
  {
    "Code": "NAT031000",
    "Literal": "NATURE / Seashells"
  },
  {
    "Code": "NAT032000",
    "Literal": "NATURE / Seasons"
  },
  {
    "Code": "NAT033000",
    "Literal": "NATURE / Sky Observation"
  },
  {
    "Code": "NAT036000",
    "Literal": "NATURE / Weather"
  },
  {
    "Code": "PER000000",
    "Literal": "PERFORMING ARTS / General"
  },
  {
    "Code": "PER001000",
    "Literal": "PERFORMING ARTS / Acting & Auditioning"
  },
  {
    "Code": "PER017000",
    "Literal": "PERFORMING ARTS / Animation (see also Film / Genres / Animated)"
  },
  {
    "Code": "PER014000",
    "Literal": "PERFORMING ARTS / Business Aspects"
  },
  {
    "Code": "PER002000",
    "Literal": "PERFORMING ARTS / Circus"
  },
  {
    "Code": "PER015000",
    "Literal": "PERFORMING ARTS / Comedy"
  },
  {
    "Code": "PER003000",
    "Literal": "PERFORMING ARTS / Dance / General"
  },
  {
    "Code": "PER003090",
    "Literal": "PERFORMING ARTS / Dance / Ballroom"
  },
  {
    "Code": "PER003050",
    "Literal": "PERFORMING ARTS / Dance / Choreography & Dance Notation"
  },
  {
    "Code": "PER003010",
    "Literal": "PERFORMING ARTS / Dance / Classical & Ballet"
  },
  {
    "Code": "PER003020",
    "Literal": "PERFORMING ARTS / Dance / Folk"
  },
  {
    "Code": "PER003100",
    "Literal": "PERFORMING ARTS / Dance / History & Criticism"
  },
  {
    "Code": "PER003030",
    "Literal": "PERFORMING ARTS / Dance / Jazz"
  },
  {
    "Code": "PER003040",
    "Literal": "PERFORMING ARTS / Dance / Modern"
  },
  {
    "Code": "PER003060",
    "Literal": "PERFORMING ARTS / Dance / Popular"
  },
  {
    "Code": "PER003070",
    "Literal": "PERFORMING ARTS / Dance / Reference"
  },
  {
    "Code": "PER021000",
    "Literal": "PERFORMING ARTS / Dance / Regional & Cultural"
  },
  {
    "Code": "PER003080",
    "Literal": "PERFORMING ARTS / Dance / Tap"
  },
  {
    "Code": "PER024000",
    "Literal": "PERFORMING ARTS / Drag"
  },
  {
    "Code": "PER004000",
    "Literal": "PERFORMING ARTS / Film / General"
  },
  {
    "Code": "PER004010",
    "Literal": "PERFORMING ARTS / Film / Direction & Production"
  },
  {
    "Code": "PER004060",
    "Literal": "PERFORMING ARTS / Film / Genres / General"
  },
  {
    "Code": "PER004070",
    "Literal": "PERFORMING ARTS / Film / Genres / Action & Adventure"
  },
  {
    "Code": "PER004080",
    "Literal": "PERFORMING ARTS / Film / Genres / Animated"
  },
  {
    "Code": "PER004090",
    "Literal": "PERFORMING ARTS / Film / Genres / Comedy"
  },
  {
    "Code": "PER004100",
    "Literal": "PERFORMING ARTS / Film / Genres / Crime"
  },
  {
    "Code": "PER004110",
    "Literal": "PERFORMING ARTS / Film / Genres / Documentary"
  },
  {
    "Code": "PER004120",
    "Literal": "PERFORMING ARTS / Film / Genres / Historical"
  },
  {
    "Code": "PER004130",
    "Literal": "PERFORMING ARTS / Film / Genres / Horror"
  },
  {
    "Code": "PER004170",
    "Literal": "PERFORMING ARTS / Film / Genres / Musicals"
  },
  {
    "Code": "PER004140",
    "Literal": "PERFORMING ARTS / Film / Genres / Science Fiction & Fantasy"
  },
  {
    "Code": "PER004180",
    "Literal": "PERFORMING ARTS / Film / Genres / Superheroes"
  },
  {
    "Code": "PER004150",
    "Literal": "PERFORMING ARTS / Film / Genres / Westerns"
  },
  {
    "Code": "PER004020",
    "Literal": "PERFORMING ARTS / Film / Guides & Reviews"
  },
  {
    "Code": "PER004030",
    "Literal": "PERFORMING ARTS / Film / History & Criticism"
  },
  {
    "Code": "PER004040",
    "Literal": "PERFORMING ARTS / Film / Reference"
  },
  {
    "Code": "PER004160",
    "Literal": "PERFORMING ARTS / Film / Regional & National"
  },
  {
    "Code": "PER004050",
    "Literal": "PERFORMING ARTS / Film / Screenwriting"
  },
  {
    "Code": "PER022000",
    "Literal": "PERFORMING ARTS / Improvisation"
  },
  {
    "Code": "PER018000",
    "Literal": "PERFORMING ARTS / Individual Director (see also BIOGRAPHY & AUTOBIOGRAPHY / Entertainment & Performing Arts)"
  },
  {
    "Code": "PER020000",
    "Literal": "PERFORMING ARTS / Monologues & Scenes"
  },
  {
    "Code": "PER007000",
    "Literal": "PERFORMING ARTS / Puppets & Puppetry"
  },
  {
    "Code": "PER008000",
    "Literal": "PERFORMING ARTS / Radio / General"
  },
  {
    "Code": "PER008010",
    "Literal": "PERFORMING ARTS / Radio / History & Criticism"
  },
  {
    "Code": "PER008020",
    "Literal": "PERFORMING ARTS / Radio / Reference"
  },
  {
    "Code": "PER009000",
    "Literal": "PERFORMING ARTS / Reference"
  },
  {
    "Code": "PER016000",
    "Literal": "PERFORMING ARTS / Screenplays"
  },
  {
    "Code": "PER019000",
    "Literal": "PERFORMING ARTS / Storytelling"
  },
  {
    "Code": "PER010000",
    "Literal": "PERFORMING ARTS / Television / General"
  },
  {
    "Code": "PER010010",
    "Literal": "PERFORMING ARTS / Television / Direction & Production"
  },
  {
    "Code": "PER010060",
    "Literal": "PERFORMING ARTS / Television / Genres / General"
  },
  {
    "Code": "PER010120",
    "Literal": "PERFORMING ARTS / Television / Genres / Animated"
  },
  {
    "Code": "PER010070",
    "Literal": "PERFORMING ARTS / Television / Genres / Children's"
  },
  {
    "Code": "PER010080",
    "Literal": "PERFORMING ARTS / Television / Genres / Comedy"
  },
  {
    "Code": "PER010130",
    "Literal": "PERFORMING ARTS / Television / Genres / Documentary"
  },
  {
    "Code": "PER010090",
    "Literal": "PERFORMING ARTS / Television / Genres / Drama"
  },
  {
    "Code": "PER010100",
    "Literal": "PERFORMING ARTS / Television / Genres / Reality, Game Shows & Talk Shows"
  },
  {
    "Code": "PER010110",
    "Literal": "PERFORMING ARTS / Television / Genres / Science Fiction, Fantasy & Horror"
  },
  {
    "Code": "PER010140",
    "Literal": "PERFORMING ARTS / Television / Genres / Superheroes"
  },
  {
    "Code": "PER010020",
    "Literal": "PERFORMING ARTS / Television / Guides & Reviews"
  },
  {
    "Code": "PER010030",
    "Literal": "PERFORMING ARTS / Television / History & Criticism"
  },
  {
    "Code": "PER010040",
    "Literal": "PERFORMING ARTS / Television / Reference"
  },
  {
    "Code": "PER010050",
    "Literal": "PERFORMING ARTS / Television / Screenwriting"
  },
  {
    "Code": "PER011000",
    "Literal": "PERFORMING ARTS / Theater / General"
  },
  {
    "Code": "PER013000",
    "Literal": "PERFORMING ARTS / Theater / Broadway & Musicals"
  },
  {
    "Code": "PER011010",
    "Literal": "PERFORMING ARTS / Theater / Direction & Production"
  },
  {
    "Code": "PER011050",
    "Literal": "PERFORMING ARTS / Theater / Dramaturgy"
  },
  {
    "Code": "PER011020",
    "Literal": "PERFORMING ARTS / Theater / History & Criticism"
  },
  {
    "Code": "PER006000",
    "Literal": "PERFORMING ARTS / Theater / Miming"
  },
  {
    "Code": "PER011030",
    "Literal": "PERFORMING ARTS / Theater / Playwriting"
  },
  {
    "Code": "PER011060",
    "Literal": "PERFORMING ARTS / Theater / Reference"
  },
  {
    "Code": "PER011070",
    "Literal": "PERFORMING ARTS / Theater / Regional & National"
  },
  {
    "Code": "PER011040",
    "Literal": "PERFORMING ARTS / Theater / Stagecraft & Scenography"
  },
  {
    "Code": "PER023000",
    "Literal": "PERFORMING ARTS / Voice Acting"
  },
  {
    "Code": "PHI000000",
    "Literal": "PHILOSOPHY / General"
  },
  {
    "Code": "PHI001000",
    "Literal": "PHILOSOPHY / Aesthetics"
  },
  {
    "Code": "PHI047000",
    "Literal": "PHILOSOPHY / African"
  },
  {
    "Code": "PHI028000",
    "Literal": "PHILOSOPHY / Buddhist"
  },
  {
    "Code": "PHI026000",
    "Literal": "PHILOSOPHY / Criticism"
  },
  {
    "Code": "PHI003000",
    "Literal": "PHILOSOPHY / Eastern"
  },
  {
    "Code": "PHI048000",
    "Literal": "PHILOSOPHY / Environmental"
  },
  {
    "Code": "PHI004000",
    "Literal": "PHILOSOPHY / Epistemology"
  },
  {
    "Code": "PHI035000",
    "Literal": "PHILOSOPHY / Essays"
  },
  {
    "Code": "PHI005000",
    "Literal": "PHILOSOPHY / Ethics & Moral Philosophy"
  },
  {
    "Code": "PHI007000",
    "Literal": "PHILOSOPHY / Free Will & Determinism"
  },
  {
    "Code": "PHI008000",
    "Literal": "PHILOSOPHY / Good & Evil"
  },
  {
    "Code": "PHI036000",
    "Literal": "PHILOSOPHY / Hermeneutics"
  },
  {
    "Code": "PHI033000",
    "Literal": "PHILOSOPHY / Hindu"
  },
  {
    "Code": "PHI009000",
    "Literal": "PHILOSOPHY / History & Surveys / General"
  },
  {
    "Code": "PHI002000",
    "Literal": "PHILOSOPHY / History & Surveys / Ancient & Classical"
  },
  {
    "Code": "PHI012000",
    "Literal": "PHILOSOPHY / History & Surveys / Medieval"
  },
  {
    "Code": "PHI037000",
    "Literal": "PHILOSOPHY / History & Surveys / Renaissance"
  },
  {
    "Code": "PHI016000",
    "Literal": "PHILOSOPHY / History & Surveys / Modern"
  },
  {
    "Code": "PHI050000",
    "Literal": "PHILOSOPHY / Indigenous"
  },
  {
    "Code": "PHI046000",
    "Literal": "PHILOSOPHY / Individual Philosophers"
  },
  {
    "Code": "PHI038000",
    "Literal": "PHILOSOPHY / Language"
  },
  {
    "Code": "PHI011000",
    "Literal": "PHILOSOPHY / Logic"
  },
  {
    "Code": "PHI013000",
    "Literal": "PHILOSOPHY / Metaphysics"
  },
  {
    "Code": "PHI014000",
    "Literal": "PHILOSOPHY / Methodology"
  },
  {
    "Code": "PHI015000",
    "Literal": "PHILOSOPHY / Mind & Body"
  },
  {
    "Code": "PHI031000",
    "Literal": "PHILOSOPHY / Movements / General"
  },
  {
    "Code": "PHI039000",
    "Literal": "PHILOSOPHY / Movements / Analytic"
  },
  {
    "Code": "PHI040000",
    "Literal": "PHILOSOPHY / Movements / Critical Theory"
  },
  {
    "Code": "PHI027000",
    "Literal": "PHILOSOPHY / Movements / Deconstruction"
  },
  {
    "Code": "PHI041000",
    "Literal": "PHILOSOPHY / Movements / Empiricism"
  },
  {
    "Code": "PHI006000",
    "Literal": "PHILOSOPHY / Movements / Existentialism"
  },
  {
    "Code": "PHI010000",
    "Literal": "PHILOSOPHY / Movements / Humanism"
  },
  {
    "Code": "PHI042000",
    "Literal": "PHILOSOPHY / Movements / Idealism"
  },
  {
    "Code": "PHI018000",
    "Literal": "PHILOSOPHY / Movements / Phenomenology"
  },
  {
    "Code": "PHI043000",
    "Literal": "PHILOSOPHY / Movements / Post-Structuralism"
  },
  {
    "Code": "PHI020000",
    "Literal": "PHILOSOPHY / Movements / Pragmatism"
  },
  {
    "Code": "PHI032000",
    "Literal": "PHILOSOPHY / Movements / Rationalism"
  },
  {
    "Code": "PHI044000",
    "Literal": "PHILOSOPHY / Movements / Realism"
  },
  {
    "Code": "PHI029000",
    "Literal": "PHILOSOPHY / Movements / Structuralism"
  },
  {
    "Code": "PHI045000",
    "Literal": "PHILOSOPHY / Movements / Transcendentalism"
  },
  {
    "Code": "PHI030000",
    "Literal": "PHILOSOPHY / Movements / Utilitarianism"
  },
  {
    "Code": "PHI049000",
    "Literal": "PHILOSOPHY / Nature"
  },
  {
    "Code": "PHI019000",
    "Literal": "PHILOSOPHY / Political"
  },
  {
    "Code": "PHI021000",
    "Literal": "PHILOSOPHY / Reference"
  },
  {
    "Code": "PHI022000",
    "Literal": "PHILOSOPHY / Religious"
  },
  {
    "Code": "PHI034000",
    "Literal": "PHILOSOPHY / Social"
  },
  {
    "Code": "PHI023000",
    "Literal": "PHILOSOPHY / Taoist"
  },
  {
    "Code": "PHI025000",
    "Literal": "PHILOSOPHY / Zen"
  },
  {
    "Code": "PHO000000",
    "Literal": "PHOTOGRAPHY / General"
  },
  {
    "Code": "PHO025000",
    "Literal": "PHOTOGRAPHY / Annuals"
  },
  {
    "Code": "PHO026000",
    "Literal": "PHOTOGRAPHY / Astrophotography"
  },
  {
    "Code": "PHO003000",
    "Literal": "PHOTOGRAPHY / Business Aspects"
  },
  {
    "Code": "PHO004000",
    "Literal": "PHOTOGRAPHY / Collections, Catalogs, Exhibitions / General"
  },
  {
    "Code": "PHO004010",
    "Literal": "PHOTOGRAPHY / Collections, Catalogs, Exhibitions / Group Shows"
  },
  {
    "Code": "PHO004020",
    "Literal": "PHOTOGRAPHY / Collections, Catalogs, Exhibitions / Permanent Collections"
  },
  {
    "Code": "PHO021000",
    "Literal": "PHOTOGRAPHY / Commercial"
  },
  {
    "Code": "PHO005000",
    "Literal": "PHOTOGRAPHY / Criticism"
  },
  {
    "Code": "PHO027000",
    "Literal": "PHOTOGRAPHY / Forensic"
  },
  {
    "Code": "PHO010000",
    "Literal": "PHOTOGRAPHY / History"
  },
  {
    "Code": "PHO011000",
    "Literal": "PHOTOGRAPHY / Individual Photographers / General"
  },
  {
    "Code": "PHO011010",
    "Literal": "PHOTOGRAPHY / Individual Photographers / Artists' Books"
  },
  {
    "Code": "PHO011020",
    "Literal": "PHOTOGRAPHY / Individual Photographers / Essays"
  },
  {
    "Code": "PHO011030",
    "Literal": "PHOTOGRAPHY / Individual Photographers / Monographs"
  },
  {
    "Code": "PHO014000",
    "Literal": "PHOTOGRAPHY / Photoessays & Documentaries"
  },
  {
    "Code": "PHO015000",
    "Literal": "PHOTOGRAPHY / Photojournalism"
  },
  {
    "Code": "PHO017000",
    "Literal": "PHOTOGRAPHY / Reference"
  },
  {
    "Code": "PHO023000",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / General"
  },
  {
    "Code": "PHO023010",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Aerial"
  },
  {
    "Code": "PHO001000",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Architectural & Industrial"
  },
  {
    "Code": "PHO023070",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Celebrations & Events"
  },
  {
    "Code": "PHO023080",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Celebrity"
  },
  {
    "Code": "PHO023020",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Children"
  },
  {
    "Code": "PHO023030",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Erotica"
  },
  {
    "Code": "PHO009000",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Fashion"
  },
  {
    "Code": "PHO023110",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Food"
  },
  {
    "Code": "PHO023100",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Historical"
  },
  {
    "Code": "PHO023040",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Landscapes"
  },
  {
    "Code": "PHO023090",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Lifestyles"
  },
  {
    "Code": "PHO023050",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Nudes"
  },
  {
    "Code": "PHO013000",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Plants & Animals"
  },
  {
    "Code": "PHO016000",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Portraits & Selfies"
  },
  {
    "Code": "PHO019000",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Regional (see also TRAVEL / Pictorials)"
  },
  {
    "Code": "PHO023060",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Sports"
  },
  {
    "Code": "PHO023120",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Street Photography"
  },
  {
    "Code": "PHO023130",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Underwater"
  },
  {
    "Code": "PHO018000",
    "Literal": "PHOTOGRAPHY / Techniques / General"
  },
  {
    "Code": "PHO022000",
    "Literal": "PHOTOGRAPHY / Techniques / Cinematography & Videography"
  },
  {
    "Code": "PHO020000",
    "Literal": "PHOTOGRAPHY / Techniques / Color"
  },
  {
    "Code": "PHO006000",
    "Literal": "PHOTOGRAPHY / Techniques / Darkroom"
  },
  {
    "Code": "PHO024000",
    "Literal": "PHOTOGRAPHY / Techniques / Digital (see also COMPUTERS / Design, Graphics & Media / Photography)"
  },
  {
    "Code": "PHO007000",
    "Literal": "PHOTOGRAPHY / Techniques / Equipment"
  },
  {
    "Code": "PHO012000",
    "Literal": "PHOTOGRAPHY / Techniques / Lighting"
  },
  {
    "Code": "POE000000",
    "Literal": "POETRY / General"
  },
  {
    "Code": "POE007000",
    "Literal": "POETRY / African"
  },
  {
    "Code": "POE005010",
    "Literal": "POETRY / American / General"
  },
  {
    "Code": "POE005050",
    "Literal": "POETRY / American / African American & Black"
  },
  {
    "Code": "POE005060",
    "Literal": "POETRY / American / Asian American & Pacific Islander"
  },
  {
    "Code": "POE005070",
    "Literal": "POETRY / American / Hispanic & Latino"
  },
  {
    "Code": "POE008000",
    "Literal": "POETRY / Ancient & Classical"
  },
  {
    "Code": "POE001000",
    "Literal": "POETRY / Anthologies (multiple authors)"
  },
  {
    "Code": "POE009000",
    "Literal": "POETRY / Asian / General"
  },
  {
    "Code": "POE009010",
    "Literal": "POETRY / Asian / Chinese"
  },
  {
    "Code": "POE009020",
    "Literal": "POETRY / Asian / Japanese"
  },
  {
    "Code": "POE010000",
    "Literal": "POETRY / Australian & Oceanian"
  },
  {
    "Code": "POE011000",
    "Literal": "POETRY / Canadian"
  },
  {
    "Code": "POE012000",
    "Literal": "POETRY / Caribbean & Latin American"
  },
  {
    "Code": "POE005030",
    "Literal": "POETRY / European / General"
  },
  {
    "Code": "POE027000",
    "Literal": "POETRY / European / Eastern"
  },
  {
    "Code": "POE005020",
    "Literal": "POETRY / European / English, Irish, Scottish, Welsh"
  },
  {
    "Code": "POE017000",
    "Literal": "POETRY / European / French"
  },
  {
    "Code": "POE018000",
    "Literal": "POETRY / European / German"
  },
  {
    "Code": "POE019000",
    "Literal": "POETRY / European / Italian"
  },
  {
    "Code": "POE020000",
    "Literal": "POETRY / European / Spanish & Portuguese"
  },
  {
    "Code": "POE014000",
    "Literal": "POETRY / Epic"
  },
  {
    "Code": "POE025000",
    "Literal": "POETRY / Haiku"
  },
  {
    "Code": "POE030000",
    "Literal": "POETRY / Indigenous"
  },
  {
    "Code": "POE011010",
    "Literal": "POETRY / Indigenous Peoples of Turtle Island"
  },
  {
    "Code": "POE028000",
    "Literal": "POETRY / Jewish"
  },
  {
    "Code": "POE021000",
    "Literal": "POETRY / LGBTQ+"
  },
  {
    "Code": "POE022000",
    "Literal": "POETRY / Medieval"
  },
  {
    "Code": "POE013000",
    "Literal": "POETRY / Middle Eastern"
  },
  {
    "Code": "POE015000",
    "Literal": "POETRY / Native American"
  },
  {
    "Code": "POE016000",
    "Literal": "POETRY / Russian & Soviet"
  },
  {
    "Code": "POE026000",
    "Literal": "POETRY / Shakespeare"
  },
  {
    "Code": "POE029000",
    "Literal": "POETRY / Sonnets"
  },
  {
    "Code": "POE023000",
    "Literal": "POETRY / Subjects & Themes / General"
  },
  {
    "Code": "POE023030",
    "Literal": "POETRY / Subjects & Themes / Animals & Nature"
  },
  {
    "Code": "POE023010",
    "Literal": "POETRY / Subjects & Themes / Death, Grief, Loss"
  },
  {
    "Code": "POE023050",
    "Literal": "POETRY / Subjects & Themes / Family"
  },
  {
    "Code": "POE023020",
    "Literal": "POETRY / Subjects & Themes / Love & Erotica"
  },
  {
    "Code": "POE023080",
    "Literal": "POETRY / Subjects & Themes / Motivational & Inspirational"
  },
  {
    "Code": "POE023040",
    "Literal": "POETRY / Subjects & Themes / Places"
  },
  {
    "Code": "POE023060",
    "Literal": "POETRY / Subjects & Themes / Political & Protest"
  },
  {
    "Code": "POE003000",
    "Literal": "POETRY / Subjects & Themes / Religious"
  },
  {
    "Code": "POE023070",
    "Literal": "POETRY / Subjects & Themes / War"
  },
  {
    "Code": "POE024000",
    "Literal": "POETRY / Women Authors"
  },
  {
    "Code": "REF004000",
    "Literal": "REFERENCE / Bibliographies & Indexes"
  },
  {
    "Code": "SOC000000",
    "Literal": "SOCIAL SCIENCE / General"
  },
  {
    "Code": "SOC046000",
    "Literal": "SOCIAL SCIENCE / Abortion & Birth Control"
  },
  {
    "Code": "SOC072000",
    "Literal": "SOCIAL SCIENCE / Activism & Social Justice"
  },
  {
    "Code": "SOC055000",
    "Literal": "SOCIAL SCIENCE / Agriculture & Food (see also POLITICAL SCIENCE / Public Policy / Agriculture & Food Policy)"
  },
  {
    "Code": "SOC002000",
    "Literal": "SOCIAL SCIENCE / Anthropology / General"
  },
  {
    "Code": "SOC002010",
    "Literal": "SOCIAL SCIENCE / Anthropology / Cultural & Social"
  },
  {
    "Code": "SOC002020",
    "Literal": "SOCIAL SCIENCE / Anthropology / Physical"
  },
  {
    "Code": "SOC003000",
    "Literal": "SOCIAL SCIENCE / Archaeology"
  },
  {
    "Code": "SOC068000",
    "Literal": "SOCIAL SCIENCE / Biracial & Multiracial Studies"
  },
  {
    "Code": "SOC056000",
    "Literal": "SOCIAL SCIENCE / Black Studies (Global)"
  },
  {
    "Code": "SOC061000",
    "Literal": "SOCIAL SCIENCE / Body Language & Nonverbal Communication"
  },
  {
    "Code": "SOC067000",
    "Literal": "SOCIAL SCIENCE / Capital Punishment"
  },
  {
    "Code": "SOC047000",
    "Literal": "SOCIAL SCIENCE / Children's Studies"
  },
  {
    "Code": "SOC058000",
    "Literal": "SOCIAL SCIENCE / Conspiracy Theories"
  },
  {
    "Code": "SOC004000",
    "Literal": "SOCIAL SCIENCE / Criminology"
  },
  {
    "Code": "SOC008000",
    "Literal": "SOCIAL SCIENCE / Cultural & Ethnic Studies / General"
  },
  {
    "Code": "SOC008010",
    "Literal": "SOCIAL SCIENCE / Cultural & Ethnic Studies / African Studies"
  },
  {
    "Code": "SOC069000",
    "Literal": "SOCIAL SCIENCE / Cultural & Ethnic Studies / American / General"
  },
  {
    "Code": "SOC001000",
    "Literal": "SOCIAL SCIENCE / Cultural & Ethnic Studies / American / African American & Black Studies"
  },
  {
    "Code": "SOC043000",
    "Literal": "SOCIAL SCIENCE / Cultural & Ethnic Studies / American / Asian American & Pacific Islander Studies"
  },
  {
    "Code": "SOC008080",
    "Literal": "SOCIAL SCIENCE / Cultural & Ethnic Studies / American / European American Studies"
  },
  {
    "Code": "SOC044000",
    "Literal": "SOCIAL SCIENCE / Cultural & Ethnic Studies / American / Hispanic & Latino Studies"
  },
  {
    "Code": "SOC008020",
    "Literal": "SOCIAL SCIENCE / Cultural & Ethnic Studies / Asian Studies"
  },
  {
    "Code": "SOC008030",
    "Literal": "SOCIAL SCIENCE / Cultural & Ethnic Studies / Australian & Oceanian Studies"
  },
  {
    "Code": "SOC008040",
    "Literal": "SOCIAL SCIENCE / Cultural & Ethnic Studies / Canadian Studies"
  },
  {
    "Code": "SOC008050",
    "Literal": "SOCIAL SCIENCE / Cultural & Ethnic Studies / Caribbean & Latin American Studies"
  },
  {
    "Code": "SOC008060",
    "Literal": "SOCIAL SCIENCE / Cultural & Ethnic Studies / European Studies"
  },
  {
    "Code": "SOC008070",
    "Literal": "SOCIAL SCIENCE / Cultural & Ethnic Studies / Middle Eastern Studies"
  },
  {
    "Code": "SOC005000",
    "Literal": "SOCIAL SCIENCE / Customs & Traditions"
  },
  {
    "Code": "SOC036000",
    "Literal": "SOCIAL SCIENCE / Death & Dying"
  },
  {
    "Code": "SOC006000",
    "Literal": "SOCIAL SCIENCE / Demography"
  },
  {
    "Code": "SOC042000",
    "Literal": "SOCIAL SCIENCE / Developing & Emerging Countries"
  },
  {
    "Code": "SOC040000",
    "Literal": "SOCIAL SCIENCE / Disasters & Disaster Relief"
  },
  {
    "Code": "SOC031000",
    "Literal": "SOCIAL SCIENCE / Discrimination"
  },
  {
    "Code": "SOC057000",
    "Literal": "SOCIAL SCIENCE / Disease & Health Issues"
  },
  {
    "Code": "SOC074000",
    "Literal": "SOCIAL SCIENCE / Diversity & Multiculturalism"
  },
  {
    "Code": "SOC007000",
    "Literal": "SOCIAL SCIENCE / Emigration & Immigration"
  },
  {
    "Code": "SOC041000",
    "Literal": "SOCIAL SCIENCE / Essays"
  },
  {
    "Code": "SOC010000",
    "Literal": "SOCIAL SCIENCE / Feminism & Feminist Theory"
  },
  {
    "Code": "SOC011000",
    "Literal": "SOCIAL SCIENCE / Folklore & Mythology"
  },
  {
    "Code": "SOC038000",
    "Literal": "SOCIAL SCIENCE / Freemasonry & Secret Societies"
  },
  {
    "Code": "SOC037000",
    "Literal": "SOCIAL SCIENCE / Future Studies"
  },
  {
    "Code": "SOC032000",
    "Literal": "SOCIAL SCIENCE / Gender Studies"
  },
  {
    "Code": "SOC013000",
    "Literal": "SOCIAL SCIENCE / Gerontology"
  },
  {
    "Code": "SOC014000",
    "Literal": "SOCIAL SCIENCE / Holidays (non-religious)"
  },
  {
    "Code": "SOC015000",
    "Literal": "SOCIAL SCIENCE / Human Geography"
  },
  {
    "Code": "SOC016000",
    "Literal": "SOCIAL SCIENCE / Human Services"
  },
  {
    "Code": "SOC065000",
    "Literal": "SOCIAL SCIENCE / Human Sexuality (see also PSYCHOLOGY / Human Sexuality)"
  },
  {
    "Code": "SOC073000",
    "Literal": "SOCIAL SCIENCE / Human Trafficking"
  },
  {
    "Code": "SOC075000",
    "Literal": "SOCIAL SCIENCE / Indigenous Peoples of Turtle Island Studies"
  },
  {
    "Code": "SOC062000",
    "Literal": "SOCIAL SCIENCE / Indigenous Studies"
  },
  {
    "Code": "SOC048000",
    "Literal": "SOCIAL SCIENCE / Islamic Studies"
  },
  {
    "Code": "SOC049000",
    "Literal": "SOCIAL SCIENCE / Jewish Studies"
  },
  {
    "Code": "SOC064000",
    "Literal": "SOCIAL SCIENCE / LGBTQ+ Studies / General"
  },
  {
    "Code": "SOC064010",
    "Literal": "SOCIAL SCIENCE / LGBTQ+ Studies / Bisexual Studies"
  },
  {
    "Code": "SOC012000",
    "Literal": "SOCIAL SCIENCE / LGBTQ+ Studies / Gay Studies"
  },
  {
    "Code": "SOC017000",
    "Literal": "SOCIAL SCIENCE / LGBTQ+ Studies / Lesbian Studies"
  },
  {
    "Code": "SOC064020",
    "Literal": "SOCIAL SCIENCE / LGBTQ+ Studies / Transgender Studies"
  },
  {
    "Code": "SOC052000",
    "Literal": "SOCIAL SCIENCE / Media Studies"
  },
  {
    "Code": "SOC018000",
    "Literal": "SOCIAL SCIENCE / Men's Studies"
  },
  {
    "Code": "SOC019000",
    "Literal": "SOCIAL SCIENCE / Methodology"
  },
  {
    "Code": "SOC020000",
    "Literal": "SOCIAL SCIENCE / Minority Studies"
  },
  {
    "Code": "SOC021000",
    "Literal": "SOCIAL SCIENCE / Native American Studies"
  },
  {
    "Code": "SOC030000",
    "Literal": "SOCIAL SCIENCE / Penology"
  },
  {
    "Code": "SOC029000",
    "Literal": "SOCIAL SCIENCE / People with Disabilities"
  },
  {
    "Code": "SOC033000",
    "Literal": "SOCIAL SCIENCE / Philanthropy & Charity"
  },
  {
    "Code": "SOC022000",
    "Literal": "SOCIAL SCIENCE / Popular Culture"
  },
  {
    "Code": "SOC034000",
    "Literal": "SOCIAL SCIENCE / Pornography"
  },
  {
    "Code": "SOC045000",
    "Literal": "SOCIAL SCIENCE / Poverty & Homelessness"
  },
  {
    "Code": "SOC063000",
    "Literal": "SOCIAL SCIENCE / Privacy & Surveillance (see also POLITICAL SCIENCE / Privacy & Surveillance)"
  },
  {
    "Code": "SOC059000",
    "Literal": "SOCIAL SCIENCE / Prostitution & Sex Trade"
  },
  {
    "Code": "SOC070000",
    "Literal": "SOCIAL SCIENCE / Race & Ethnic Relations"
  },
  {
    "Code": "SOC023000",
    "Literal": "SOCIAL SCIENCE / Reference"
  },
  {
    "Code": "SOC066000",
    "Literal": "SOCIAL SCIENCE / Refugees"
  },
  {
    "Code": "SOC053000",
    "Literal": "SOCIAL SCIENCE / Regional Studies"
  },
  {
    "Code": "SOC024000",
    "Literal": "SOCIAL SCIENCE / Research"
  },
  {
    "Code": "SOC060000",
    "Literal": "SOCIAL SCIENCE / Sexual Abuse & Harassment"
  },
  {
    "Code": "SOC054000",
    "Literal": "SOCIAL SCIENCE / Slavery"
  },
  {
    "Code": "SOC050000",
    "Literal": "SOCIAL SCIENCE / Social Classes & Economic Disparity"
  },
  {
    "Code": "SOC025000",
    "Literal": "SOCIAL SCIENCE / Social Work"
  },
  {
    "Code": "SOC026000",
    "Literal": "SOCIAL SCIENCE / Sociology / General"
  },
  {
    "Code": "SOC026010",
    "Literal": "SOCIAL SCIENCE / Sociology / Marriage & Family"
  },
  {
    "Code": "SOC026020",
    "Literal": "SOCIAL SCIENCE / Sociology / Rural"
  },
  {
    "Code": "SOC026040",
    "Literal": "SOCIAL SCIENCE / Sociology / Social Theory"
  },
  {
    "Code": "SOC026030",
    "Literal": "SOCIAL SCIENCE / Sociology / Urban"
  },
  {
    "Code": "SOC039000",
    "Literal": "SOCIAL SCIENCE / Sociology of Religion"
  },
  {
    "Code": "SOC027000",
    "Literal": "SOCIAL SCIENCE / Statistics"
  },
  {
    "Code": "SOC071000",
    "Literal": "SOCIAL SCIENCE / Technology Studies"
  },
  {
    "Code": "SOC051000",
    "Literal": "SOCIAL SCIENCE / Violence in Society"
  },
  {
    "Code": "SOC035000",
    "Literal": "SOCIAL SCIENCE / Volunteer Work"
  },
  {
    "Code": "SOC028000",
    "Literal": "SOCIAL SCIENCE / Women's Studies"
  },
  {
    "Code": "YAF000000",
    "Literal": "YOUNG ADULT FICTION / General"
  },
  {
    "Code": "YAF001000",
    "Literal": "YOUNG ADULT FICTION / Action & Adventure / General"
  },
  {
    "Code": "YAF001010",
    "Literal": "YOUNG ADULT FICTION / Action & Adventure / Pirates"
  },
  {
    "Code": "YAF001020",
    "Literal": "YOUNG ADULT FICTION / Action & Adventure / Survival Stories"
  },
  {
    "Code": "YAF046120",
    "Literal": "YOUNG ADULT FICTION / African American & Black"
  },
  {
    "Code": "YAF071000",
    "Literal": "YOUNG ADULT FICTION / Alternative History"
  },
  {
    "Code": "YAF002000",
    "Literal": "YOUNG ADULT FICTION / Animals / General"
  },
  {
    "Code": "YAF002010",
    "Literal": "YOUNG ADULT FICTION / Animals / Horses"
  },
  {
    "Code": "YAF002020",
    "Literal": "YOUNG ADULT FICTION / Animals / Marine Life"
  },
  {
    "Code": "YAF002030",
    "Literal": "YOUNG ADULT FICTION / Animals / Mythical Creatures"
  },
  {
    "Code": "YAF002040",
    "Literal": "YOUNG ADULT FICTION / Animals / Pets"
  },
  {
    "Code": "YAF004000",
    "Literal": "YOUNG ADULT FICTION / Art"
  },
  {
    "Code": "YAF046130",
    "Literal": "YOUNG ADULT FICTION / Asian American & Pacific Islander"
  },
  {
    "Code": "YAF005000",
    "Literal": "YOUNG ADULT FICTION / Biographical"
  },
  {
    "Code": "YAF076000",
    "Literal": "YOUNG ADULT FICTION / Biracial & Multiracial"
  },
  {
    "Code": "YAF006000",
    "Literal": "YOUNG ADULT FICTION / Books & Libraries"
  },
  {
    "Code": "YAF007000",
    "Literal": "YOUNG ADULT FICTION / Boys & Men"
  },
  {
    "Code": "YAF008000",
    "Literal": "YOUNG ADULT FICTION / Careers, Occupations, Internships"
  },
  {
    "Code": "YAF009000",
    "Literal": "YOUNG ADULT FICTION / Classics"
  },
  {
    "Code": "YAF072000",
    "Literal": "YOUNG ADULT FICTION / Clean & Nonviolent"
  },
  {
    "Code": "YAF010000",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / General"
  },
  {
    "Code": "YAF010050",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Action & Adventure"
  },
  {
    "Code": "YAF010190",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / African American & Black"
  },
  {
    "Code": "YAF010200",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Asian American & Pacific Islander"
  },
  {
    "Code": "YAF010060",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Classic Adaptation"
  },
  {
    "Code": "YAF010070",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Coming of Age"
  },
  {
    "Code": "YAF010180",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Diversity & Multicultural"
  },
  {
    "Code": "YAF010080",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Dystopian"
  },
  {
    "Code": "YAF010090",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Fairy Tales, Folklore, Legends & Mythology"
  },
  {
    "Code": "YAF010100",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Fantasy"
  },
  {
    "Code": "YAF010210",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Hispanic & Latino"
  },
  {
    "Code": "YAF010110",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Historical"
  },
  {
    "Code": "YAF010120",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Horror"
  },
  {
    "Code": "YAF010130",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Humorous"
  },
  {
    "Code": "YAF010220",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Indigenous"
  },
  {
    "Code": "YAF010230",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Indigenous Peoples of Turtle Island"
  },
  {
    "Code": "YAF010140",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / LGBTQ+"
  },
  {
    "Code": "YAF035000",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Light Novel"
  },
  {
    "Code": "YAF010010",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Manga"
  },
  {
    "Code": "YAF010020",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Media Tie-In"
  },
  {
    "Code": "YAF010150",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Mystery & Detective"
  },
  {
    "Code": "YAF010240",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Native American"
  },
  {
    "Code": "YAF010160",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Paranormal"
  },
  {
    "Code": "YAF010170",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Romance"
  },
  {
    "Code": "YAF010030",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Science Fiction"
  },
  {
    "Code": "YAF010040",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Superheroes"
  },
  {
    "Code": "YAF011000",
    "Literal": "YOUNG ADULT FICTION / Coming of Age"
  },
  {
    "Code": "YAF012000",
    "Literal": "YOUNG ADULT FICTION / Computers & Digital Media"
  },
  {
    "Code": "YAF013000",
    "Literal": "YOUNG ADULT FICTION / Cooking & Food"
  },
  {
    "Code": "YAF058070",
    "Literal": "YOUNG ADULT FICTION / Disabilities"
  },
  {
    "Code": "YAF014000",
    "Literal": "YOUNG ADULT FICTION / Diversity & Multicultural"
  },
  {
    "Code": "YAF015000",
    "Literal": "YOUNG ADULT FICTION / Dystopian"
  },
  {
    "Code": "YAF016000",
    "Literal": "YOUNG ADULT FICTION / Epistolary (Letters & Diaries)"
  },
  {
    "Code": "YAF017000",
    "Literal": "YOUNG ADULT FICTION / Fairy Tales & Folklore / General"
  },
  {
    "Code": "YAF017010",
    "Literal": "YOUNG ADULT FICTION / Fairy Tales & Folklore / Adaptations"
  },
  {
    "Code": "YAF017020",
    "Literal": "YOUNG ADULT FICTION / Fairy Tales & Folklore / Anthologies"
  },
  {
    "Code": "YAF017030",
    "Literal": "YOUNG ADULT FICTION / Fairy Tales & Folklore / Country & Cultural"
  },
  {
    "Code": "YAF018000",
    "Literal": "YOUNG ADULT FICTION / Family / General (see also headings under Social Themes)"
  },
  {
    "Code": "YAF018010",
    "Literal": "YOUNG ADULT FICTION / Family / Adoption"
  },
  {
    "Code": "YAF018020",
    "Literal": "YOUNG ADULT FICTION / Family / Alternative Family"
  },
  {
    "Code": "YAF018080",
    "Literal": "YOUNG ADULT FICTION / Family / Blended Families"
  },
  {
    "Code": "YAF018030",
    "Literal": "YOUNG ADULT FICTION / Family / Marriage & Divorce"
  },
  {
    "Code": "YAF018040",
    "Literal": "YOUNG ADULT FICTION / Family / Multigenerational"
  },
  {
    "Code": "YAF018050",
    "Literal": "YOUNG ADULT FICTION / Family / Orphans & Foster Homes"
  },
  {
    "Code": "YAF018060",
    "Literal": "YOUNG ADULT FICTION / Family / Parents"
  },
  {
    "Code": "YAF018070",
    "Literal": "YOUNG ADULT FICTION / Family / Siblings"
  },
  {
    "Code": "YAF019000",
    "Literal": "YOUNG ADULT FICTION / Fantasy / General"
  },
  {
    "Code": "YAF019010",
    "Literal": "YOUNG ADULT FICTION / Fantasy / Contemporary"
  },
  {
    "Code": "YAF019020",
    "Literal": "YOUNG ADULT FICTION / Fantasy / Dark Fantasy"
  },
  {
    "Code": "YAF019030",
    "Literal": "YOUNG ADULT FICTION / Fantasy / Epic"
  },
  {
    "Code": "YAF019040",
    "Literal": "YOUNG ADULT FICTION / Fantasy / Historical"
  },
  {
    "Code": "YAF019070",
    "Literal": "YOUNG ADULT FICTION / Fantasy / Indigenous"
  },
  {
    "Code": "YAF019060",
    "Literal": "YOUNG ADULT FICTION / Fantasy / Romance"
  },
  {
    "Code": "YAF019050",
    "Literal": "YOUNG ADULT FICTION / Fantasy / Wizards & Witches"
  },
  {
    "Code": "YAF020000",
    "Literal": "YOUNG ADULT FICTION / Fashion & Beauty"
  },
  {
    "Code": "YAF077000",
    "Literal": "YOUNG ADULT FICTION / First Nations"
  },
  {
    "Code": "YAF021000",
    "Literal": "YOUNG ADULT FICTION / Ghost Stories"
  },
  {
    "Code": "YAF022000",
    "Literal": "YOUNG ADULT FICTION / Girls & Women"
  },
  {
    "Code": "YAF023000",
    "Literal": "YOUNG ADULT FICTION / Health & Daily Living / General"
  },
  {
    "Code": "YAF023010",
    "Literal": "YOUNG ADULT FICTION / Health & Daily Living / Diseases, Illnesses & Injuries"
  },
  {
    "Code": "YAF046140",
    "Literal": "YOUNG ADULT FICTION / Hispanic & Latino"
  },
  {
    "Code": "YAF024000",
    "Literal": "YOUNG ADULT FICTION / Historical / General"
  },
  {
    "Code": "YAF024010",
    "Literal": "YOUNG ADULT FICTION / Historical / Africa"
  },
  {
    "Code": "YAF024020",
    "Literal": "YOUNG ADULT FICTION / Historical / Ancient Civilizations"
  },
  {
    "Code": "YAF024030",
    "Literal": "YOUNG ADULT FICTION / Historical / Asia"
  },
  {
    "Code": "YAF024190",
    "Literal": "YOUNG ADULT FICTION / Historical / Australia & Oceania"
  },
  {
    "Code": "YAF024040",
    "Literal": "YOUNG ADULT FICTION / Historical / Canada"
  },
  {
    "Code": "YAF024050",
    "Literal": "YOUNG ADULT FICTION / Historical / Europe"
  },
  {
    "Code": "YAF024060",
    "Literal": "YOUNG ADULT FICTION / Historical / Exploration & Discovery"
  },
  {
    "Code": "YAF024070",
    "Literal": "YOUNG ADULT FICTION / Historical / Holocaust"
  },
  {
    "Code": "YAF024080",
    "Literal": "YOUNG ADULT FICTION / Historical / Medieval"
  },
  {
    "Code": "YAF024090",
    "Literal": "YOUNG ADULT FICTION / Historical / Middle East"
  },
  {
    "Code": "YAF024100",
    "Literal": "YOUNG ADULT FICTION / Historical / Military & Wars"
  },
  {
    "Code": "YAF024110",
    "Literal": "YOUNG ADULT FICTION / Historical / Prehistory"
  },
  {
    "Code": "YAF024120",
    "Literal": "YOUNG ADULT FICTION / Historical / Renaissance"
  },
  {
    "Code": "YAF024130",
    "Literal": "YOUNG ADULT FICTION / Historical / United States / General"
  },
  {
    "Code": "YAF024140",
    "Literal": "YOUNG ADULT FICTION / Historical / United States / Colonial & Revolutionary Periods"
  },
  {
    "Code": "YAF024150",
    "Literal": "YOUNG ADULT FICTION / Historical / United States / 19th Century"
  },
  {
    "Code": "YAF024160",
    "Literal": "YOUNG ADULT FICTION / Historical / United States / Civil War Period (1850-1877)"
  },
  {
    "Code": "YAF024170",
    "Literal": "YOUNG ADULT FICTION / Historical / United States / 20th Century"
  },
  {
    "Code": "YAF024180",
    "Literal": "YOUNG ADULT FICTION / Historical / United States / 21st Century"
  },
  {
    "Code": "YAF025000",
    "Literal": "YOUNG ADULT FICTION / Holidays & Celebrations"
  },
  {
    "Code": "YAF026000",
    "Literal": "YOUNG ADULT FICTION / Horror"
  },
  {
    "Code": "YAF027000",
    "Literal": "YOUNG ADULT FICTION / Humorous / General"
  },
  {
    "Code": "YAF027010",
    "Literal": "YOUNG ADULT FICTION / Humorous / Dark Humor"
  },
  {
    "Code": "YAF046010",
    "Literal": "YOUNG ADULT FICTION / Indigenous / General"
  },
  {
    "Code": "YAF046180",
    "Literal": "YOUNG ADULT FICTION / Indigenous / Cautionary Tales"
  },
  {
    "Code": "YAF046190",
    "Literal": "YOUNG ADULT FICTION / Indigenous / City Life"
  },
  {
    "Code": "YAF046200",
    "Literal": "YOUNG ADULT FICTION / Indigenous / Family Life"
  },
  {
    "Code": "YAF046210",
    "Literal": "YOUNG ADULT FICTION / Indigenous / Historical"
  },
  {
    "Code": "YAF046220",
    "Literal": "YOUNG ADULT FICTION / Indigenous / Life Stories (see also Biographical)"
  },
  {
    "Code": "YAF046230",
    "Literal": "YOUNG ADULT FICTION / Indigenous / Oral Stories"
  },
  {
    "Code": "YAF046240",
    "Literal": "YOUNG ADULT FICTION / Indigenous / Retellings"
  },
  {
    "Code": "YAF046250",
    "Literal": "YOUNG ADULT FICTION / Indigenous / Teachings"
  },
  {
    "Code": "YAF046170",
    "Literal": "YOUNG ADULT FICTION / Indigenous Peoples of Turtle Island"
  },
  {
    "Code": "YAF028000",
    "Literal": "YOUNG ADULT FICTION / Interactive Adventures"
  },
  {
    "Code": "YAF078000",
    "Literal": "YOUNG ADULT FICTION / Inuit"
  },
  {
    "Code": "YAF029000",
    "Literal": "YOUNG ADULT FICTION / Law & Crime"
  },
  {
    "Code": "YAF030000",
    "Literal": "YOUNG ADULT FICTION / Legends, Myths, Fables / General"
  },
  {
    "Code": "YAF030010",
    "Literal": "YOUNG ADULT FICTION / Legends, Myths, Fables / Arthurian"
  },
  {
    "Code": "YAF030020",
    "Literal": "YOUNG ADULT FICTION / Legends, Myths, Fables / Greek & Roman"
  },
  {
    "Code": "YAF031000",
    "Literal": "YOUNG ADULT FICTION / LGBTQ+ / General"
  },
  {
    "Code": "YAF031010",
    "Literal": "YOUNG ADULT FICTION / LGBTQ+ / Bisexual"
  },
  {
    "Code": "YAF031020",
    "Literal": "YOUNG ADULT FICTION / LGBTQ+ / Gay"
  },
  {
    "Code": "YAF031030",
    "Literal": "YOUNG ADULT FICTION / LGBTQ+ / Lesbian"
  },
  {
    "Code": "YAF031040",
    "Literal": "YOUNG ADULT FICTION / LGBTQ+ / Transgender"
  },
  {
    "Code": "YAF031050",
    "Literal": "YOUNG ADULT FICTION / LGBTQ+ / Two-Spirited & Indigiqueer"
  },
  {
    "Code": "YAF032000",
    "Literal": "YOUNG ADULT FICTION / Lifestyles / City & Town Life"
  },
  {
    "Code": "YAF033000",
    "Literal": "YOUNG ADULT FICTION / Lifestyles / Country Life"
  },
  {
    "Code": "YAF034000",
    "Literal": "YOUNG ADULT FICTION / Lifestyles / Farm & Ranch Life"
  },
  {
    "Code": "YAF036000",
    "Literal": "YOUNG ADULT FICTION / Literary"
  },
  {
    "Code": "YAF037000",
    "Literal": "YOUNG ADULT FICTION / Loners & Outcasts"
  },
  {
    "Code": "YAF038000",
    "Literal": "YOUNG ADULT FICTION / Magical Realism"
  },
  {
    "Code": "YAF039000",
    "Literal": "YOUNG ADULT FICTION / Media Tie-In"
  },
  {
    "Code": "YAF040000",
    "Literal": "YOUNG ADULT FICTION / Mermaids & Mermen"
  },
  {
    "Code": "YAF079000",
    "Literal": "YOUNG ADULT FICTION / Métis"
  },
  {
    "Code": "YAF046160",
    "Literal": "YOUNG ADULT FICTION / Middle Eastern & Arab American"
  },
  {
    "Code": "YAF041000",
    "Literal": "YOUNG ADULT FICTION / Monsters"
  },
  {
    "Code": "YAF042000",
    "Literal": "YOUNG ADULT FICTION / Mysteries & Detective Stories"
  },
  {
    "Code": "YAF046150",
    "Literal": "YOUNG ADULT FICTION / Native American"
  },
  {
    "Code": "YAF074000",
    "Literal": "YOUNG ADULT FICTION / Neurodiversity"
  },
  {
    "Code": "YAF044000",
    "Literal": "YOUNG ADULT FICTION / Novels in Verse"
  },
  {
    "Code": "YAF073000",
    "Literal": "YOUNG ADULT FICTION / Own Voices"
  },
  {
    "Code": "YAF045000",
    "Literal": "YOUNG ADULT FICTION / Paranormal, Occult & Supernatural"
  },
  {
    "Code": "YAF047000",
    "Literal": "YOUNG ADULT FICTION / Performing Arts / General"
  },
  {
    "Code": "YAF047010",
    "Literal": "YOUNG ADULT FICTION / Performing Arts / Dance"
  },
  {
    "Code": "YAF047020",
    "Literal": "YOUNG ADULT FICTION / Performing Arts / Film"
  },
  {
    "Code": "YAF047030",
    "Literal": "YOUNG ADULT FICTION / Performing Arts / Music"
  },
  {
    "Code": "YAF047040",
    "Literal": "YOUNG ADULT FICTION / Performing Arts / Television & Radio"
  },
  {
    "Code": "YAF047050",
    "Literal": "YOUNG ADULT FICTION / Performing Arts / Theater & Musicals"
  },
  {
    "Code": "YAF046000",
    "Literal": "YOUNG ADULT FICTION / Places / General"
  },
  {
    "Code": "YAF046020",
    "Literal": "YOUNG ADULT FICTION / Places / Africa"
  },
  {
    "Code": "YAF046030",
    "Literal": "YOUNG ADULT FICTION / Places / Asia"
  },
  {
    "Code": "YAF046040",
    "Literal": "YOUNG ADULT FICTION / Places / Australia & Oceania"
  },
  {
    "Code": "YAF046050",
    "Literal": "YOUNG ADULT FICTION / Places / Canada"
  },
  {
    "Code": "YAF046060",
    "Literal": "YOUNG ADULT FICTION / Places / Caribbean & Latin America"
  },
  {
    "Code": "YAF046070",
    "Literal": "YOUNG ADULT FICTION / Places / Europe"
  },
  {
    "Code": "YAF046080",
    "Literal": "YOUNG ADULT FICTION / Places / Mexico"
  },
  {
    "Code": "YAF046090",
    "Literal": "YOUNG ADULT FICTION / Places / Middle East"
  },
  {
    "Code": "YAF046100",
    "Literal": "YOUNG ADULT FICTION / Places / Polar Regions"
  },
  {
    "Code": "YAF046110",
    "Literal": "YOUNG ADULT FICTION / Places / United States"
  },
  {
    "Code": "YAF048000",
    "Literal": "YOUNG ADULT FICTION / Poetry"
  },
  {
    "Code": "YAF049000",
    "Literal": "YOUNG ADULT FICTION / Politics & Government"
  },
  {
    "Code": "YAF050000",
    "Literal": "YOUNG ADULT FICTION / Recycling & Green Living"
  },
  {
    "Code": "YAF051000",
    "Literal": "YOUNG ADULT FICTION / Religious / General"
  },
  {
    "Code": "YAF051010",
    "Literal": "YOUNG ADULT FICTION / Religious / Agnostic & Atheist"
  },
  {
    "Code": "YAF051020",
    "Literal": "YOUNG ADULT FICTION / Religious / Buddhist"
  },
  {
    "Code": "YAF051030",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / General"
  },
  {
    "Code": "YAF051040",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Action & Adventure"
  },
  {
    "Code": "YAF051050",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Comics & Graphic Novels"
  },
  {
    "Code": "YAF051060",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Fantasy"
  },
  {
    "Code": "YAF051070",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Historical"
  },
  {
    "Code": "YAF051080",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Mysteries & Detective Stories"
  },
  {
    "Code": "YAF051090",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Relationships"
  },
  {
    "Code": "YAF051100",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Science Fiction"
  },
  {
    "Code": "YAF051110",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Social Issues"
  },
  {
    "Code": "YAF051120",
    "Literal": "YOUNG ADULT FICTION / Religious / Hindu"
  },
  {
    "Code": "YAF051130",
    "Literal": "YOUNG ADULT FICTION / Religious / Jewish"
  },
  {
    "Code": "YAF051140",
    "Literal": "YOUNG ADULT FICTION / Religious / Muslim"
  },
  {
    "Code": "YAF052000",
    "Literal": "YOUNG ADULT FICTION / Romance / General"
  },
  {
    "Code": "YAF052010",
    "Literal": "YOUNG ADULT FICTION / Romance / Clean & Wholesome"
  },
  {
    "Code": "YAF052020",
    "Literal": "YOUNG ADULT FICTION / Romance / Contemporary"
  },
  {
    "Code": "YAF052030",
    "Literal": "YOUNG ADULT FICTION / Romance / Historical"
  },
  {
    "Code": "YAF052080",
    "Literal": "YOUNG ADULT FICTION / Romance / Indigenous"
  },
  {
    "Code": "YAF052040",
    "Literal": "YOUNG ADULT FICTION / Romance / LGBTQ+"
  },
  {
    "Code": "YAF052070",
    "Literal": "YOUNG ADULT FICTION / Romance / Multicultural & Interracial"
  },
  {
    "Code": "YAF052050",
    "Literal": "YOUNG ADULT FICTION / Romance / Paranormal"
  },
  {
    "Code": "YAF052060",
    "Literal": "YOUNG ADULT FICTION / Romance / Romantic Comedy"
  },
  {
    "Code": "YAF053000",
    "Literal": "YOUNG ADULT FICTION / Royalty"
  },
  {
    "Code": "YAF027020",
    "Literal": "YOUNG ADULT FICTION / Satire"
  },
  {
    "Code": "YAF054000",
    "Literal": "YOUNG ADULT FICTION / School & Education / General"
  },
  {
    "Code": "YAF054010",
    "Literal": "YOUNG ADULT FICTION / School & Education / Boarding School & Prep School"
  },
  {
    "Code": "YAF054020",
    "Literal": "YOUNG ADULT FICTION / School & Education / College & University"
  },
  {
    "Code": "YAF043000",
    "Literal": "YOUNG ADULT FICTION / Science & Nature / General (see also headings under Animals)"
  },
  {
    "Code": "YAF043010",
    "Literal": "YOUNG ADULT FICTION / Science & Nature / Environment"
  },
  {
    "Code": "YAF056000",
    "Literal": "YOUNG ADULT FICTION / Science Fiction / General"
  },
  {
    "Code": "YAF056010",
    "Literal": "YOUNG ADULT FICTION / Science Fiction / Alien Contact"
  },
  {
    "Code": "YAF003000",
    "Literal": "YOUNG ADULT FICTION / Science Fiction / Apocalyptic & Post-Apocalyptic"
  },
  {
    "Code": "YAF056040",
    "Literal": "YOUNG ADULT FICTION / Science Fiction / Indigenous Futurism"
  },
  {
    "Code": "YAF056030",
    "Literal": "YOUNG ADULT FICTION / Science Fiction / Romance"
  },
  {
    "Code": "YAF056020",
    "Literal": "YOUNG ADULT FICTION / Science Fiction / Space Opera"
  },
  {
    "Code": "YAF063000",
    "Literal": "YOUNG ADULT FICTION / Science Fiction / Time Travel"
  },
  {
    "Code": "YAF057000",
    "Literal": "YOUNG ADULT FICTION / Short Stories, Collections & Anthologies"
  },
  {
    "Code": "YAF058000",
    "Literal": "YOUNG ADULT FICTION / Social Themes / General (see also headings under Family)"
  },
  {
    "Code": "YAF058280",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Activism & Social Justice"
  },
  {
    "Code": "YAF058010",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Assimilation"
  },
  {
    "Code": "YAF058020",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Bullying"
  },
  {
    "Code": "YAF058030",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Class Differences"
  },
  {
    "Code": "YAF058230",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Cutting & Self-Harm"
  },
  {
    "Code": "YAF058040",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Dating & Sex"
  },
  {
    "Code": "YAF058050",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Death, Grief, Bereavement"
  },
  {
    "Code": "YAF058060",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Depression"
  },
  {
    "Code": "YAF058080",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Drugs, Alcohol, Substance Abuse"
  },
  {
    "Code": "YAF058090",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Eating Disorders & Body Image"
  },
  {
    "Code": "YAF058100",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Emigration & Immigration"
  },
  {
    "Code": "YAF058110",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Emotions & Feelings"
  },
  {
    "Code": "YAF058120",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Friendship"
  },
  {
    "Code": "YAF058140",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Mental Illness"
  },
  {
    "Code": "YAF058150",
    "Literal": "YOUNG ADULT FICTION / Social Themes / New Experience"
  },
  {
    "Code": "YAF058160",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Peer Pressure"
  },
  {
    "Code": "YAF058170",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Physical & Emotional Abuse (see also Social Themes / Sexual Abuse)"
  },
  {
    "Code": "YAF058130",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Poverty & Homelessness"
  },
  {
    "Code": "YAF058180",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Pregnancy"
  },
  {
    "Code": "YAF058190",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Prejudice & Racism"
  },
  {
    "Code": "YAF058200",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Religion & Faith"
  },
  {
    "Code": "YAF058210",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Runaways"
  },
  {
    "Code": "YAF058220",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Self-Esteem & Self-Reliance"
  },
  {
    "Code": "YAF058240",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Sexual Abuse"
  },
  {
    "Code": "YAF058250",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Suicide"
  },
  {
    "Code": "YAF058260",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Values & Virtues"
  },
  {
    "Code": "YAF058270",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Violence"
  },
  {
    "Code": "YAF059000",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / General"
  },
  {
    "Code": "YAF059010",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Baseball & Softball"
  },
  {
    "Code": "YAF059020",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Basketball"
  },
  {
    "Code": "YAF059030",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Camping & Outdoor Activities"
  },
  {
    "Code": "YAF059040",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Equestrian"
  },
  {
    "Code": "YAF059050",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Extreme Sports"
  },
  {
    "Code": "YAF059060",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Football"
  },
  {
    "Code": "YAF059070",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Gymnastics"
  },
  {
    "Code": "YAF059080",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Hockey"
  },
  {
    "Code": "YAF059090",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Martial Arts"
  },
  {
    "Code": "YAF059100",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Skateboarding"
  },
  {
    "Code": "YAF059110",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Soccer"
  },
  {
    "Code": "YAF059120",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Track & Field"
  },
  {
    "Code": "YAF059130",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Water Sports"
  },
  {
    "Code": "YAF059140",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Winter Sports"
  },
  {
    "Code": "YAF060000",
    "Literal": "YOUNG ADULT FICTION / Steampunk"
  },
  {
    "Code": "YAF061000",
    "Literal": "YOUNG ADULT FICTION / Superheroes"
  },
  {
    "Code": "YAF055000",
    "Literal": "YOUNG ADULT FICTION / Technology"
  },
  {
    "Code": "YAF062000",
    "Literal": "YOUNG ADULT FICTION / Thrillers & Suspense / General"
  },
  {
    "Code": "YAF062010",
    "Literal": "YOUNG ADULT FICTION / Thrillers & Suspense / Crime"
  },
  {
    "Code": "YAF062020",
    "Literal": "YOUNG ADULT FICTION / Thrillers & Suspense / Espionage"
  },
  {
    "Code": "YAF062030",
    "Literal": "YOUNG ADULT FICTION / Thrillers & Suspense / Psychological"
  },
  {
    "Code": "YAF062040",
    "Literal": "YOUNG ADULT FICTION / Thrillers & Suspense / Supernatural"
  },
  {
    "Code": "YAF064000",
    "Literal": "YOUNG ADULT FICTION / Travel & Transportation / General"
  },
  {
    "Code": "YAF064010",
    "Literal": "YOUNG ADULT FICTION / Travel & Transportation / Car & Road Trips"
  },
  {
    "Code": "YAF075000",
    "Literal": "YOUNG ADULT FICTION / Urban & Street Lit"
  },
  {
    "Code": "YAF065000",
    "Literal": "YOUNG ADULT FICTION / Vampires"
  },
  {
    "Code": "YAF066000",
    "Literal": "YOUNG ADULT FICTION / Visionary & Metaphysical"
  },
  {
    "Code": "YAF067000",
    "Literal": "YOUNG ADULT FICTION / War & Military"
  },
  {
    "Code": "YAF068000",
    "Literal": "YOUNG ADULT FICTION / Werewolves & Shifters"
  },
  {
    "Code": "YAF069000",
    "Literal": "YOUNG ADULT FICTION / Westerns"
  },
  {
    "Code": "YAF070000",
    "Literal": "YOUNG ADULT FICTION / Zombies"
  },
  {
    "Code": "YAN000000",
    "Literal": "YOUNG ADULT NONFICTION / General"
  },
  {
    "Code": "YAN058000",
    "Literal": "YOUNG ADULT NONFICTION / Activism & Social Justice"
  },
  {
    "Code": "YAN001000",
    "Literal": "YOUNG ADULT NONFICTION / Activity Books"
  },
  {
    "Code": "YAN002000",
    "Literal": "YOUNG ADULT NONFICTION / Adventure & Adventurers"
  },
  {
    "Code": "YAN038110",
    "Literal": "YOUNG ADULT NONFICTION / African American & Black"
  },
  {
    "Code": "YAN003000",
    "Literal": "YOUNG ADULT NONFICTION / Animals / General"
  },
  {
    "Code": "YAN003010",
    "Literal": "YOUNG ADULT NONFICTION / Animals / Animal Welfare"
  },
  {
    "Code": "YAN003020",
    "Literal": "YOUNG ADULT NONFICTION / Animals / Birds"
  },
  {
    "Code": "YAN003030",
    "Literal": "YOUNG ADULT NONFICTION / Animals / Marine Life"
  },
  {
    "Code": "YAN004000",
    "Literal": "YOUNG ADULT NONFICTION / Architecture"
  },
  {
    "Code": "YAN005000",
    "Literal": "YOUNG ADULT NONFICTION / Art / General"
  },
  {
    "Code": "YAN005010",
    "Literal": "YOUNG ADULT NONFICTION / Art / Cartooning"
  },
  {
    "Code": "YAN005020",
    "Literal": "YOUNG ADULT NONFICTION / Art / Drawing"
  },
  {
    "Code": "YAN005030",
    "Literal": "YOUNG ADULT NONFICTION / Art / Fashion"
  },
  {
    "Code": "YAN005040",
    "Literal": "YOUNG ADULT NONFICTION / Art / History"
  },
  {
    "Code": "YAN005050",
    "Literal": "YOUNG ADULT NONFICTION / Art / Painting"
  },
  {
    "Code": "YAN005060",
    "Literal": "YOUNG ADULT NONFICTION / Art / Sculpture"
  },
  {
    "Code": "YAN005070",
    "Literal": "YOUNG ADULT NONFICTION / Art / Techniques"
  },
  {
    "Code": "YAN038120",
    "Literal": "YOUNG ADULT NONFICTION / Asian American & Pacific Islander"
  },
  {
    "Code": "YAN006000",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / General"
  },
  {
    "Code": "YAN006010",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Art"
  },
  {
    "Code": "YAN006020",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Cultural & Regional"
  },
  {
    "Code": "YAN006030",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Historical"
  },
  {
    "Code": "YAN006150",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / LGBTQ+"
  },
  {
    "Code": "YAN006040",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Literary"
  },
  {
    "Code": "YAN006050",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Music"
  },
  {
    "Code": "YAN006060",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Performing Arts"
  },
  {
    "Code": "YAN006070",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Political"
  },
  {
    "Code": "YAN006080",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Presidents & First Families (U.S.)"
  },
  {
    "Code": "YAN006090",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Religious"
  },
  {
    "Code": "YAN006100",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Royalty"
  },
  {
    "Code": "YAN006110",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Science & Technology"
  },
  {
    "Code": "YAN006120",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Social Activists"
  },
  {
    "Code": "YAN006130",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Sports & Recreation"
  },
  {
    "Code": "YAN006140",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Women"
  },
  {
    "Code": "YAN062000",
    "Literal": "YOUNG ADULT NONFICTION / Biracial & Multiracial"
  },
  {
    "Code": "YAN008000",
    "Literal": "YOUNG ADULT NONFICTION / Books & Libraries"
  },
  {
    "Code": "YAN009000",
    "Literal": "YOUNG ADULT NONFICTION / Boys & Men"
  },
  {
    "Code": "YAN010000",
    "Literal": "YOUNG ADULT NONFICTION / Business & Economics"
  },
  {
    "Code": "YAN011000",
    "Literal": "YOUNG ADULT NONFICTION / Careers"
  },
  {
    "Code": "YAN012000",
    "Literal": "YOUNG ADULT NONFICTION / Comics & Graphic Novels / General"
  },
  {
    "Code": "YAN012010",
    "Literal": "YOUNG ADULT NONFICTION / Comics & Graphic Novels / Biography"
  },
  {
    "Code": "YAN012020",
    "Literal": "YOUNG ADULT NONFICTION / Comics & Graphic Novels / History"
  },
  {
    "Code": "YAN012030",
    "Literal": "YOUNG ADULT NONFICTION / Comics & Graphic Novels / Science & Nature"
  },
  {
    "Code": "YAN012040",
    "Literal": "YOUNG ADULT NONFICTION / Comics & Graphic Novels / Social Topics"
  },
  {
    "Code": "YAN013000",
    "Literal": "YOUNG ADULT NONFICTION / Computers / General"
  },
  {
    "Code": "YAN013030",
    "Literal": "YOUNG ADULT NONFICTION / Computers / Coding & Programming"
  },
  {
    "Code": "YAN013010",
    "Literal": "YOUNG ADULT NONFICTION / Computers / Entertainment & Games"
  },
  {
    "Code": "YAN013020",
    "Literal": "YOUNG ADULT NONFICTION / Computers / Internet & Social Media"
  },
  {
    "Code": "YAN013040",
    "Literal": "YOUNG ADULT NONFICTION / Computers / Software"
  },
  {
    "Code": "YAN014000",
    "Literal": "YOUNG ADULT NONFICTION / Cooking & Food"
  },
  {
    "Code": "YAN015000",
    "Literal": "YOUNG ADULT NONFICTION / Crafts & Hobbies"
  },
  {
    "Code": "YAN016000",
    "Literal": "YOUNG ADULT NONFICTION / Curiosities & Wonders"
  },
  {
    "Code": "YAN051230",
    "Literal": "YOUNG ADULT NONFICTION / Disabilities"
  },
  {
    "Code": "YAN061000",
    "Literal": "YOUNG ADULT NONFICTION / Diversity & Multicultural"
  },
  {
    "Code": "YAN017000",
    "Literal": "YOUNG ADULT NONFICTION / Drama"
  },
  {
    "Code": "YAN018000",
    "Literal": "YOUNG ADULT NONFICTION / Family / General (see also headings under Social Topics)"
  },
  {
    "Code": "YAN018010",
    "Literal": "YOUNG ADULT NONFICTION / Family / Adoption"
  },
  {
    "Code": "YAN018020",
    "Literal": "YOUNG ADULT NONFICTION / Family / Alternative Family"
  },
  {
    "Code": "YAN018080",
    "Literal": "YOUNG ADULT NONFICTION / Family / Blended Families"
  },
  {
    "Code": "YAN018030",
    "Literal": "YOUNG ADULT NONFICTION / Family / Marriage & Divorce"
  },
  {
    "Code": "YAN018040",
    "Literal": "YOUNG ADULT NONFICTION / Family / Multigenerational"
  },
  {
    "Code": "YAN018050",
    "Literal": "YOUNG ADULT NONFICTION / Family / Orphans & Foster Homes"
  },
  {
    "Code": "YAN018060",
    "Literal": "YOUNG ADULT NONFICTION / Family / Parents"
  },
  {
    "Code": "YAN018070",
    "Literal": "YOUNG ADULT NONFICTION / Family / Siblings"
  },
  {
    "Code": "YAN019000",
    "Literal": "YOUNG ADULT NONFICTION / Fashion"
  },
  {
    "Code": "YAN063000",
    "Literal": "YOUNG ADULT NONFICTION / First Nations"
  },
  {
    "Code": "YAN021000",
    "Literal": "YOUNG ADULT NONFICTION / Games & Activities / General"
  },
  {
    "Code": "YAN021010",
    "Literal": "YOUNG ADULT NONFICTION / Games & Activities / Puzzles & Word Games"
  },
  {
    "Code": "YAN021020",
    "Literal": "YOUNG ADULT NONFICTION / Games & Activities / Questions & Answers"
  },
  {
    "Code": "YAN022000",
    "Literal": "YOUNG ADULT NONFICTION / Gardening"
  },
  {
    "Code": "YAN023000",
    "Literal": "YOUNG ADULT NONFICTION / Girls & Women"
  },
  {
    "Code": "YAN024000",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / General"
  },
  {
    "Code": "YAN024010",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Beauty & Grooming"
  },
  {
    "Code": "YAN024020",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Diet & Nutrition"
  },
  {
    "Code": "YAN024030",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Diseases, Illnesses & Injuries"
  },
  {
    "Code": "YAN024040",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Fitness & Exercise"
  },
  {
    "Code": "YAN024050",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Maturing"
  },
  {
    "Code": "YAN024100",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Mental Health"
  },
  {
    "Code": "YAN024090",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Mindfulness & Meditation"
  },
  {
    "Code": "YAN024060",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Physical Impairments"
  },
  {
    "Code": "YAN024070",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Safety"
  },
  {
    "Code": "YAN024080",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Sexuality & Pregnancy"
  },
  {
    "Code": "YAN038130",
    "Literal": "YOUNG ADULT NONFICTION / Hispanic & Latino"
  },
  {
    "Code": "YAN025000",
    "Literal": "YOUNG ADULT NONFICTION / History / General"
  },
  {
    "Code": "YAN025010",
    "Literal": "YOUNG ADULT NONFICTION / History / Africa"
  },
  {
    "Code": "YAN025020",
    "Literal": "YOUNG ADULT NONFICTION / History / Ancient"
  },
  {
    "Code": "YAN025030",
    "Literal": "YOUNG ADULT NONFICTION / History / Asia"
  },
  {
    "Code": "YAN025040",
    "Literal": "YOUNG ADULT NONFICTION / History / Australia & Oceania"
  },
  {
    "Code": "YAN025050",
    "Literal": "YOUNG ADULT NONFICTION / History / Canada"
  },
  {
    "Code": "YAN025060",
    "Literal": "YOUNG ADULT NONFICTION / History / Central & South America"
  },
  {
    "Code": "YAN025070",
    "Literal": "YOUNG ADULT NONFICTION / History / Europe"
  },
  {
    "Code": "YAN025080",
    "Literal": "YOUNG ADULT NONFICTION / History / Exploration & Discovery"
  },
  {
    "Code": "YAN025090",
    "Literal": "YOUNG ADULT NONFICTION / History / Holocaust"
  },
  {
    "Code": "YAN025100",
    "Literal": "YOUNG ADULT NONFICTION / History / Medieval"
  },
  {
    "Code": "YAN025110",
    "Literal": "YOUNG ADULT NONFICTION / History / Mexico"
  },
  {
    "Code": "YAN025120",
    "Literal": "YOUNG ADULT NONFICTION / History / Middle East"
  },
  {
    "Code": "YAN025130",
    "Literal": "YOUNG ADULT NONFICTION / History / Military & Wars"
  },
  {
    "Code": "YAN025140",
    "Literal": "YOUNG ADULT NONFICTION / History / Modern"
  },
  {
    "Code": "YAN025150",
    "Literal": "YOUNG ADULT NONFICTION / History / Prehistoric"
  },
  {
    "Code": "YAN025160",
    "Literal": "YOUNG ADULT NONFICTION / History / Renaissance"
  },
  {
    "Code": "YAN025170",
    "Literal": "YOUNG ADULT NONFICTION / History / United States / General"
  },
  {
    "Code": "YAN025180",
    "Literal": "YOUNG ADULT NONFICTION / History / United States / State & Local"
  },
  {
    "Code": "YAN025190",
    "Literal": "YOUNG ADULT NONFICTION / History / United States / Colonial & Revolutionary Periods"
  },
  {
    "Code": "YAN025200",
    "Literal": "YOUNG ADULT NONFICTION / History / United States / 19th Century"
  },
  {
    "Code": "YAN025210",
    "Literal": "YOUNG ADULT NONFICTION / History / United States / Civil War Period (1850-1877)"
  },
  {
    "Code": "YAN025220",
    "Literal": "YOUNG ADULT NONFICTION / History / United States / 20th Century"
  },
  {
    "Code": "YAN025230",
    "Literal": "YOUNG ADULT NONFICTION / History / United States / 21st Century"
  },
  {
    "Code": "YAN026000",
    "Literal": "YOUNG ADULT NONFICTION / Holidays & Celebrations"
  },
  {
    "Code": "YAN027000",
    "Literal": "YOUNG ADULT NONFICTION / House & Home"
  },
  {
    "Code": "YAN028000",
    "Literal": "YOUNG ADULT NONFICTION / Humor"
  },
  {
    "Code": "YAN038010",
    "Literal": "YOUNG ADULT NONFICTION / Indigenous / General"
  },
  {
    "Code": "YAN038170",
    "Literal": "YOUNG ADULT NONFICTION / Indigenous / Elders"
  },
  {
    "Code": "YAN038180",
    "Literal": "YOUNG ADULT NONFICTION / Indigenous / Family Life"
  },
  {
    "Code": "YAN038190",
    "Literal": "YOUNG ADULT NONFICTION / Indigenous / Health & Healing"
  },
  {
    "Code": "YAN038200",
    "Literal": "YOUNG ADULT NONFICTION / Indigenous / History"
  },
  {
    "Code": "YAN038210",
    "Literal": "YOUNG ADULT NONFICTION / Indigenous / Land-Based Knowledge"
  },
  {
    "Code": "YAN038220",
    "Literal": "YOUNG ADULT NONFICTION / Indigenous / Reconciliation"
  },
  {
    "Code": "YAN038160",
    "Literal": "YOUNG ADULT NONFICTION / Indigenous Peoples of Turtle Island"
  },
  {
    "Code": "YAN029000",
    "Literal": "YOUNG ADULT NONFICTION / Inspirational & Personal Growth"
  },
  {
    "Code": "YAN064000",
    "Literal": "YOUNG ADULT NONFICTION / Inuit"
  },
  {
    "Code": "YAN030000",
    "Literal": "YOUNG ADULT NONFICTION / Language Arts / General"
  },
  {
    "Code": "YAN030010",
    "Literal": "YOUNG ADULT NONFICTION / Language Arts / Communication"
  },
  {
    "Code": "YAN030020",
    "Literal": "YOUNG ADULT NONFICTION / Language Arts / Composition & Creative Writing"
  },
  {
    "Code": "YAN030030",
    "Literal": "YOUNG ADULT NONFICTION / Language Arts / Grammar"
  },
  {
    "Code": "YAN030040",
    "Literal": "YOUNG ADULT NONFICTION / Language Arts / Journal Writing"
  },
  {
    "Code": "YAN030050",
    "Literal": "YOUNG ADULT NONFICTION / Language Arts / Vocabulary & Spelling"
  },
  {
    "Code": "YAN020000",
    "Literal": "YOUNG ADULT NONFICTION / Language Study / General"
  },
  {
    "Code": "YAN020010",
    "Literal": "YOUNG ADULT NONFICTION / Language Study / English as a Second Language"
  },
  {
    "Code": "YAN020020",
    "Literal": "YOUNG ADULT NONFICTION / Language Study / French"
  },
  {
    "Code": "YAN020040",
    "Literal": "YOUNG ADULT NONFICTION / Language Study / Indigenous Languages in the Americas"
  },
  {
    "Code": "YAN020030",
    "Literal": "YOUNG ADULT NONFICTION / Language Study / Spanish"
  },
  {
    "Code": "YAN031000",
    "Literal": "YOUNG ADULT NONFICTION / Law & Crime"
  },
  {
    "Code": "YAN032000",
    "Literal": "YOUNG ADULT NONFICTION / LGBTQ+"
  },
  {
    "Code": "YAN033000",
    "Literal": "YOUNG ADULT NONFICTION / Literary Criticism & Collections"
  },
  {
    "Code": "YAN034000",
    "Literal": "YOUNG ADULT NONFICTION / Mathematics / General"
  },
  {
    "Code": "YAN034010",
    "Literal": "YOUNG ADULT NONFICTION / Mathematics / Algebra"
  },
  {
    "Code": "YAN034020",
    "Literal": "YOUNG ADULT NONFICTION / Mathematics / Geometry"
  },
  {
    "Code": "YAN035000",
    "Literal": "YOUNG ADULT NONFICTION / Media Studies"
  },
  {
    "Code": "YAN036000",
    "Literal": "YOUNG ADULT NONFICTION / Media Tie-In"
  },
  {
    "Code": "YAN065000",
    "Literal": "YOUNG ADULT NONFICTION / Métis"
  },
  {
    "Code": "YAN038150",
    "Literal": "YOUNG ADULT NONFICTION / Middle Eastern & Arab American"
  },
  {
    "Code": "YAN037000",
    "Literal": "YOUNG ADULT NONFICTION / Music / General"
  },
  {
    "Code": "YAN037010",
    "Literal": "YOUNG ADULT NONFICTION / Music / History"
  },
  {
    "Code": "YAN037020",
    "Literal": "YOUNG ADULT NONFICTION / Music / Instruction & Study"
  },
  {
    "Code": "YAN037030",
    "Literal": "YOUNG ADULT NONFICTION / Music / Popular"
  },
  {
    "Code": "YAN037040",
    "Literal": "YOUNG ADULT NONFICTION / Music / Rap & Hip Hop"
  },
  {
    "Code": "YAN037050",
    "Literal": "YOUNG ADULT NONFICTION / Music / Rock"
  },
  {
    "Code": "YAN038140",
    "Literal": "YOUNG ADULT NONFICTION / Native American"
  },
  {
    "Code": "YAN059000",
    "Literal": "YOUNG ADULT NONFICTION / Neurodiversity"
  },
  {
    "Code": "YAN007000",
    "Literal": "YOUNG ADULT NONFICTION / Paranormal & Supernatural"
  },
  {
    "Code": "YAN039000",
    "Literal": "YOUNG ADULT NONFICTION / Performing Arts / General"
  },
  {
    "Code": "YAN039010",
    "Literal": "YOUNG ADULT NONFICTION / Performing Arts / Dance"
  },
  {
    "Code": "YAN039020",
    "Literal": "YOUNG ADULT NONFICTION / Performing Arts / Film"
  },
  {
    "Code": "YAN039030",
    "Literal": "YOUNG ADULT NONFICTION / Performing Arts / Television & Radio"
  },
  {
    "Code": "YAN039040",
    "Literal": "YOUNG ADULT NONFICTION / Performing Arts / Theater & Musicals"
  },
  {
    "Code": "YAN040000",
    "Literal": "YOUNG ADULT NONFICTION / Personal Finance"
  },
  {
    "Code": "YAN041000",
    "Literal": "YOUNG ADULT NONFICTION / Philosophy"
  },
  {
    "Code": "YAN042000",
    "Literal": "YOUNG ADULT NONFICTION / Photography"
  },
  {
    "Code": "YAN038000",
    "Literal": "YOUNG ADULT NONFICTION / Places / General"
  },
  {
    "Code": "YAN038020",
    "Literal": "YOUNG ADULT NONFICTION / Places / Africa"
  },
  {
    "Code": "YAN038030",
    "Literal": "YOUNG ADULT NONFICTION / Places / Asia"
  },
  {
    "Code": "YAN038040",
    "Literal": "YOUNG ADULT NONFICTION / Places / Australia & Oceania"
  },
  {
    "Code": "YAN038050",
    "Literal": "YOUNG ADULT NONFICTION / Places / Canada"
  },
  {
    "Code": "YAN038060",
    "Literal": "YOUNG ADULT NONFICTION / Places / Caribbean & Latin America"
  },
  {
    "Code": "YAN038070",
    "Literal": "YOUNG ADULT NONFICTION / Places / Europe"
  },
  {
    "Code": "YAN038080",
    "Literal": "YOUNG ADULT NONFICTION / Places / Mexico"
  },
  {
    "Code": "YAN038090",
    "Literal": "YOUNG ADULT NONFICTION / Places / Middle East"
  },
  {
    "Code": "YAN038100",
    "Literal": "YOUNG ADULT NONFICTION / Places / United States"
  },
  {
    "Code": "YAN043000",
    "Literal": "YOUNG ADULT NONFICTION / Poetry"
  },
  {
    "Code": "YAN044000",
    "Literal": "YOUNG ADULT NONFICTION / Public Speaking & Presentation"
  },
  {
    "Code": "YAN045000",
    "Literal": "YOUNG ADULT NONFICTION / Recycling & Green Living"
  },
  {
    "Code": "YAN046000",
    "Literal": "YOUNG ADULT NONFICTION / Reference"
  },
  {
    "Code": "YAN047000",
    "Literal": "YOUNG ADULT NONFICTION / Religion / General"
  },
  {
    "Code": "YAN047010",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Agnosticism & Atheism"
  },
  {
    "Code": "YAN047020",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Biblical Stories & Studies"
  },
  {
    "Code": "YAN047030",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Buddhism"
  },
  {
    "Code": "YAN047040",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Christianity"
  },
  {
    "Code": "YAN047050",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Eastern"
  },
  {
    "Code": "YAN047060",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Hinduism"
  },
  {
    "Code": "YAN047090",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Indigenous / General"
  },
  {
    "Code": "YAN047100",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Indigenous / Ceremony & Spiritual Practice"
  },
  {
    "Code": "YAN047110",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Indigenous / Sacred Stories"
  },
  {
    "Code": "YAN047120",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Indigenous / Scripture & Prayerbooks"
  },
  {
    "Code": "YAN047130",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Indigenous / Spirituality"
  },
  {
    "Code": "YAN047140",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Indigenous / Teachings & Traditions"
  },
  {
    "Code": "YAN047070",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Islam"
  },
  {
    "Code": "YAN047080",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Judaism"
  },
  {
    "Code": "YAN048000",
    "Literal": "YOUNG ADULT NONFICTION / Religious / Christian / General"
  },
  {
    "Code": "YAN048010",
    "Literal": "YOUNG ADULT NONFICTION / Religious / Christian / Dating"
  },
  {
    "Code": "YAN048020",
    "Literal": "YOUNG ADULT NONFICTION / Religious / Christian / Devotional & Prayer"
  },
  {
    "Code": "YAN048030",
    "Literal": "YOUNG ADULT NONFICTION / Religious / Christian / Family & Relationships"
  },
  {
    "Code": "YAN048040",
    "Literal": "YOUNG ADULT NONFICTION / Religious / Christian / Inspirational"
  },
  {
    "Code": "YAN049000",
    "Literal": "YOUNG ADULT NONFICTION / School & Education"
  },
  {
    "Code": "YAN050000",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / General (see also headings under Animals or Technology)"
  },
  {
    "Code": "YAN050010",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Anatomy & Physiology"
  },
  {
    "Code": "YAN050020",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Astronomy"
  },
  {
    "Code": "YAN050030",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Biology"
  },
  {
    "Code": "YAN050040",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Botany"
  },
  {
    "Code": "YAN050050",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Chemistry"
  },
  {
    "Code": "YAN050060",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Disasters"
  },
  {
    "Code": "YAN050070",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Earth Sciences"
  },
  {
    "Code": "YAN050080",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Environmental Conservation & Protection"
  },
  {
    "Code": "YAN050090",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Environmental Science & Ecosystems"
  },
  {
    "Code": "YAN050100",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Experiments & Projects"
  },
  {
    "Code": "YAN050110",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / History of Science"
  },
  {
    "Code": "YAN050120",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Physics"
  },
  {
    "Code": "YAN050130",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Zoology"
  },
  {
    "Code": "YAN052000",
    "Literal": "YOUNG ADULT NONFICTION / Social Science / General"
  },
  {
    "Code": "YAN052010",
    "Literal": "YOUNG ADULT NONFICTION / Social Science / Archaeology"
  },
  {
    "Code": "YAN052020",
    "Literal": "YOUNG ADULT NONFICTION / Social Science / Customs, Traditions, Anthropology"
  },
  {
    "Code": "YAN052030",
    "Literal": "YOUNG ADULT NONFICTION / Social Science / Folklore & Mythology"
  },
  {
    "Code": "YAN052040",
    "Literal": "YOUNG ADULT NONFICTION / Social Science / Politics & Government"
  },
  {
    "Code": "YAN052050",
    "Literal": "YOUNG ADULT NONFICTION / Social Science / Psychology"
  },
  {
    "Code": "YAN052060",
    "Literal": "YOUNG ADULT NONFICTION / Social Science / Sociology"
  },
  {
    "Code": "YAN051000",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / General (see also headings under Family)"
  },
  {
    "Code": "YAN051010",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Assimilation"
  },
  {
    "Code": "YAN051020",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Bullying"
  },
  {
    "Code": "YAN051270",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Civil & Human Rights"
  },
  {
    "Code": "YAN051030",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Class Differences"
  },
  {
    "Code": "YAN051280",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Compulsive Behavior"
  },
  {
    "Code": "YAN051210",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Cutting & Self-Harm"
  },
  {
    "Code": "YAN051040",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Dating & Sex"
  },
  {
    "Code": "YAN051050",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Death, Grief, Bereavement"
  },
  {
    "Code": "YAN051060",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Depression & Mental Illness"
  },
  {
    "Code": "YAN051070",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Drugs, Alcohol, Substance Abuse"
  },
  {
    "Code": "YAN051080",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Eating Disorders & Body Image"
  },
  {
    "Code": "YAN051090",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Emigration & Immigration"
  },
  {
    "Code": "YAN051100",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Emotions & Feelings"
  },
  {
    "Code": "YAN051110",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Friendship"
  },
  {
    "Code": "YAN051140",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Manners & Etiquette"
  },
  {
    "Code": "YAN051150",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Peer Pressure"
  },
  {
    "Code": "YAN051160",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Physical & Emotional Abuse (see also Social Topics / Sexual Abuse)"
  },
  {
    "Code": "YAN051120",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Poverty & Homelessness"
  },
  {
    "Code": "YAN051170",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Pregnancy"
  },
  {
    "Code": "YAN051180",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Prejudice & Racism"
  },
  {
    "Code": "YAN051190",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Runaways"
  },
  {
    "Code": "YAN051200",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Self-Esteem & Self-Reliance"
  },
  {
    "Code": "YAN051220",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Sexual Abuse"
  },
  {
    "Code": "YAN051240",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Suicide"
  },
  {
    "Code": "YAN051250",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Values & Virtues"
  },
  {
    "Code": "YAN051260",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Violence"
  },
  {
    "Code": "YAN053000",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / General"
  },
  {
    "Code": "YAN053010",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Baseball & Softball"
  },
  {
    "Code": "YAN053020",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Basketball"
  },
  {
    "Code": "YAN053030",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Camping & Outdoor Activities"
  },
  {
    "Code": "YAN053040",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Extreme Sports"
  },
  {
    "Code": "YAN053050",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Football"
  },
  {
    "Code": "YAN053060",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Hockey"
  },
  {
    "Code": "YAN053070",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Martial Arts"
  },
  {
    "Code": "YAN053080",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Motor Sports"
  },
  {
    "Code": "YAN053090",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Olympics & Paralympics"
  },
  {
    "Code": "YAN053100",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Soccer"
  },
  {
    "Code": "YAN053110",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Track & Field"
  },
  {
    "Code": "YAN053120",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Winter Sports"
  },
  {
    "Code": "YAN054000",
    "Literal": "YOUNG ADULT NONFICTION / Study Aids / General"
  },
  {
    "Code": "YAN054010",
    "Literal": "YOUNG ADULT NONFICTION / Study Aids / Book Notes (see also STUDY AIDS / Book Notes)"
  },
  {
    "Code": "YAN054020",
    "Literal": "YOUNG ADULT NONFICTION / Study Aids / Test Preparation"
  },
  {
    "Code": "YAN055000",
    "Literal": "YOUNG ADULT NONFICTION / Technology / General"
  },
  {
    "Code": "YAN055010",
    "Literal": "YOUNG ADULT NONFICTION / Technology / Aeronautics, Astronautics & Space Science"
  },
  {
    "Code": "YAN055020",
    "Literal": "YOUNG ADULT NONFICTION / Technology / Agriculture"
  },
  {
    "Code": "YAN055030",
    "Literal": "YOUNG ADULT NONFICTION / Technology / Electricity & Electronics"
  },
  {
    "Code": "YAN055040",
    "Literal": "YOUNG ADULT NONFICTION / Technology / Inventions"
  },
  {
    "Code": "YAN055050",
    "Literal": "YOUNG ADULT NONFICTION / Technology / Machinery & Tools"
  },
  {
    "Code": "YAN055060",
    "Literal": "YOUNG ADULT NONFICTION / Technology / Robotics"
  },
  {
    "Code": "YAN056000",
    "Literal": "YOUNG ADULT NONFICTION / Transportation / General"
  },
  {
    "Code": "YAN056010",
    "Literal": "YOUNG ADULT NONFICTION / Transportation / Aviation"
  },
  {
    "Code": "YAN056020",
    "Literal": "YOUNG ADULT NONFICTION / Transportation / Boats, Ships & Underwater Craft"
  },
  {
    "Code": "YAN056030",
    "Literal": "YOUNG ADULT NONFICTION / Transportation / Cars & Trucks"
  },
  {
    "Code": "YAN057000",
    "Literal": "YOUNG ADULT NONFICTION / Travel"
  },
  {
    "Code": "YAN060000",
    "Literal": "YOUNG ADULT NONFICTION / Volunteering"
  },
  {
    "Code": "NON000000",
    "Literal": "NON-CLASSIFIABLE"
  }
];