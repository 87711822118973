import React from 'react';
import { Link } from 'react-router-dom';
import styles from './wishlist.module.css';
import { messageState } from '../../atoms';
import { useSetRecoilState } from 'recoil';

export default function WishlistLink({ loggedIn }) {
  const setMessage = useSetRecoilState(messageState);

  return (
    <div className={styles.wishlistButton}>
      {loggedIn ? <Link to='/account/wishlist'><i className='fa-solid fa-bookmark'></i></Link> :
        <button onClick={() => {
          setMessage({ type: 'info', label: 'Got your eye on something?', text: `<a href='/login'>Login on create an account to add items to your wishlist!</a>`, img: null, temp: false, cart: false });
        }}><i className='fa-solid fa-bookmark'></i></button>
      }
    </div>
  )
}