import React, { useEffect, useState } from 'react';
import { makeRequest } from '../../Utils';
import styles from './blogblock.module.css';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function BlogBlock({ posts }) {

  return (
    <div>
      <div className={`newHead`}>
        <h2>Latest from the Blog</h2>
      </div>
      <div className={styles.blogBlock}>
        {posts.map((post) => {
          return (
            <div key={post._id} className={styles.blogBlock_post}>
              <h3><Link to={`/blog/post/${post.slug}`}>{post.title}</Link></h3>
              <div className={styles.blogMeta}>{post.author} | {moment(post.publishDate).format('LL')}</div>
              <div className={styles.blogSummary}>{post.summary}</div>
              <Link className={styles.readMore} to={`/blog/post/${post.slug}`}>Read More</Link>
            </div>
          )
        })}
      </div>

    </div>
  )
}