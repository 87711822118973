import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { userState, cartOpenState, menuOpenState, sessionState, pageviewState } from '../../atoms/';
import Cart from '../Cart';
import { ReactComponent as Wordmark } from '../../icons/wordmark_1.svg';
import './style.css';
import Search from '../Search';
import { analyticsEvent, asaEvent } from '../../Utils';
import AdminHeaderMenu from '../AdminHeaderMenu';
import MegaMenu from '../MegaMenu';
import CartCounter from './CartCounter';
import WishlistLink from './WishlistLink';

export default function Header() {
  const user = useRecoilValue(userState);
  const session = useRecoilValue(sessionState);
  const pageview = useRecoilValue(pageviewState);
  const [cartOpen, setCartOpen] = useRecoilState(cartOpenState);
  const [menuOpen, setMenuOpen] = useRecoilState(menuOpenState);
  const [bigMenuOpen, setBigMenuOpen] = useState(false);

  let location = useLocation();
  let navigate = useNavigate();

  const userId = useRef(null);

  useEffect(() => {
    if (userId.current && !user.id) {
      localStorage.setItem('asterismToken', '');
      if (location.pathname.includes('/account')) {
        navigate('/login');
      } else if (location.pathname.includes('/pub')) {
        navigate('/pub/login');
      }

    }
    userId.current = user.id;
  }, [user]);

  useEffect(() => {
    setMenuOpen(false);
    window.scrollTo(0, 0);
  }, [location])

  const toggleCart = () => {
    setCartOpen(!cartOpen);
    if (!cartOpen) {
      analyticsEvent('cartClick', { location: 'header' });
      asaEvent({
        session: session.id,
        category: 'cart',
        action: 'cartOpen',
        label: 'header',
        locationOne: '',
        locationTwo: '',
        locationThree: '',
        testgroup: session.group,
        pagetype: pageview.pagetype,
        pageview: pageview.id,
        campaign: session.campaign,
      });
    }
  }

  const menuClick = (label) => {
    asaEvent({
      session: session.id,
      category: 'menu',
      action: 'click',
      label: 'oldMenu',
      locationOne: label,
      locationTwo: '',
      locationThree: '',
      testgroup: session.group,
      pagetype: pageview.pagetype,
      pageview: pageview.id,
      campaign: session.campaign,
      value: 1
    });
  }


  return (
    <>
      <MegaMenu open={bigMenuOpen} close={() => { setBigMenuOpen(false) }} />
      <div className='stickyContainer'>
        {user.admin && <AdminHeaderMenu />}
        <header className='flex-layout headerContainer'>
          <div className='headerLeft'>
            <button className='megaMenuIcon' onClick={() => { setBigMenuOpen(!bigMenuOpen) }}><i class="fa-solid fa-bars"></i></button>
            <div className='headerLogo'>
              <Link aria-label='Asterism Books Logo' className='headerLogo__link' tabIndex={0} to='/'><Wordmark className='headerLogo__image' /></Link>
            </div>
          </div>
          <Search />
          <div className='headerRight'>
            <div className='userMenu'>
              <div className={`userMenuHeader ${menuOpen && 'active'}`}>
                {user.email ?
                  <button className='userMenuOpenButton' onClick={() => setMenuOpen(!menuOpen)}>
                    <i className='fa-solid fa-user'></i> <span className='mobileLabelHide'>My Account <i className={`fa-solid fa-angle-${menuOpen ? 'up' : 'down'}`}></i></span>
                  </button> :
                  <span><Link tabIndex={0} to='/login'><i className='fa-solid fa-user'></i> <span className='mobileLabelHide'>Sign In</span></Link></span>}
              </div>
              {menuOpen && user.type === 'Publisher' && <PublisherMenu />}
              {menuOpen && user.type === 'Retailer' && <RetailerMenu />}
              {menuOpen && user.type === 'Customer' && <UserMenu />}
              {menuOpen && user.type === 'Library' && <LibraryMenu />}
            </div>
            <WishlistLink loggedIn={user.id} />
            <CartCounter toggleCart={toggleCart} />
          </div>
        </header>
        {/*<div className='subhead'>
          <nav className='subheadContainer'>
            <div className='subhead__list-left'>
              <Link onClick={() => { menuClick('all-books') }} to='/all-books'>All Books</Link>
              <Link onClick={() => { menuClick('new-books') }} to='/new-books'>New Releases</Link>
              <Link onClick={() => { menuClick('upcoming-books') }} to='/upcoming-books'>Upcoming Titles</Link>
              <Link onClick={() => { menuClick('ebooks') }} to='/ebooks'>eBooks</Link>
              <Link onClick={() => { menuClick('our-publishers') }} to='/our-publishers'>Our Publishers</Link>
            </div>
            <div className='subhead__list-right'>
              <Link to='/about' onClick={() => { menuClick('about') }}>About</Link>
              <Link to='/customer-support' onClick={() => { menuClick('support') }}>Support</Link>
              <Link to='/for-retailers' onClick={() => { menuClick('for-retailers') }}>For Retailers</Link>
              <Link to='/for-libraries' onClick={() => { menuClick('for-libraries') }}>For Libraries</Link>
              <Link to='/for-publishers' onClick={() => { menuClick('for-publishers') }}>For Publishers</Link>
            </div>
          </nav>
        </div>*/}
        <Cart open={cartOpen} />
        <div className={`cartScreen ${cartOpen && 'cartScreen-open'}`} onClick={() => { setCartOpen(false); asaEvent(session.id, session.group, 'cart', 'cartClose', 'offClick', 1); }}></div>
      </div>
    </>
  )
}


function PublisherMenu() {
  const logout = useResetRecoilState(userState);
  const user = useRecoilValue(userState);


  return (
    <div className='userMenu__expanded'>
      <ul>
        <li>Logged in as {user.name !== '' ? user.name : user.email}</li>
        <li><Link to='/pub/account'>Account Home</Link></li>
        <li><Link to='/pub/settings'>Account Settings</Link></li>
        <li><Link to='/pub/products'>My Products</Link></li>
        <li><Link to='/pub/orders'>My Orders</Link></li>
        <li><Link to='/pub/dropship'>Dropship</Link></li>
        <li><Link to='/pub/lists'>My Lists</Link></li>
        <li><Link to='/pub/standing-orders'>My Standing Orders</Link></li>
        <li><Link to='/pub/shipments'>My Shipments</Link></li>
        <li><Link to='/pub/directory'>Customer Directory</Link></li>
        <li><a onClick={logout} role='button'>Log out</a></li>
      </ul>
    </div>
  )
}

function RetailerMenu() {
  const logout = useResetRecoilState(userState);
  const user = useRecoilValue(userState);

  return (
    <div className='userMenu__expanded'>
      <ul>
        <li>Logged in as {user.name}</li>
        <li><Link to='/account'>Account Settings</Link></li>
        <li><Link to='/account/orders'>My Orders</Link></li>
        <li><Link to='/account/wishlist'>My Wishlist</Link></li>
        <li><Link to='/account/standing-orders'>Standing Orders</Link></li>
        <li><Link to='/account/new-order'>Quick Order Tool</Link></li>
        <li><a onClick={logout} role='button'>Log out</a></li>
      </ul>
    </div>
  )
}

function UserMenu() {
  const logout = useResetRecoilState(userState);
  const user = useRecoilValue(userState);

  return (
    <div className='userMenu__expanded'>
      <ul>
        <li>Logged in as {user.name ? user.name : user.email}</li>
        <li><Link to='/account'>Account Settings</Link></li>
        <li><Link to='/account/wishlist'>My Wishlist</Link></li>
        <li><Link to='/account/orders'>My Orders</Link></li>
        {user.admin && <li><Link to='/admin'>Admin</Link></li>}
        <li><a onClick={logout} role='button'>Log out</a></li>
      </ul>
    </div>
  )
}

function LibraryMenu() {
  const logout = useResetRecoilState(userState);
  const user = useRecoilValue(userState);

  return (
    <div className='userMenu__expanded'>
      <ul>
        <li>Logged in as {user.name}</li>
        <li><Link to='/account'>Account Settings</Link></li>
        <li><Link to='account/standing-orders'>Standing Orders</Link></li>
        <li><Link to='/account/wishlist'>My Wishlist</Link></li>
        <li><Link to='/account/orders'>My Orders</Link></li>
        <li><a onClick={logout} role='button'>Log out</a></li>
      </ul>
    </div>
  )
}
