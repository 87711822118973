import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TextControl } from '../../components/InputControls';
import { makeRequest } from '../../Utils';

export default function Hero({ post }) {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const seen = window.localStorage.getItem('welcomeSeen');


  const submit = async () => {
    setSubmitted(true);
    await makeRequest(`newsletter?email=${email}`);
  }



  return (
    <>
      <div className='hero mobileHide'>
        <div className={`heroBlock`}>
          <div className='homepageTitle'>Welcome to <span className='homepageTitlePress'>Asterism</span>!</div>
          <div className='homepageDescription'>
            <p>Asterism Books is a trade distributor and online bookstore designed, built, and run by independent publishers. We aim to bring the best of small-press publishing together in one place, highlighting the quality and diversity of the independent publishing landscape.</p>
          </div>
        </div>
        <div className='heroBlock'>
          <div><strong>Latest from the Blog</strong> {post.title && <Link className='blogPostLink' to={`/blog/post/${post.slug}`}>{post.title}</Link>}</div>
        </div>
        <div className='heroBlock'>
          <div className='visitTop'>Coming to Seattle?</div>
          <div className='visitBottom'><Link to='/visit-us'>Plan a visit <i className="fa-solid fa-arrow-right-long"></i></Link></div>
        </div>
        <div className={`heroBlock`}>

          <div className='heroLabel newsletter'>Sign Up for Our Newsletter and Save 10%</div>
          <div className='heroNewsletterText'>Get all the latest news, weekly curated picks, and more, straight to your inbox! Plus, receive a 10% off coupon for your next order.</div>
          {!submitted && <div className='newsletterBlock_controls'>
            <TextControl
              value={email}
              change={setEmail}
              classlist='newsletterSignupNew'
              placeholder={'Your email'}
            />
            <div>
              <button onClick={submit} className='newsletterButton'>I'm in!</button>
            </div>
          </div>}
          {submitted &&
            <div className='newsletterConfirm'>Thanks! You're swell.</div>
          }
          <div className='newsletterFinePrint'>By signing up, you agree to get regular email communications from Asterism. We will never sell your data, and you can opt-out at any time.</div>
        </div>

      </div>
    </>
  )
}