import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styles.css';

export default function AdminHeaderMenu() {
  const [open, setOpen] = useState(false);

  return (
    <div className='adminHeaderMenu'>
      <div className='adminHeaderItem title'>Admin</div>
      <div className={`adminHeaderInner ${open && 'adminHeaderVisible'}`}>

        {config.map((item) => {
          if (item.children) {
            return <SubMenu item={item} />
          } else {
            return <Link to={item.link}>{item.title}</Link>
          }
        })}
      </div>
      <div>
        <button className='mobileMenuToggle mobileShow' onClick={() => { setOpen(!open) }}>{open ? <i className='fa-solid fa-caret-up'></i> : <i className='fa-solid fa-caret-down'></i>}</button>
      </div>
    </div>)
}

function SubMenu({ item }) {
  const [open, setOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location])

  return (
    <div className='adminHeaderItem'>
      <button onClick={() => { setOpen(!open) }}>{item.title} {open ? <i className='fa-solid fa-caret-up'></i> : <i className='fa-solid fa-caret-down'></i>}</button>
      {open &&
        <div className='adminHeaderSubmenu'>
          {item.children.map((link) => {
            return <div className='adminHeaderSubmenu__item' key={link.title}><Link to={link.link}>{link.title}</Link></div>
          })}
        </div>
      }
    </div>
  )
}




const config = [
  {
    title: 'Home',
    link: '/admin'
  },
  {
    title: 'Orders',
    link: '/admin/orders'
  },
  {
    title: 'Shipments',
    link: '/admin/shipments'
  },
  {
    title: 'Products',
    link: '/admin/products'
  },
  {
    title: 'Publishers',
    link: '/admin/publishers'
  },
  {
    title: 'Lists',
    link: '/admin/lists'
  },
  {
    title: 'Homepage',
    link: '/tools/homepage'
  },
  {
    title: 'Blog',
    link: '/admin/posts'
  },
  {
    title: 'Catalogs',
    link: '/admin/catalogs'
  },
  {
    title: 'Inventory',
    children: [
      {
        title: 'Inventory Manager',
        link: '/tools/inventory'
      },
      {
        title: 'Stock Requests',
        link: '/admin/requests'
      },
      {
        title: 'Pick History',
        link: '/tools/pick-history'
      }
    ]
  },
  {
    title: 'Tools',
    children: [
      {
        title: 'Picker',
        link: '/tools/picker'
      },
      {
        title: 'Pre-order Dashboard',
        link: '/admin/preorders'
      },
      {
        title: 'Backorders',
        link: '/tools/backorders'
      },
      {
        title: 'Dimensions Updater',
        link: '/tools/dimensions'
      }
    ]
  },
  {
    title: 'Other',
    children: [
      {
        title: 'Shipping Supplies',
        link: '/admin/supplies'
      }
    ]
  }
]