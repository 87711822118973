import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { asaEvent, formatISODate, makeRequest, slugify } from '../../Utils';
import './styles.css';
import SidesScroller from '../SideScroller';
import { useRecoilValue } from 'recoil';
import { pageviewState, sessionState, userState } from '../../atoms';

export default function HomepageBookBlock({ source, block, sourceId, title = '', moreText = '', moreLink = '', classList = '' }) {
  const [list, setList] = useState([]);
  const session = useRecoilValue(sessionState);
  const pageview = useRecoilValue(pageviewState);
  const user = useRecoilValue(userState);


  const getBestsellers = async () => {
    let data = await makeRequest('bestsellers');
    let slug = data[0].slug;
    let request = await makeRequest(`list?slug=${slug}`);
    setList(request.products);
  }

  const getList = async (id) => {
    let request = await makeRequest(`list?id=${id}&display=true`);
    setList(request.products);
  }

  const getNew = async () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const endKey = formatISODate(tomorrow);
    let data = await makeRequest(`all-products?beforeDate=${endKey}&inStockNow=true&per=24`, 'GET');
    setList(data.docs);
  }

  const getUpcoming = async () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const endKey = formatISODate(tomorrow);
    let data = await makeRequest(`all-products?afterDate=${endKey}&ascending=true&per=24`, 'GET');
    setList(data.docs);
  }

  useEffect(() => {
    if (source === 'list') {
      getList(sourceId)
    } else if (source === 'bestsellers') {
      getBestsellers();
    } else if (source === 'new') {
      getNew();
    } else if (source === 'upcoming') {
      getUpcoming();
    }
  }, []);

  return (
    <div className='homepageBookBlock'>
      <div className={`newHead flex-layout ${classList}`}>
        <h2>{title}</h2>
        {(user.admin && source === 'list') ? <Link to={`/admin/list/${sourceId}`}>Edit List</Link> : ''}
      </div>
      <SidesScroller block={block} title={title} list={list} classList={classList} moreText={moreText} moreLink={moreLink} />
    </div>
  )
}