import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { checkAuth, LoadingMessage } from '../../Utils';
import { userState, messageState, cartState } from '../../atoms';

export default function AuthAccess() {
  const setCart = useSetRecoilState(cartState);
  const setUserData = useSetRecoilState(userState);
  const setMessage = useSetRecoilState(messageState);
  const [hasError, setHasError] = useState(false);


  document.title = `Checking access token | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', 'Access your account with a magic link to your email');

  const navigate = useNavigate();

  const checkToken = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');
    if (token) {
      let authData = await checkAuth(token);
      if (authData) {
        setUserData(authData.userData);
        localStorage.setItem('asterismToken', token);
        if (authData.cart) {
          setCart(authData.cart);
        }
        setMessage({ type: 'success', label: 'success', text: 'User logged in successfully!', temp: true });
        if (authData.mode === 'publisher') {
          navigate('/pub/account');
        } else {
          navigate('/account');
        }
      }
      else {
        setMessage({ type: 'error', temp: false, text: 'Login token invalid. It may be expired.', label: 'Error' });
        setHasError(true);
      }
    } else {
      setMessage({ type: 'error', temp: false, text: 'No valid login token present.', label: 'Error' });
      setHasError(true);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      checkToken();
    }, 1000)
  }, [])

  return (
    <div className='loginCard asterismCard'>
      <div className='roomBelowMedium'>
        <h1 className='page-header'>Checking your access token...</h1>
      </div>
      {!hasError && <LoadingMessage message={'Checking...'} />}
      {hasError && <div>
        <div className='roomBelowMedium'>It looks like your link is no longer valid.</div>
        <button className='buttonPrimary buttonFullWidth' onClick={() => {
          navigate('/login')
        }}>Try logging in again</button>
      </div>}
    </div>
  )
}
