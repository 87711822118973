import React, { useState } from 'react';
import { ticketTypes } from '../../Variables';
import { TextAreaControl, TextControl, SelectControl } from '../../components/InputControls';
import './style.css';
import { LoadingMessage, makeRequest } from '../../Utils';

export default function SupportPage() {
  return (
    <div>
      <h1 className='page-header'>Customer Support Center</h1>
      <div className='supportPage'>
        <div>
          <h3>Check your order status</h3>
          <StatusCheck />
          <hr />
          <h2>FAQs</h2>
          <h3>Tracking</h3>
          <p><strong>When is my package going to ship?</strong></p>
          <p>For items we have in-stock, an order will usually ship no later than the next business day. For pre-ordered items, i.e., products with a publication date in the future, we will typically ship about a week before publication date, or as soon as we receive copies from the publisher.</p>
          <p><strong>How can I make sure I entered the correct address?</strong></p>
          <p>Use the form above, or check the receipt that was emailed to you after placing your order.</p>
          <p><strong>I entered my address incorrectly. Can you change it?</strong></p>
          <p>We can update your address up until the package has been shipped. Please let us know as soon as possible so we can address the issue.</p>
          <strong>My tracking link shows that the package has been delivered, but I don't see it.</strong>
          <p>Here are the steps to check on your package:</p>
          <ul>
            <li>You can use the above order status form or your e-mail to verify that the shipping address is correct.</li>
            <li>Contact your local post office or carrier store</li>
            <li>For USPS, file a Missing Mail report online at <a href='https://usps.my.site.com/emailus/s/?_gl=1*q8ywoe*_gcl_au*MTQzMjMxNjc2LjE3MTM0MTU3Njc.*_ga*MjAwODQ5ODg4NC4xNzA1NTM5OTg0*_ga_3NXP3C8S9V*MTcxOTI0NjUyNi41NC4xLjE3MTkyNDY1NTMuMC4wLjA.' target='_blank'>this link</a></li>
            <li>If the package is still missing, contact us with the results of the Missing Mail Report and we will file an insurance claim and issue a refund or, where possible, a replacement</li>
            <li><strong>NOTE: We will not issue a refund/replacement if the address entered on the order was incorrect.</strong></li>
          </ul>
          <p><strong>My international package says it has shipped, but there has been no update on the tracking for several days.</strong></p>
          <p>That's usually nothing to worry about! Our affordable shipping through Asendia means that each individual package is shipped as part of a much larger parcel to the final destination. This typically means that tracking will be slow to update while the package is in the US, for as long as two weeks, and then the tracking will update with the local carrier tracking number once it has cleared customers in your country. If it has been more than three weeks, please contact us!</p>
          <p><strong>My package has shipped, but it hasn't arrived yet. What can I do?</strong></p>
          <p>First, please note that USPS media mail can take up to 14 days, though it usually arrives much sooner. If it's been longer than that, here are some good steps to take:</p>
          <ul>
            <li>First, contact the carrier. They'll have the most information about your package's whereabouts. We typically have the same tracking data you have.</li>
            <li>If the package is lost or undeliverable, let us know and we will process a refund or replacement, availability pending.</li>
          </ul>
          <p><strong>My package says it's been delivered but I don't see it anywhere. Help?</strong></p>
          <p>Oof. We know this is frustrating. If you've already checked the address and the package was sent to the correct address, you can try the following:</p>
          <ul>
            <li>Look around! Packages show up in the darndest places, and it may have been left somewhere you wouldn't expect. Check the tracking link to see if the delivery person included a photo or note about where the package was left.</li>
            <li>Ask around. If you have a building manager, landlord, or neighbor who might have seen it, it's always a good idea to check.</li>
            <li>Wait. We know, this is the hard part. Sometimes carriers will mark pacakges as delivered just a little <em>too</em> soon. If it doesn't show up within 2 days of the delivery date, now's a good time to let us know so we can look into it.</li>
          </ul>
          <p>In the case of a lost package, we'll do our best to issue a refund or replacement.</p>
          <p>Please report any package thefts to the relevant authorities.</p>
          <p><strong>I paid for expedited shipping, but the package was sent via a slower method.</strong></p>
          <p>Well, we're sure sorry about that! If you still want the book, we will refund the shipping cost for your order. If you'd rather return the book and get refunded, just let us know!</p>
          <h3>Returns and Refunds</h3>
          <p><strong>What's your return policy?</strong></p>
          <p>We're happy to process returns within 30 days of shipment, as long as the item is still in new condition.</p>
          <p><strong>The book I received was damaged, what can you do?</strong></p>
          <p>Damaged books do happen, though we try our best to minimize these with expert packing. If you received a damaged book, please use the form to get in touch. We will process a replacement or a refund, depending on product availability. <strong>Note:</strong> we may ask for additional documentation of damage, including photos, as we will need these to process insurance claims.</p>
          <p><strong></strong></p>
          <p></p>
          <h3>Other Issues</h3>
          <p><strong>I received the wrong item or the wrong number of items, how do I fix this?</strong></p>
          <p>Get in touch! Please provide details on any erroneous items you received, as well as any that you didn't. Photos are great! We'll do our best to get the correct items to you or issue a refund, pending availability of titles. If we shipped you the wrong books in error, we will provide a return label at no-cost.</p>
          <p><strong>We had our books shipped to a freight forwarder, who then lost or damaged the books. Is there anything you can do?</strong></p>
          <p>In this case, no. Asterism Books is not liable or responsible for any losses, damages or delays due to the actions or inactions of third party freight forwarding shipping services. We are only responsible in shipping the products to the designated address.</p>
          <hr />
          <p></p>
        </div>
        <div>
          <h3>Contact Us</h3>
          <p>Email us at orders [@] asterismbooks.com or use the form below! We'll be in touch within one business day.</p>
          <SupportForm />
        </div>
      </div>
    </div>
  )
}

function StatusCheck() {
  const [id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [checking, setChecking] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [data, setData] = useState({});

  const checkStatus = async () => {
    setError('');
    setChecking(true);
    if (!id) {
      setError('Please input your order ID');
      setChecking(false);
      return;
    } else if (!email) {
      setError('Please enter the email associated with your order');
      setChecking(false);
      return;
    }
    try {
      let status = await makeRequest(`order-status?id=${id}&email=${email}`);
      if (status.message) {
        setStatusMessage(status.message);
        setData(status.order);
      } else {
        setError(`We couldn't automatically check your order status. Please get in touch for additional help!`)
      }
      setChecking(false);
    } catch (e) {
      setError(`We couldn't automatically check your order status. Please get in touch for additional help!`)
    }
  }

  return (
    <>
      <TextControl value={id} label='Your Order Number' help='AST-01-XXXXXX' change={(v) => { setId(v.trim()) }} />
      <TextControl value={email} label='Your Email' help='Must match the email on the receipt' change={setEmail} />
      {error && <div className='errorMessage'>{error}</div>}
      {checking ? <LoadingMessage text='Checking your order status...' /> : <button className='buttonPrimary' onClick={checkStatus}>Check</button>}
      {statusMessage &&
        <div className='orderStatusMessage'>
          <span dangerouslySetInnerHTML={{ __html: statusMessage }} />
          {data.customer?.address && <div>
            <div className='pseudoLabel roomAboveMedium'>The shipping address on this order is:</div>
            <div><strong>{data.customer.address.name}</strong></div>
            <div>{data.customer.address.lineOne}</div>
            <div>{data.customer.address.lineTwo}</div>
            <div>{data.customer.address.city} {data.customer.address.state} {data.customer.address.postalCode}</div>
            <div>{data.customer.address.country}</div>
          </div>}
        </div>}
    </>
  )
}

function SupportForm() {
  const [order, setOrder] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState('gi');
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const createTicket = async () => {
    let save = await makeRequest('support-form', 'POST', {
      order: order,
      email: email,
      name: name,
      type: type,
      message: message
    });
    if (save.ok) {
      setSubmitted(true);
      setSubmitting(false);
    } else {
      setSubmitting(false);
      setError(true);
    }
  }

  if (submitted) {
    return (<div>Thanks for your message! We'll be in touch shortly.</div>)
  }

  return (
    <>
      <TextControl
        value={email}
        change={setEmail}
        label='Your Email'
      />
      <TextControl
        value={name}
        change={setName}
        label='Your Name (optional)'
      />
      <SelectControl
        value={type}
        change={setType}
        label={'How can we help you?'}
        canBeEmpty={true}
        options={Object.keys(ticketTypes).map((type) => {
          return {
            value: type,
            label: ticketTypes[type]
          }
        })}
      />
      <TextControl
        value={order}
        change={setOrder}
        label='Your order or receipt number'
      />
      <TextAreaControl
        value={message}
        change={setMessage}
        label='Tell us about your issue'
      />
      {error && <div className='errorMessage'>There was a problem submitting your request.</div>}
      {submitting ? <LoadingMessage text='Creating support ticket' /> : <button className='buttonPrimary' onClick={createTicket}>Open Support Ticket</button>}

    </>
  )
}