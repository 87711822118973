import React, { useEffect, useState } from 'react';
import { makeRequest, shuffle } from '../../Utils';
import SidesScroller from '../../components/SideScroller';

export default function PickList({ block }) {
  const [list, setList] = useState([]);

  const buildPicks = async () => {
    if (!block.picks) return;
    let newList = [];
    for (let i = 0; i < block.picks.length; i++) {
      let data = await makeRequest(`product?id=${block.picks[i].id}`);
      data.staffPick = block.picks[i];
      newList.push(data);
    }
    shuffle(newList);
    setList(newList);
  }

  useEffect(() => {
    buildPicks();
  }, [block.picks])

  return (
    <div className='homepageBookBlock'>
      <div className={`newHead flex-layout`}>
        <h2>Recent Staff Picks</h2>
      </div>
      <SidesScroller block={block} title={'Recent Staff Picks'} list={list} />
    </div>
  )
}