import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import { getProduct, randomIntFromInterval, makeRequest } from '../../Utils';
import HomepageBookBlock from '../../components/HomepageBookBlock';
import ProductListItem from '../../components/ProductListItem';
import { useInView } from "react-intersection-observer";

export default function GiftGuide() {
  document.title = '2024 Holiday Gift Guide | Asterism Books'
  document.querySelector('meta[name="description"]').setAttribute('content', `A look at giftable highlights from our catalogue of independent books, records, and more!`);


  return (
    <>
      <GiftGuideHeader />
      <div className='giftGuide-body'>
        <p className='giftGuide-welcome'>
          <span className='giftGuide-big'>’Tis that time of year,</span> when fireplaces are cozy, year-end reading lists are due, and supporting your favorite small presses means all the more. To help you find our favorite, most giftable items, we've pulled together this handy guide, from our bestsellers and stocking-sized books, to special selections for the, er, pickier guests at your holiday soirees. From us to you, a very happy holidays!
          <br /><br />
          — The Asterism Team
        </p>

        <GiftGuideBody />
      </div>
    </>
  )
}

function GiftGuideHeader() {
  const container = useRef();

  useEffect(() => {
    setInterval(() => {
      if (!container || !container.current) {
        return;
      }
      const size = randomIntFromInterval(16, 52);
      const rotation = randomIntFromInterval(-90, 90);
      const left = randomIntFromInterval(-5, 100);

      const speedClass = size < 21 ? 'speedOne' : size < 28 ? 'speedTwo' : size < 44 ? 'speedThree' : 'speedFour';
      const timing = size < 21 ? 20000 : size < 28 ? 16000 : size < 44 ? 12000 : 9000;

      let flake = document.createElement('div');
      flake.className = 'snowflake';
      flake.classList.add(speedClass);
      flake.innerHTML = '*';
      flake.style.fontSize = `${size}px`;
      flake.style.marginLeft = `${left}%`;
      flake.style.transform = `rotate(${rotation}deg)`;
      container.current.appendChild(flake);

      setTimeout(() => {
        let drift = randomIntFromInterval(3, 12);
        flake.classList.add('falling');
        flake.style.marginLeft = `${left + drift}%`;
        flake.style.transform = `rotate(${rotation + randomIntFromInterval(-120, 120)}deg)`;
      }, 40);
      setTimeout(() => {
        container.current.removeChild(flake);
      }, timing);
    }, 100);
  }, [])



  return (<div className='giftGuideHeader' ref={container}>
    <div className='giftGuideText'>
      <div className='giftGuideBrand'>Asterism Books’</div>
      <div className='giftGuideTitle'>2024 Holiday<br /> Gift Guide</div>
    </div>
    <svg id="snowBank" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1733.14 475.79">
      <g id="Layer_2-2" data-name="Layer_2">
        <path class="snowdrift" d="M1733.14,475.79H0V4.09c34.88,11.14,88.18,26.05,154.29,35.84,181.54,26.88,296.3-9.02,414-23.14,321.4-38.55,315.03,127.29,586.29,77.14C1302.27,66.63,1412.85-2.63,1648.29.08c35.59.41,65,2.36,84.86,4.02v471.7Z" />
      </g>
    </svg>
  </div>)
}


function GiftGuideBody() {
  return (
    <div>
      <HomepageBookBlock block={0} title={'Limited Editions, Hardcovers, and More'} source={'list'} sourceId={'bd27af61-9be2-4894-99cf-f461306f0215'} moreText={''} moreLink={''} classList={'holiday'} />
      <HolidayList><div className='bookFeaturePair'>
        <BookFeature id='9781935904182' title={'For the kid in all of us...'} />
        <BookFeature id='9798989969807' title={'For the film buff...'} />
        <BookFeature id='9781737785071' title={'For the astrologer...'} />
      </div>
      </HolidayList>
      <HolidayList><HomepageBookBlock block={0} title={'Stocking-Sized Books'} source={'list'} sourceId={'a704ab4d-8380-404d-8ccd-e7658cb9cb30'} moreText={''} moreLink={''} classList={'holiday'} /></HolidayList>
      <HolidayList><div className='bookFeaturePair'>
        <BookFeature id='680585379700' title={'For the musician...'} />
        <BookFeature id='9780982617731' title={'For the animal lover...'} />        <BookFeature id='9780983547860' title={'For the theater kid...'} />
      </div></HolidayList>
      <HolidayList><HomepageBookBlock block={0} title={'Anthologies'} source={'list'} sourceId={'ffaf837f-1453-4645-8bba-672c6c0a1e6e'} moreText={''} moreLink={''} classList={'holiday'} /></HolidayList>
      <HolidayList><div className='bookFeaturePair'>
        <BookFeature id='9781919601533' title={'For the tech geek...'} />
        <BookFeature id='9781733137515' title={'For the sports fan...'} />
        <BookFeature id='9781949065190' title={'For the crime lover...'} />
      </div>
      </HolidayList>
      <HolidayList>
        <HomepageBookBlock block={0} title={'Some of 2024\'s Bestsellers'} source={'list'} sourceId={'f444e97f-1924-463a-a136-6b7b04a030be'} moreText={''} moreLink={''} classList={'holiday'} /></HolidayList>
      <HolidayList><HomepageBookBlock block={0} title={'For the Artists in Your Life'} source={'list'} sourceId={'f41c4943-5071-4335-ba5e-207a47c4c190'} moreText={''} moreLink={''} classList={'holiday'} /></HolidayList>
      <h1 className='staffPicks'>Asterism Staff Picks</h1>
      <HolidayList>
        <div className='bookFeaturePair'>
          <BookFeature id='9781950192212' title={'Josh\'s Pick'} quote={'A book of immense import to my own literary journey, Matches is a book I return to as an old friend.'} />
          <BookFeature id='9788395487149' title={'Alexis\'s Pick'} quote={'A mother presents her daughter’s thoughts and drawings to the world in this little book and it’s adorable while captivating. The drawings seem simple yet intricate, as you can see something in every one. This book is a mother giving a voice to her child and we all should listen to what she has to say.'} />
          <BookFeature id='9781933959870' title={'Miriam\'s Pick'} quote={'Sahin’s Embroideries zooms in on the fine and complicated needlework of mother/daughter relationships with acuity and care. A beautiful offering to those among us who can’t help but pull on loose threads.'} />
        </div>
      </HolidayList>
      <HolidayList><HomepageBookBlock block={0} title={'More Staff Favorites'} source={'list'} sourceId={'468902be-6943-4a7e-9a01-8b547cdd7116'} moreText={''} moreLink={''} classList={'holiday'} /></HolidayList>
      <HolidayList><div className='bookFeaturePair'>
        <BookFeature id='9781937027926' title={'Annabel\'s Pick'} quote={'For me, there is virtually no greater gift than experiencing a poet work through the scaffolding of the essay—which is why I’m putting A Handbook of Disappointed Fate on the holiday alter this year. When I sit with Anne Boyer, wherever her thoughts in time, place, and predicament, I leave a better thinker and finer witness to the people, physics, ailments, happinesses that make a life endlessly ripe for inquiry.  '} />
        <BookFeature id='9781620540633' title={'Phil\'s Pick'} quote={`Tidal Lock is the story of a young woman trying to uncover the mystery of her father’s disappearance. Lindsay Hill has gifted us with another great read, as we share, page by page, this young woman’s attempt to reweave the threads of her life. Both Sea of Hooks and Tidal Lock have my highest recommendation.`} />
        <BookFeature id='9781771316224' title={'Molli\'s Pick'} quote={'Earnest and witty, this book made me laugh out loud too many times to count. In fact, it may have cured the trauma brought on by my catholic upbringing.'} />
        <BookFeature id='9781620540466' title={'Ryan\'s Pick'} />
      </div>
      </HolidayList>
    </div>
  )
}

function HolidayList({ children }) {
  const [show, setShow] = useState(false);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    rootMargin: '800px'
  });

  useEffect(() => {
    if (inView && !show) {
      setShow(true);
    }
  }, [inView]);
  return (<div ref={ref}>{show && children}</div>)
}

function BookFeature({ id, title = '...', staff = '', quote = '' }) {
  let [product, setProduct] = useState({});

  const getData = async (i) => {
    let data = await makeRequest(`product?id=${i}`)
    setProduct(data);
  }
  useEffect(() => {
    getData(id);
  }, [id]);

  if (!product) {
    return (<></>)
  }
  return (
    <div className='giftGuideItem'>
      <div className='giftGuideHeading'>
        <div className='giftGuideHeadingText'>{title} </div>

      </div>
      <div className='giftGuideFeature'>
        <div className='giftGuideQuote'>{quote}</div>
        {product._id && <ProductListItem product={product} context='giftGuide' order={0} />}
        <div>



        </div>
      </div>
    </div>)
}