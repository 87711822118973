import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import ProductListItemSmall from '../ProductListItemSmall';
import './styles.css';
import { useRecoilValue } from 'recoil';
import GhostGrid from '../GhostGrid';
import { asaEvent, slugify } from '../../Utils';
import { pageviewState, sessionState } from '../../atoms';

export default function SidesScroller({ list = [], block, title, classList, alt = false, moreText = '', moreLink = '' }) {
  const session = useRecoilValue(sessionState);
  const pageview = useRecoilValue(pageviewState);
  const [displayLeft, setDisplayLeft] = useState(false);
  const [displayRight, setDisplayRight] = useState(true);

  const container = useRef();

  const scrollRight = () => {
    const left = container.current.scrollLeft;
    const one = container.current.offsetWidth - 200;
    const newLeft = left + one > container.current.scrollLeftMax ? container.current.scrollLeftMax : left + one;
    container.current.scrollTo(
      {
        top: 0,
        left: newLeft,
        behavior: "smooth",
      }
    )
    setDisplayLeft(true);
    setDisplayRight(newLeft < container.current.scrollLeftMax)
    pcEvent('scroll', 'scroll_right', 'button')
  }

  const scrollLeft = () => {
    const left = container.current.scrollLeft + 200;
    const one = container.current.offsetWidth;
    const newLeft = left - one < 0 ? 0 : left - one;
    container.current.scrollTo(
      {
        top: 0,
        left: newLeft,
        behavior: "smooth",
      }
    )
    setDisplayLeft(newLeft !== 0);
    setDisplayRight(true);
    pcEvent('scroll', 'scroll_left', 'button')
  }

  const pcEvent = (category, action, element) => {
    asaEvent({
      session: session.id,
      category: category,
      action: action,
      label: '',
      locationOne: `${block}__${slugify(title)}`,
      locationTwo: 'sidescroll_list',
      locationThree: element,
      testgroup: session.group,
      pagetype: pageview.pagetype,
      pageview: pageview.id,
      campaign: session.campaign,
    });
  }

  const getColors = (index) => {
    if (index === 0 || index === 4 || index === 8 || index === 12) {
      return {
        background: 'rgba(229, 59, 60, 1)',
        color: '#f2f0ea'
      }
    } else if (index === 1 || index === 5 || index === 9 || index === 13) {
      return {
        background: '#b9cdcc',
        color: 'rgba(0, 55, 75, 1)'
      }
    } else if (index === 2 || index === 6 || index === 10 || index === 14) {
      return {
        background: 'rgba(171,149,102, 1)',
        color: '#f2f0ea'
      }
    } else {
      return {
        background: 'rgba(0, 55, 75, 1)',
        color: '#b9cdcc'
      }
    }
  }


  return (
    <div className='sideScrollContainer'>
      {displayLeft && <div className={`productListScroll-left`}>
        <button onClick={scrollLeft}><i class="fa-solid fa-chevron-left"></i></button>
      </div>}
      <div className='sideScrollWrapperWrapper'>
        <div className={`sideScrollWrapper ${classList}`} ref={container}>
          <div className={`productListSmaller ${alt && 'alt'}`}
            style={{ gridTemplateColumns: `repeat(${(list.length > 0 ? list.length.toString() : 10) + (moreLink ? 1 : 0)},1fr)` }}
          >

            {list.map((product, index) => {
              return (
                <ProductListItemSmall key={product._id} product={product} context={{ block: block, title: title, index: index }} />
              )
            })}
            {list.length === 0 &&
              <GhostGrid items={10} smaller={true} />
            }
            {moreText && moreLink && <div className='moreContainer' style={{ background: getColors(block).background }}><a href={moreLink} style={{ color: getColors(block).color }} className='moreBigLink' onClick={() => {
              asaEvent({
                session: session.id,
                category: 'recirc',
                action: 'click',
                label: 'blockListMore',
                locationOne: `${block}_${slugify(title)}`,
                locationTwo: 'sidescroll_list',
                locationThree: 'moreLink',
                testgroup: session.group,
                pagetype: pageview.pagetype,
                pageview: pageview.id,
                campaign: session.campaign,
                value: 1
              });
            }}><div className='moreText'>{moreText} <i className="fa-solid fa-arrow-right-long"></i></div></a></div>}
          </div>
        </div>
      </div>
      {displayRight && <div className={`productListScroll-right`}>
        <button onClick={scrollRight}><i class="fa-solid fa-chevron-right"></i></button>
      </div>}
    </div>
  )
}