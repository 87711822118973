import React from 'react';
import { useRecoilState } from 'recoil';
import { actionState } from '../../atoms';
import styles from './confirm.module.css';

export default function ActionConfirm() {
  const [action, setAction] = useRecoilState(actionState);

  if (!action.text || !action.callback) {
    return (<></>)
  }

  const cancel = () => {
    setAction({});
  }

  return (<div className={styles.actionModal}>
    <div className={styles.actionConfirm}>
      <div className={styles.actionConfirmLabel}>{action.label}</div>
      <div className={styles.actionConfirmText}>{action.text}</div>
      <div className={styles.actionConfirmOptions}>
        <button className='buttonPrimary' onClick={() => {
          action.callback();
          cancel();
        }}>Confirm</button>
        <button className='buttonCancel' onClick={cancel}>Cancel</button>
      </div>
    </div>
  </div>)
}