import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

export default function TeamPage() {

  document.title = `Meet the Team | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Get to know the Asterism Books team.`);


  return (
    <div>
      <h1 className='page-header'>Meet the Asterism Team</h1>
      <div className='flex-layout flexColMobile'>
        <div className='flex-four pageContent'>

          <div className='teamMemberCard'>
            <div className='teamMemberPhoto'>
              <img src='https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/IMG_9861.jpg' alt='Asterism Books founder Josh Rothes' />
            </div>
            <div className='teamMemberInfo'>
              <div className='teamMemberName'>Joshua Rothes</div>
              <div className='teamMemberTitle'>Founder / co-owner / tech support</div>
              <div className='teamMemberBio'>Josh Rothes is a writer, occasional translator, and editor who founded Asterism Books in 2021 for lack of a better distribution solution. He is the publisher of Sublunary Editions, co-publisher of the Empyrean Series, and co-publisher of Hanuman Editions. Author of Asterism's expansive code base, Josh learned javascript solely to create a better writing app for himself. He can frequently be found browsing the shelves of World Poetry and punctum books.</div>
            </div>
          </div>

          <div className='teamMemberCard'>
            <div className='teamMemberPhoto'>
              <img src='https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/phil.jpg' alt='Asterism Books co-owner Phil Bevis' />
            </div>
            <div className='teamMemberInfo'>
              <div className='teamMemberName'>Phil Bevis</div>
              <div className='teamMemberTitle'>Co-owner / head of business development</div>
              <div className='teamMemberBio'>Phil re-launched Asterism Books as co-owner in January of 2023. His favorite book of all time happens to be an Asterism title—the award-winning <em>Sea of Hooks</em> by Lindsay Hill. A decade ago he was lucky to be able to adapt and produce it for the stage with Annie Brulé at Seattle's ACT Theatre. </div>
            </div>
          </div>

          <div className='teamMemberCard'>
            <div className='teamMemberPhoto'>
              <img src='https://asterism-files.s3.us-east-1.amazonaws.com/ffe295f2bd48647c7f354d7cb024bc24/1734044032-600.jpg' alt='A smiling Molli Corcoran, Asterim sales manager' />
            </div>
            <div className='teamMemberInfo'>
              <div className='teamMemberName'>Molli Corcoran</div>
              <div className='teamMemberTitle'>Sales manager / erstwhile tooth-fairy</div>
              <div className='teamMemberBio'>Molli Corcoran joined the Asterism team in May of 2024. She is currently on a big Etel Adnan kick. “I’m loving all of the Etel Adnan titles from Litmus Press.” A fun fact about Molli is that she is a wizard when it comes to growing indoor plants.</div>
            </div>
          </div>

          <div className='teamMemberCard'>
            <div className='teamMemberPhoto'>
              <img src='https://asterism-files.s3.us-east-1.amazonaws.com/ffe295f2bd48647c7f354d7cb024bc24/1734044094-600.jpg' alt='Asterism creative coordinator Alexis Olaffson' />
            </div>
            <div className='teamMemberInfo'>
              <div className='teamMemberName'>Alexis Olafsson</div>
              <div className='teamMemberTitle'>Creative coordinator / catalog editor / intern emerita</div>
              <div className='teamMemberBio'>Alexis has been with Asterism Books since December, 2023. As a senior earning a BA in English at the University of Washington, Alexis’s love for literature only grows as time goes on. She’s a passionate songwriter who plays a little guitar from time to time. While curating lists for the website or the newsletters, she found her two favorites on the shelves to be <em>Nettleback</em> from Cipher Press and <em>No More Flowers</em> from Birds, LLC. </div>
            </div>
          </div>

          <div className='teamMemberCard'>
            <div className='teamMemberPhoto'>
              <img src='https://asterism-files.s3.us-east-1.amazonaws.com/ffe295f2bd48647c7f354d7cb024bc24/1734043929-600.jpg' alt='Asterism Books inventory lead Annabel Jankovic' />
            </div>
            <div className='teamMemberInfo'>
              <div className='teamMemberName'>Annabel Jankovic</div>
              <div className='teamMemberTitle'>Inventory lead / blog editor / beverage hoarder</div>
              <div className='teamMemberBio'>Hello, hello! I began my Asterism journey as Inventory Lead in October of 2024, following stints at Powell's and, more recently, the University of Washington Press. A fun fact: I was once swooped by a mama barred owl, patrolling the airspace as her baby-birds learned to fly. My go-to Asterism pick, preoccupied as I've been with all-things "unidentified flying object-relations" and their cultural-psychical revelations, comes from the beautiful Santa Barbara-based scholarly press Punctum Books: <em>Wilhelm Reich versus the Flying Saucers: An American Tragedy.  </em> </div>
            </div>
          </div>

          <div className='teamMemberCard'>
            <div className='teamMemberPhoto'>
              <img src='https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/Miriam+Milena.jpg' alt='Asterism communications coordinator Miriam Milena' />
            </div>
            <div className='teamMemberInfo'>
              <div className='teamMemberName'>Miriam Milena</div>
              <div className='teamMemberTitle'>Communications coordinator / inveterate pot-stirrer</div>
              <div className='teamMemberBio'>Miriam joined us in the wake of the SPD closure, helping receive many thousands of books and placing them in their new homes. Now, she haunts Asterism remotely from Bellingham, where she is working towards her MFA in Creative Writing at Western Washington University. When she comes back to visit us, she's most likely reaching for titles off the shelves belonging to Litmus Press, Changes Press, and Bored Wolves. In her wallet she carries a tiny photo of Eileen Myles.</div>
            </div>
          </div>

          <div className='teamMemberCard'>
            <div className='teamMemberPhoto'>
              <img src='https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/ryan.jpg' alt='Asterism Books shipping manager Ryan Long' />
            </div>
            <div className='teamMemberInfo'>
              <div className='teamMemberName'>Ryan Long</div>
              <div className='teamMemberTitle'>Shipping coordinator / pack rat</div>
              <div className='teamMemberBio'>
                Ryan came on board to Asterism in March of 2023 to free Josh from the shackles of shipping and has been wielding the tape gun with pride ever since. They are the proud owner of four beautiful rats and 100+ books on Shakespeare, but their favorite Asterism title to flip through before packing it up is <em>Found Cats</em>.
              </div>
            </div>
          </div>

          <div className='teamMemberCard'>
            <div className='teamMemberPhoto'>
              <img src='https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/IMG_4404.jpg' alt='Asterism Books logistics coordinator Jade Tisserand' />
            </div>
            <div className='teamMemberInfo'>
              <div className='teamMemberName'>Jade Tisserand</div>
              <div className='teamMemberTitle'>Logistics coordinator / new kid</div>
              <div className='teamMemberBio'>
                Jade joined Asterism in January 2025. She worked previously at Third Place Books and Bookshop Santa Cruz and can’t imagine working anywhere that the walls aren’t more-or-less held up by bookshelves. She’ll read almost anything (no romance or poetry, please) and has an embarrassing affinity for “dad books.”
              </div>
            </div>
          </div>

          <div className='teamMemberCard'>
            <div className='teamMemberPhoto'>
              <img src='https://asterism-files.s3.us-east-1.amazonaws.com/websitefiles/IMG_2215.JPEG' alt='Just a precious little puppy dog standing on a pile of books' />
            </div>
            <div className='teamMemberInfo'>
              <div className='teamMemberName'>Merle</div>
              <div className='teamMemberTitle'>Head of naps / social media darling</div>
              <div className='teamMemberBio'>Merle's love of books largely stems from the fact that they smell like his father. He can usually be found in his bed or hiding under the couch, though as an increasingly big brave boy, he also enjoys patroling the warehouse and ensuring no dropped treat goes uneaten.</div>
            </div>
          </div>

        </div>
        <div className='flex-two'>
          <h3>Want to join the team?</h3>
          <p>Any job openings at Asterism can be found <Link to='/careers'>here</Link></p>
        </div>
      </div>
    </div>
  )
}
