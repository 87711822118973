import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';
import styles from './blog.module.css';
import Blobbiest from '../../icons/blobbiest';
import { makeRequest } from '../../Utils';
import moment from 'moment';


export default function BlogHome() {
  const [posts, setPosts] = useState([]);
  const [headlines, setHeadlines] = useState([])
  const [page, setPage] = useState(0);
  const per = 20;

  const getPosts = async (page) => {
    let loaded = [...posts];
    let data = await makeRequest(`posts?per=${per}&page=${page}`);
    setPosts(loaded.concat(data));
  }

  const getHeadlines = async (page) => {
    let data = await makeRequest(`headlines`);
    setHeadlines(data);
  }

  document.title = `Book Blog | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Lists, features, interviews, and more centered around independent publishing!`);

  useEffect(() => {
    getPosts(page);
    getHeadlines();
  }, [page]);

  return (<>
    <div className={styles.blogGrid}>
      {posts.map((post, index) =>
        <>
          <div className={styles.blogPost}>
            <div>
              <div className={styles.blogTags}>
                {post.tags && post.tags.map((tag) => {
                  return (<div className={styles.blogTag} key={tag.value}>{tag.label}</div>)
                })}
              </div>

              <Link to={`/blog/post/${post.slug}`}><h2>{post.title}</h2></Link>
              <div className={styles.blogMeta}>{post.author} | {moment(post.publishDate).format('LL')}</div>
              <div className={styles.blogSummary}>{post.summary}</div>

            </div>
          </div >
          {index === 3 && <div className={styles.blogNews}>
            <Blobbiest />
            <h3>From the Newsroom</h3>
            <ul className={styles.newsList}>
              {headlines.map((headline) =>
                <li className={styles.blogHeadline}><Link to={`/blog/post/${headline.slug}`}>{headline.title}</Link></li>
              )}
            </ul>
            <Link className={styles.moreNewsLink} to='/blog/news'>More News <i className="fa-solid fa-arrow-right-long"></i></Link>
          </div>}
        </>
      )}
      {posts.length === ((page + 1) * per) &&
        <div>
          <button onClick={() => { setPage(page + 1) }}>Load More Posts</button>
        </div>
      }
    </div >
  </>)
}