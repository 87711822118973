import React, { useState, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Link, useNavigate } from 'react-router-dom';
import { TextControl } from '../../components/InputControls';
import { checkAuth, makeRequest, wishlistChange, analyticsEvent } from '../../Utils';
import { userState, messageState, cartState } from '../../atoms';

export default function Login({ context }) {
  const [email, setEmail] = useState('');
  const setCart = useSetRecoilState(cartState);
  const [pass, setPass] = useState('');
  const [userData, setUserData] = useRecoilState(userState);
  const setMessage = useSetRecoilState(messageState);
  const [loggingIn, setLoggingIn] = useState(false);
  const [loginState, setLoginState] = useState('password');
  const [passView, setPassView] = useState(false);
  const [passwordlessDone, setPasswordlessDone] = useState(false);
  const [init, setInit] = useState(false);

  document.title = `Log in to your Asterism account | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', 'Login to your account to view your profile, orders, and more.');

  const navigate = useNavigate();

  const forgotPass = async () => {
    let data = await makeRequest('forgot', 'POST', { email: email, context: context });
    if (data.ok) {
      setMessage({ type: 'success', label: 'success', text: 'We will send you an email with a link to reset your password!', temp: true });
      analyticsEvent('loginForgot');
    }
  }

  const submit = async () => {
    setLoggingIn(true);
    let user = {
      email: email,
      pass: pass,
      mode: context
    }
    let data = await makeRequest('login', 'POST', user);
    if (data.token) {
      setMessage({ type: 'success', label: 'success', text: 'User logged in successfully!', temp: true });
      localStorage.setItem('asterismToken', data.token);
      //const queryParams = new URLSearchParams(window.location.search);
      //const ret = queryParams.get('return');
      let authData = await checkAuth(data.token);
      setUserData(authData.userData);
      if (authData.cart) {
        setCart(authData.cart);
      }
      analyticsEvent('loginSuccess');
      if (context === 'publisher') {
        navigate('/pub/account');
      } else {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('wishlist')) {
          await wishlistChange(authData.id, searchParams.get('wishlist'), true);
          navigate('/account/wishlist');
        } else {
          navigate('/account');
        }
      }
    } else {
      setMessage({ type: 'error', temp: true, text: data.message, label: 'Error' })
      analyticsEvent('loginError');
    }
    setLoggingIn(false)
  }

  const passwordlessLogin = async () => {
    let user = {
      email: email,
    }
    let data = await makeRequest('passwordless-login', 'POST', user);
    if (data.status === 'error') {
      setMessage({ type: 'error', label: 'error', text: `We didn't recognize your email. Check it and try again, or use one of the other login methods`, temp: true });
    } else {
      setPasswordlessDone(true);
    }
  }

  useEffect(() => {
    const pref = localStorage.getItem('asterismLoginPref');
    if (pref === 'password' || pref === 'passwordless') {
      setLoginState(pref);
    }
    setInit(true);
  }, [])

  useEffect(() => {
    const pref = localStorage.getItem('asterismToken')
    if ((loginState === 'password' || loginState === 'passwordless') && pref !== loginState && init) {
      localStorage.setItem('asterismLoginPref', loginState);
    }
  }, [loginState]);




  if (userData.id) {
    return (
      <div>
        <h4>Whoa there. You're already logged in. Go to <Link to={'/account'}>your account</Link></h4>
      </div>
    )
  }



  if (passwordlessDone) {
    return (
      <div className='loginCard asterismCard'>
        <div className='roomBelowMedium'>
          <h1 className='page-header'>Your login email has been sent!</h1>
        </div>
        <div className='roomBelowMedium'>Didn't get it? Try logging in a different way or using the forgot password tool.</div>
        <button className='buttonPrimary buttonFullWidth' onClick={() => {
          setPasswordlessDone(false);
          setLoginState('password');
        }}>Login another way</button>
      </div>
    )
  }

  return (
    <div className='loginCard asterismCard'>
      <div className='roomBelowMedium'>
        <h1 className='page-header'>Sign in to your Asterism account</h1>
      </div>

      <div className='roomBelowMedium'><Link to={`/create-account${window.location.search}`}>Or create an account</Link></div>

      <div className='loginForm'>
        <TextControl
          value={email}
          change={(value) => { setEmail(value) }}
          label={`Account Email`}
          disabled={loggingIn}
          valid={false}
          type='text'
          autocomplete='username'
          required={true}
          bypass={true}
          description={''}
        />
        {loginState === 'password' &&
          <div className='inputControl'>
            <div className='inputControl__label'>Password</div>
            <div className='passwordContainer'>
              <input disabled={loggingIn} type={passView ? 'text' : 'password'} value={pass} onChange={(e) => { setPass(e.target.value) }} onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  submit();
                }
              }} />
              <button onClick={() => { setPassView(!passView) }}><i class={passView ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'} name='Toggle password visibility'></i></button>
            </div>
          </div>
        }
      </div>

      {loginState === 'password' && <div className='buttonPair'>
        <button className='buttonPrimary' disabled={loggingIn} onClick={submit}>Sign In</button>
        <button className='buttonSecondary' onClick={() => { setLoginState('forgot') }}>Forgot Password</button>
      </div>}
      {loginState === 'forgot' && <div className='buttonPair'>
        <button className='buttonPrimary' disabled={loggingIn} onClick={forgotPass}>Request Reset Email</button>
        <button className='buttonSecondary' onClick={() => { setLoginState('password') }}>Login Instead</button>
      </div>}
      {loginState === 'passwordless' &&
        <div className='buttonPair'><button className='buttonPrimary' onClick={passwordlessLogin}><i className="fa-solid fa-paper-plane-top"></i> Send Link</button></div>
      }
      <hr />
      <div className='roomBelowMedium'>{loginState === 'password' ? 'Or, try passwordless login with a link sent to your email.' : 'Or, login with your password'}</div>
      <button className='buttonPrimary buttonFullWidth' onClick={() => {
        setLoginState(loginState === 'password' ? 'passwordless' : 'password')
      }}>{loginState === 'password' ? 'Sign in with a link' : 'Login with a password'}</button>
    </div>
  )
}
