import React from 'react';
import EmailSignup from '../../components/EmailSignup';

export default function Newsletter() {

  document.title = `Sign Up for Our Email Newsletter | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Join our mailing list and receive a 10% off coupon to use on your next order.`);

  return (
    <>
      <EmailSignup />
    </>
  )
}