import React, { useEffect, useState, useRef } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {
  useRecoilState,
  useSetRecoilState,
  useResetRecoilState,
  useRecoilValue,
} from 'recoil';
import './App.css';
import { analyticsEvent, checkAuth, updateUserCart } from './Utils.js';
import PublisherMenu from './components/PubMenu';
import AdminMenu from './components/AdminMenu';

// Atoms
import { userState, cartOpenState, cartState, messageState, modeState, checkoutMode } from './atoms';

// Core Components

import Header from './components/Header';
import Footer from './components/Footer';
import Message from './components/Message';
import Confirm from './components/ActionConfirm';

// Pages
import Home from './pages/Home';
import Product from './pages/ProductPage';
import Publisher from './pages/Publisher';
import BooksAll from './pages/BooksAll';
import PageAbout from './pages/PageAbout';
import PageRetailers from './pages/PageRetailers';
import PageLibraries from './pages/PageLibraries';
import ProductHome from './pages/ProductHome';
import PageTOC from './pages/PageTOC';
import Publishers from './pages/Publishers';
import ForPublishers from './pages/PagePublishers';
import SearchResults from './pages/SearchResults';
import NotFound from './pages/Page404';
import List from './pages/List';
import SupportPage from './pages/Support';
import Bestsellers from './pages/Bestsellers';
import CatalogPage from './pages/Catalog';
import Jobs from './pages/PageJobs';
import GiftCards from './pages/GiftCards';
import GiftGuide from './pages/GiftGuide';
import TeamPage from './pages/PageTeam/index.js';
import BlogPost from './pages/BlogPost';
import BlogHome from './pages/Blog';
import BlogNews from './pages/BlogNews';
import Visit from './pages/PageVisit'
import Newsletter from './pages/PageNewsletter';

// Auth
import AuthLogin from './pages/AuthLogin';
import AuthReset from './pages/AuthReset';
import AuthCreate from './pages/AuthCreate';
import AuthAccess from './pages/AuthAccess';

import { makeRequest } from './Utils.js';
import PubHome from './pages/PubHome';
import StoreMap from './pages/StoreMap/index.js';
import Checkout from './pages/Checkout/index.js';
import { useAnalytics } from './hooks/useAnalytics.js';
import EmailSignup from './components/EmailSignup/index.js';

/** Non-essential Pages */
const ToolsPicker = React.lazy(() => import('./pages/ToolsPicker'));
const ToolsBarcode = React.lazy(() => import('./pages/ToolsBarcode'));
const ToolsMailingList = React.lazy(() => import('./pages/ToolsMailingList'));
const ToolsQR = React.lazy(() => import('./pages/ToolsQR'));
const ToolsSocial = React.lazy(() => import('./pages/ToolsSocial'));
const ToolsImageUploader = React.lazy(() => import('./pages/ToolsImageUploader'));
const ToolsShipping = React.lazy(() => import('./pages/ToolsShipping'));
const ToolsNewsletterLegacy = React.lazy(() => import('./pages/ToolsNewsletterLegacy'));
const ToolsInventory = React.lazy(() => import('./pages/ToolsInventory'));
const ToolsLabels = React.lazy(() => import('./pages/ToolsLabels'));
const ToolsPricing = React.lazy(() => import('./pages/ToolsPricing'));
const ToolsBulkOrder = React.lazy(() => import('./pages/ToolsBulkOrder'));
const ToolsPackingSlips = React.lazy(() => import('./pages/ToolsPackingSlips/index.js'));
const ToolsCustomerService = React.lazy(() => import('./pages/ToolsCustomerService/index.js'));
const ToolsBackorders = React.lazy(() => import('./pages/ToolsBackorders'));
const ToolsRoomFinder = React.lazy(() => import('./pages/ToolsRoomFinder'));
const ToolsHomepage = React.lazy(() => import('./pages/ToolsHomepage'));
const ToolsPickHistory = React.lazy(() => import('./pages/ToolsPickHistory'));
const ToolsDimensions = React.lazy(() => import('./pages/ToolsDimensions'));
const ShowroomReport = React.lazy(() => import('./pages/ToolsShowroom'));
const FillableBackorders = React.lazy(() => import('./pages/ToolsFillable'));

const AdminHome = React.lazy(() => import('./pages/AdminHome'));
const AdminShipments = React.lazy(() => import('./pages/AdminShipments'));
const AdminOrders = React.lazy(() => import('./pages/AdminOrders'));
const AdminInventory = React.lazy(() => import('./pages/AdminInventory'));
const AdminCreateCustomer = React.lazy(() => import('./pages/AdminCreateCustomer'));
const AdminCreatePublisher = React.lazy(() => import('./pages/AdminPublisherCreate'));
const AdminPublisher = React.lazy(() => import('./pages/AdminPub'));
const AdminPublisherList = React.lazy(() => import('./pages/AdminPubList'));
const AdminCustomer = React.lazy(() => import('./pages/AdminCustomer'));
const AdminWikiPostEdit = React.lazy(() => import('./pages/AdminWikiPostEdit'));
const AdminWikiPost = React.lazy(() => import('./pages/AdminWikiPost'));
const AdminWikiList = React.lazy(() => import('./pages/AdminWikiList'));
const AdminCatalog = React.lazy(() => import('./pages/AdminCatalog'));
const AdminCatalogs = React.lazy(() => import('./pages/AdminCatalogs'));
const AdminRequest = React.lazy(() => import('./pages/AdminRequest'));
const AdminRequests = React.lazy(() => import('./pages/AdminRequests'));
const AdminLogs = React.lazy(() => import('./pages/AdminLogs'));
const AdminTickets = React.lazy(() => import('./pages/AdminTickets'));
const AdminOrder = React.lazy(() => import('./pages/AdminOrder'));
const AdminTicket = React.lazy(() => import('./pages/AdminTicket/index.js'));
const AdminLists = React.lazy(() => import('./pages/AdminLists'));
const AdminBlogEdit = React.lazy(() => import('./pages/AdminBlogEdit'));
const AdminBlog = React.lazy(() => import('./pages/AdminBlog'));
const AdminProducts = React.lazy(() => import('./pages/AdminProducts'));
const AdminStyleguide = React.lazy(() => import('./pages/AdminStyles'));
const OrderComposer = React.lazy(() => import('./pages/AdminComposer'));
const AdminDropship = React.lazy(() => import('./pages/AdminDropship'));
const AdminReturns = React.lazy(() => import('./pages/AdminReturns'));
const AdminPreorders = React.lazy(() => import('./pages/AdminPreorders'));
const BackorderOrders = React.lazy(() => import('./pages/ToolsBackordersOrders'));
const Supplies = React.lazy(() => import('./pages/PageSupplies'));

// Publisher
const PubProducts = React.lazy(() => import('./pages/PubProducts'));
const PubProductEdit = React.lazy(() => import('./pages/PubProductEdit'));
const PubSettings = React.lazy(() => import('./pages/PubSettings'));
const PubSubs = React.lazy(() => import('./pages/PubSubs'));
const PubOrder = React.lazy(() => import('./pages/PubOrder'));
const PubOrders = React.lazy(() => import('./pages/PubOrders'));
const PubShipments = React.lazy(() => import('./pages/PubShipments'));
const PubTerms = React.lazy(() => import('./pages/PubTerms'));
const PubList = React.lazy(() => import('./pages/PubList'));
const PubLists = React.lazy(() => import('./pages/PubLists'));
const PubInventory = React.lazy(() => import('./pages/PubInventory'));
const PubRequests = React.lazy(() => import('./pages/PubRequests'));
const PubRequest = React.lazy(() => import('./pages/PubRequest'));
const PubDirectory = React.lazy(() => import('./pages/PubDirectory'));
const DropShipper = React.lazy(() => import('./pages/PubDropship'));

// Libraries!
const LibrarySubs = React.lazy(() => import('./pages/LibrarySubs'));

// Account
const AccountOrders = React.lazy(() => import('./pages/AccountOrders'));
const AccountOrder = React.lazy(() => import('./pages/AccountOrder'));
const NewOrder = React.lazy(() => import('./pages/NewOrder'));
const Wishlist = React.lazy(() => import('./pages/UserWishlist'));
const DownloadPage = React.lazy(() => import('./pages/OrderDownload'));
const UserSettings = React.lazy(() => import('./pages/UserSettings'));

function App() {
  const [userData, setUserData] = useRecoilState(userState);
  const setMessage = useSetRecoilState(messageState);
  const mode = useRecoilValue(modeState);
  const [cart, setCart] = useRecoilState(cartState);
  const setCheckoutState = useSetRecoilState(checkoutMode);
  const cartOpen = useRecoilValue(cartOpenState);
  const logout = useResetRecoilState(userState);

  const getInitialAuth = async () => {
    const params = new URLSearchParams(window.location.search);
    let authData = await checkAuth();
    if (authData) {
      setUserData(authData.userData);
      if (authData.cart && params.get('success') !== 'true') {
        setCart(authData.cart);
      }
    } else {
      logout();
    }
  }

  const debounceDelay = 750;
  const debounceTimer = useRef(0);
  const controller = useRef();

  const maybeUpdateUserCart = (cart, user) => {
    clearTimeout(debounceTimer.current);
    controller.current?.abort();
    controller.current = new AbortController();
    debounceTimer.current = setTimeout(async () => {
      try {
        await updateUserCart(cart, user);
      } catch (err) {
        // nada
      } finally {
        // nada
      }
    }, debounceDelay);
  }

  useEffect(() => {
    if (userData.id) {
      maybeUpdateUserCart(cart, userData.id);
    }
  }, [cart, userData])

  const checkForSuccess = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('success') === 'true') {
      setCart({});
      setMessage({ label: 'Success', type: 'success', text: 'Thank you for your order!', temp: true });
      analyticsEvent('checkoutSuccess', { mode: mode });
    }
    if (params.get('checkout') === 'admin') {
      setCheckoutState('admin');
    }
  }

  useEffect(() => {
    checkForSuccess();
    getInitialAuth();
  }, []);

  return (
    <BrowserRouter>
      <div className={`app-container ${cartOpen && 'cartOpen'}`}>
        <AnalyticsWrapper />
        <Header />
        <Routes>
          <Route path='/' element={<PageWrapper noEmailFooter={true}><Home /></PageWrapper>} />
          <Route path='/product/:slug' element={<PageWrapper><Product /></PageWrapper>} />
          <Route path='/product' element={<PageWrapper><ProductHome /></PageWrapper>} />
          <Route path='/publisher/:slug' element={<PageWrapper><Publisher /></PageWrapper>} />
          <Route path='/publisher/:slug/page/:page' element={<PageWrapper><Publisher /></PageWrapper>} />
          <Route path='/all-books' element={<PageWrapper><BooksAll /></PageWrapper>} />
          <Route path='/ebooks' element={<PageWrapper><BooksAll mode='ebooks' /></PageWrapper>} />
          <Route path='/all-books/page/:page' element={<PageWrapper><BooksAll /></PageWrapper>} />
          <Route path='/all-books/:category' element={<PageWrapper><BooksAll /></PageWrapper>} />
          <Route path='/all-books/:category/page/:page' element={<PageWrapper><BooksAll /></PageWrapper>} />
          <Route path='/upcoming-books' element={<PageWrapper><BooksAll mode='upcoming' /></PageWrapper>} />
          <Route path='/new-books' element={<PageWrapper><BooksAll mode={'new'} /></PageWrapper>} />
          <Route path='/gift-guide' element={<PageWrapper contentClass='full-width'><GiftGuide /></PageWrapper>} />
          <Route path='/about' element={<PageWrapper><PageAbout /></PageWrapper>} />
          <Route path='/our-publishers' element={<PageWrapper><Publishers /></PageWrapper>} />
          <Route path='/for-publishers' element={<PageWrapper><ForPublishers /></PageWrapper>} />
          <Route path='/for-retailers' element={<PageWrapper><PageRetailers /></PageWrapper>} />
          <Route path='/bookstores' noindex={true} element={<PageWrapper><PageRetailers /></PageWrapper>} />
          <Route path='/for-libraries' element={<PageWrapper><PageLibraries /></PageWrapper>} />
          <Route path='/search' element={<PageWrapper><SearchResults /></PageWrapper>} />
          <Route path='/privacy' element={<PageWrapper><PageTOC /></PageWrapper>} />
          <Route path='/list/:slug' element={<PageWrapper><List /></PageWrapper>} />
          <Route path='/stores' element={<PageWrapper><StoreMap /></PageWrapper>} />
          <Route path='/meet-the-team' element={<PageWrapper><TeamPage /></PageWrapper>} />
          <Route path='/visit-us' element={<PageWrapper><Visit /></PageWrapper>} />
          <Route path='/newsletter' element={<PageWrapper noEmailFooter={true}><Newsletter /></PageWrapper>} />
          <Route path='/customer-support' element={<PageWrapper noEmailFooter={true}><SupportPage /></PageWrapper>} />
          <Route path='/checkout' element={<PageWrapper noEmailFooter={true}><Checkout /></PageWrapper>} noindex={true} />
          <Route path='/bestsellers' element={<PageWrapper><Bestsellers /></PageWrapper>} />
          <Route path='/catalog' element={<PageWrapper><CatalogPage /></PageWrapper>} />
          <Route path='/careers' element={<PageWrapper><Jobs /></PageWrapper>} />
          <Route path='/gift-cards' element={<PageWrapper><GiftCards /></PageWrapper>} />
          <Route path='/blog' element={<PageWrapper><BlogHome /></PageWrapper>} />
          <Route path='/blog/post/:slug' element={<PageWrapper><BlogPost /></PageWrapper>} />
          <Route path='/blog/news' element={<PageWrapper><BlogNews /></PageWrapper>} />
          <Route path='/blog/news/page/:slug' element={<PageWrapper><BlogNews /></PageWrapper>} />
          {/* Tools */}
          <Route path='/tools/barcode-generator' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><ToolsBarcode /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/tools/homepage' element={<PageWrapper noMenu={true} admin={true}><React.Suspense fallback={<>...</>}><ToolsHomepage /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/tools/qr-code-generator' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><ToolsQR /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/tools/social-graphic-generator' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><ToolsSocial /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/tools/image-uploader' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><ToolsImageUploader /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/tools/shipping' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><ToolsShipping /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/tools/pricing' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><ToolsPricing /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/tools/newsletter-legacy' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><ToolsNewsletterLegacy /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/tools/inventory' element={<PageWrapper admin={true} noMenu={true}><React.Suspense fallback={<>...</>}><ToolsInventory /></React.Suspense></PageWrapper>} noIndex={true} />
          <Route path='/tools/mailing-list' element={<PageWrapper admin={true} noMenu={true}><React.Suspense fallback={<>...</>}><ToolsMailingList /></React.Suspense></PageWrapper>} noIndex={true} />
          <Route path='/tools/dimensions' element={<PageWrapper admin={true} noMenu={true}><React.Suspense fallback={<>...</>}><ToolsDimensions /></React.Suspense></PageWrapper>} noIndex={true} />
          <Route path='/tools/labels' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><ToolsLabels /></React.Suspense></PageWrapper>} noIndex={true} />
          <Route path='/tools/bulk-cart' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><ToolsBulkOrder /></React.Suspense></PageWrapper>} noIndex={true} />
          <Route path='/tools/packing-slips' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><ToolsPackingSlips /></React.Suspense></PageWrapper>} noIndex={true} />
          <Route path='/tools/service' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><ToolsCustomerService /></React.Suspense></PageWrapper>} noIndex={true} />
          <Route path='/tools/backorders' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><ToolsBackorders /></React.Suspense></PageWrapper>} noIndex={true} />
          <Route path='/tools/backorder-status' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><BackorderOrders /></React.Suspense></PageWrapper>} noIndex={true} />
          <Route path='/tools/room-finder' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><ToolsRoomFinder /></React.Suspense></PageWrapper>} noIndex={true} />
          <Route path='/tools/showroom' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><ShowroomReport /></React.Suspense></PageWrapper>} noIndex={true} />
          <Route path='/tools/fillable-backorders' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><FillableBackorders /></React.Suspense></PageWrapper>} noIndex={true} />
          <Route path='/tools/picker' element={<PageWrapper noMenu={true} admin={true}><React.Suspense fallback={<>...</>}><ToolsPicker /></React.Suspense></PageWrapper>} noIndex={true} />
          <Route path='/tools/pick-history' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><ToolsPickHistory /></React.Suspense></PageWrapper>} noIndex={true} />
          {/* Auth Routes */}
          <Route path='/login' element={<PageWrapper noEmailFooter={true}><AuthLogin /></PageWrapper>} />
          <Route path='/access' element={<PageWrapper noEmailFooter={true}><AuthAccess /></PageWrapper>} noIndex={true} />
          <Route path='/new-password' element={<PageWrapper><AuthReset context='user' /></PageWrapper>} />
          <Route path='/pub/setup' element={<PageWrapper><AuthReset context='publisher' /></PageWrapper>} />
          <Route path='/pub/login' element={<PageWrapper><AuthLogin /></PageWrapper>} />
          <Route path='/create-account' element={<PageWrapper><AuthCreate /></PageWrapper>} />
          {/* User Routes */}
          <Route path='/account' element={<PageWrapper><React.Suspense fallback={<>...</>}><UserSettings /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/account/standing-orders' element={<PageWrapper><React.Suspense fallback={<>...</>}><LibrarySubs /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/account/orders' element={<PageWrapper><React.Suspense fallback={<>...</>}><AccountOrders /></React.Suspense></PageWrapper>} type='customer' noindex={true} />
          <Route path='/account/order/:id' element={<PageWrapper><React.Suspense fallback={<>...</>}><AccountOrder /></React.Suspense></PageWrapper>} type='customer' noindex={true} />
          <Route path='/account/orders/page/:page' element={<PageWrapper><React.Suspense fallback={<>...</>}><AccountOrders /></React.Suspense></PageWrapper>} type='customer' noindex={true} />
          <Route path='/account/new-order' element={<PageWrapper><React.Suspense fallback={<>...</>}><NewOrder noindex={true} /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/account/wishlist' element={<PageWrapper><React.Suspense fallback={<>...</>}><Wishlist /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/order-download' element={<PageWrapper><React.Suspense fallback={<>...</>}><DownloadPage /></React.Suspense></PageWrapper>} noindex={true} />
          {/* Publisher Routes */}
          <Route path='/pub/account' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><PubHome noindex={true} /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/pub/settings' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><PubSettings noindex={true} /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/pub/products' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><PubProducts noindex={true} /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/pub/standing-orders' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><PubSubs noindex={true} /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/pub/products/page/:page' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><PubProducts noindex={true} /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/pub/product/add-new' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><PubProductEdit context='create' noindex={true} /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/pub/product/:id' element={<PageWrapper admin={true}> <React.Suspense fallback={<>...</>}><PubProductEdit context='edit' noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/pub/order/:id' element={<PageWrapper admin={true} > <React.Suspense fallback={<>...</>}>< PubOrder noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/pub/orders' element={< PageWrapper admin={true} > <React.Suspense fallback={<>...</>}>< PubOrders noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/pub/orders/page/:page' element={< PageWrapper admin={true} > <React.Suspense fallback={<>...</>}>< PubOrders noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/pub/shipments' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}>< PubShipments type='publisher' noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/pub/shipments/page/:page' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}>< PubShipments type='publisher' noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/pub/directory' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}>< PubDirectory noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/pub/directory/page/:page' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}>< PubDirectory noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/pub/terms' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}>< PubTerms noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/pub/list/:id' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}>< PubList noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/pub/lists' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}>< PubLists noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/pub/lists/page/:page' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}>< PubLists noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/pub/inventory' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><PubInventory /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/pub/requests' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><PubRequests /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/pub/request/:id' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><PubRequest /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/pub/dropship' element={<PageWrapper noMenu={true} admin={true}><React.Suspense fallback={<>...</>}><DropShipper /></React.Suspense></PageWrapper>} noindex={true} />
          {/* Admin Routes */}
          <Route path='/admin' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><AdminHome context={'admin'} noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/products' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><AdminProducts noindex={true} context={'admin'} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/products/page/:page' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><PubProducts noindex={true} context={'admin'} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/pub-products/:publisher' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><PubProducts noindex={true} context={'admin'} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/pub-products/:publisher/page/:page' element={< PageWrapper admin={true} > <React.Suspense fallback={<>...</>}>< PubProducts noindex={true} context={'admin'} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/shipments' element={< PageWrapper admin={true} noindex={true} > <React.Suspense fallback={<>...</>}>< AdminShipments context='admin' noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/shipments/page/:page' element={< PageWrapper admin={true} noindex={true} > <React.Suspense fallback={<>...</>}>< PubShipments context='admin' noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/orders' element={< PageWrapper admin={true} noindex={true}> <React.Suspense fallback={<>...</>}>< AdminOrders noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/pub-orders/:publisher/' element={< PageWrapper admin={true} noindex={true} > <React.Suspense fallback={<>...</>}>< PubOrders context={'admin'} noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/inventory' element={< PageWrapper admin={true} ><React.Suspense fallback={<>...</>}>< AdminInventory noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/order/:id' element={< PageWrapper admin={true} ><React.Suspense fallback={<>...</>}>< AdminOrder noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/customer/new' element={< PageWrapper admin={true} ><React.Suspense fallback={<>...</>}>< AdminCreateCustomer noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/customer/:id' element={< PageWrapper admin={true} ><React.Suspense fallback={<>...</>}>< AdminCustomer noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/publisher/new' element={< PageWrapper admin={true} ><React.Suspense fallback={<>...</>}>< AdminCreatePublisher noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/publisher/:id' element={< PageWrapper admin={true} ><React.Suspense fallback={<>...</>}>< AdminPublisher noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/publishers' element={< PageWrapper admin={true} > <React.Suspense fallback={<>...</>}>< AdminPublisherList noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/wiki' element={< PageWrapper admin={true} > <React.Suspense fallback={<>...</>}>< AdminWikiList noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/wiki/new' element={< PageWrapper admin={true} > <React.Suspense fallback={<>...</>}>< AdminWikiPostEdit noindex={true} newPost={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/wiki/:id/edit' element={< PageWrapper admin={true} > <React.Suspense fallback={<>...</>}>< AdminWikiPostEdit noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/wiki/:id' element={< PageWrapper admin={true} > <React.Suspense fallback={<>...</>}>< AdminWikiPost noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/catalogs' element={< PageWrapper admin={true} ><React.Suspense fallback={<>...</>}>< AdminCatalogs noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/catalog/new' element={< PageWrapper admin={true} ><React.Suspense fallback={<>...</>}><AdminCatalog noindex={true} newCatalog={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/catalog/:id' element={<PageWrapper noMenu={true} admin={true}><React.Suspense fallback={<>...</>}><AdminCatalog /></React.Suspense></PageWrapper>} noIndex={true} />
          <Route path='/admin/request/new' element={< PageWrapper admin={true} > <React.Suspense fallback={<>...</>}>< AdminRequest noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/requests' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><AdminRequests noindex={true} /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/admin/request/:id' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}><PubRequest admin={true} /></React.Suspense></PageWrapper>} noindex={true} />
          <Route path='/admin/logs' element={<PageWrapper admin={true}> <React.Suspense fallback={<>...</>}>< AdminLogs noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/tickets' element={<PageWrapper admin={true}> <React.Suspense fallback={<>...</>}><AdminTickets noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/ticket/:id' element={<PageWrapper admin={true}> <React.Suspense fallback={<>...</>}><AdminTicket noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/list/:id' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}>< PubList admin={true} noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/lists' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}>< AdminLists admin={true} noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/lists/page/:page' element={<PageWrapper admin={true}><React.Suspense fallback={<>...</>}>< PubLists admin={true} noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/posts' noindex={true} element={< PageWrapper admin={true} > <React.Suspense fallback={<>...</>}>< AdminBlog /></React.Suspense></PageWrapper >} />
          <Route path='/admin/posts/page/:page' noindex={true} element={< PageWrapper admin={true} > <React.Suspense fallback={<>...</>}>< AdminBlog /></React.Suspense></PageWrapper >} />
          <Route path='/admin/post/:id' noindex={true} element={< PageWrapper admin={true} > <React.Suspense fallback={<>...</>}>< AdminBlogEdit /></React.Suspense></PageWrapper >} />
          <Route path='/admin/composer' element={< PageWrapper noMenu={true} admin={true} > <React.Suspense fallback={<>...</>}>< OrderComposer noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/style-guide' element={< PageWrapper noMenu={true} admin={true} > <React.Suspense fallback={<>...</>}><AdminStyleguide noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/dropship' element={< PageWrapper noMenu={true} admin={true} > <React.Suspense fallback={<>...</>}><AdminDropship noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/returns' element={< PageWrapper noMenu={true} admin={true} > <React.Suspense fallback={<>...</>}><AdminReturns noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/preorders' element={< PageWrapper noMenu={true} admin={true} > <React.Suspense fallback={<>...</>}><AdminPreorders noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='/admin/supplies' element={< PageWrapper noMenu={true} admin={true} > <React.Suspense fallback={<>...</>}><Supplies noindex={true} /></React.Suspense></PageWrapper >} noindex={true} />
          <Route path='*' element={< NotFound />} />

        </Routes >
        <Message />
        <Confirm />
        <Footer />
      </div >
    </BrowserRouter >
  );
}

function AnalyticsWrapper() {
  const [userData, setUserData] = useRecoilState(userState);

  const location = useLocation();

  const checkReAuth = async () => {
    let auth = await checkAuth();
    if (!auth && userData.email) {
      setUserData({
        role: 'Guest',
        name: '',
        email: '',
        id: null,
        admin: false,
      });
    }
  }

  useEffect(() => {
    checkReAuth();
  }, [location]);

  const testGroup = useAnalytics();
  return (<></>)
}

function PageWrapper({ children, admin, noEmailFooter, noMenu = false, contentClass = '' }) {
  const [cart, setCart] = useRecoilState(cartState);
  const userData = useRecoilValue(userState);
  const navigate = useNavigate();

  const addToCart = async (id, qty = 1) => {
    let newCart = { ...cart };

    try {
      let product = await makeRequest(`product?id=${id}`, 'GET');
      if (!product._id) {
        return;
      }

      if (newCart[product._id]) {
        let newItem = { ...newCart[product._id] };
        newItem.count = newCart[product._id].count + qty;
        newCart[product._id] = newItem;
      } else {
        newCart[product._id] = {
          count: qty,
          data: product
        }
      }
      setCart(newCart);
      navigate(`/product/${product.slug}`)
    } catch (e) {
      console.log(e);
    }
  }

  const checkParams = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('addToCart') && params.get('addToCart') !== 'true') {
      let qty = params.get('qty') ? parseInt(params.get('qty')) : 1;
      addToCart(params.get('addToCart').replace(/-|\—|\–/g, ''), qty);
      params.delete('addToCart');
      params.delete('qty');
    }
  }

  useEffect(() => {
    checkParams();
  }, []);

  const location = useLocation();

  useEffect(() => {
    document.querySelector('.app-container').scrollIntoView(true);
  }, [location])

  return (
    <div className={`page-container ${admin && !noMenu && 'pub-page'}`}>
      {userData.type === 'Publisher' && admin && !noMenu && <PublisherMenu />}
      {userData.type !== 'Publisher' && admin && !noMenu && <AdminMenu />}
      <div className={`content-container ${contentClass}`}>{children}</div>
      {!noEmailFooter && !admin &&
        <div className='content-container'><EmailSignup /></div>
      }
    </div>
  )
}

export default App;
